import dva from 'dva';
import { Component } from 'react';
import createLoading from 'dva-loading';
import history from '@tmp/history';

let app = null;

export function _onCreate() {
  const plugins = require('umi/_runtimePlugin');
  const runtimeDva = plugins.mergeConfig('dva');
  app = dva({
    history,
    
    ...(runtimeDva.config || {}),
    ...(window.g_useSSR ? { initialState: window.g_initialData } : {}),
  });
  
  app.use(createLoading());
  (runtimeDva.plugins || []).forEach(plugin => {
    app.use(plugin);
  });
  
  app.model({ namespace: 'address', ...(require('/Users/angelit/Work/DaiViet/CRM/Web/src/models/address.js').default) });
app.model({ namespace: 'business', ...(require('/Users/angelit/Work/DaiViet/CRM/Web/src/models/business.js').default) });
app.model({ namespace: 'callCenter', ...(require('/Users/angelit/Work/DaiViet/CRM/Web/src/models/callCenter.js').default) });
app.model({ namespace: 'cashflow', ...(require('/Users/angelit/Work/DaiViet/CRM/Web/src/models/cashflow.js').default) });
app.model({ namespace: 'configLimit', ...(require('/Users/angelit/Work/DaiViet/CRM/Web/src/models/configLimit.js').default) });
app.model({ namespace: 'configuration', ...(require('/Users/angelit/Work/DaiViet/CRM/Web/src/models/configuration.js').default) });
app.model({ namespace: 'converSession', ...(require('/Users/angelit/Work/DaiViet/CRM/Web/src/models/converSession.js').default) });
app.model({ namespace: 'customer', ...(require('/Users/angelit/Work/DaiViet/CRM/Web/src/models/customer.js').default) });
app.model({ namespace: 'customerCase', ...(require('/Users/angelit/Work/DaiViet/CRM/Web/src/models/customerCase.js').default) });
app.model({ namespace: 'discountRate', ...(require('/Users/angelit/Work/DaiViet/CRM/Web/src/models/discountRate.js').default) });
app.model({ namespace: 'employeeProvince', ...(require('/Users/angelit/Work/DaiViet/CRM/Web/src/models/employeeProvince.js').default) });
app.model({ namespace: 'employees', ...(require('/Users/angelit/Work/DaiViet/CRM/Web/src/models/employees.js').default) });
app.model({ namespace: 'exception', ...(require('/Users/angelit/Work/DaiViet/CRM/Web/src/models/exception.js').default) });
app.model({ namespace: 'global', ...(require('/Users/angelit/Work/DaiViet/CRM/Web/src/models/global.js').default) });
app.model({ namespace: 'list', ...(require('/Users/angelit/Work/DaiViet/CRM/Web/src/models/list.js').default) });
app.model({ namespace: 'login', ...(require('/Users/angelit/Work/DaiViet/CRM/Web/src/models/login.js').default) });
app.model({ namespace: 'menu', ...(require('/Users/angelit/Work/DaiViet/CRM/Web/src/models/menu.js').default) });
app.model({ namespace: 'orderAccessories', ...(require('/Users/angelit/Work/DaiViet/CRM/Web/src/models/orderAccessories.js').default) });
app.model({ namespace: 'orderManagement', ...(require('/Users/angelit/Work/DaiViet/CRM/Web/src/models/orderManagement.js').default) });
app.model({ namespace: 'payment', ...(require('/Users/angelit/Work/DaiViet/CRM/Web/src/models/payment.js').default) });
app.model({ namespace: 'product', ...(require('/Users/angelit/Work/DaiViet/CRM/Web/src/models/product.js').default) });
app.model({ namespace: 'report', ...(require('/Users/angelit/Work/DaiViet/CRM/Web/src/models/report.js').default) });
app.model({ namespace: 'returnOrder', ...(require('/Users/angelit/Work/DaiViet/CRM/Web/src/models/returnOrder.js').default) });
app.model({ namespace: 'role', ...(require('/Users/angelit/Work/DaiViet/CRM/Web/src/models/role.js').default) });
app.model({ namespace: 'setting', ...(require('/Users/angelit/Work/DaiViet/CRM/Web/src/models/setting.js').default) });
app.model({ namespace: 'transferOrders', ...(require('/Users/angelit/Work/DaiViet/CRM/Web/src/models/transferOrders.js').default) });
app.model({ namespace: 'user', ...(require('/Users/angelit/Work/DaiViet/CRM/Web/src/models/user.js').default) });
app.model({ namespace: 'warrantyCare', ...(require('/Users/angelit/Work/DaiViet/CRM/Web/src/models/warrantyCare.js').default) });
app.model({ namespace: 'warrantyHistory', ...(require('/Users/angelit/Work/DaiViet/CRM/Web/src/models/warrantyHistory.js').default) });
app.model({ namespace: 'warrantyRequest', ...(require('/Users/angelit/Work/DaiViet/CRM/Web/src/models/warrantyRequest.js').default) });
app.model({ namespace: 'warrantyRequestAccessoriesOrder', ...(require('/Users/angelit/Work/DaiViet/CRM/Web/src/models/warrantyRequestAccessoriesOrder.js').default) });
app.model({ namespace: 'warrantyRequestAnalysis', ...(require('/Users/angelit/Work/DaiViet/CRM/Web/src/models/warrantyRequestAnalysis.js').default) });
app.model({ namespace: 'warrantyRequestComment', ...(require('/Users/angelit/Work/DaiViet/CRM/Web/src/models/warrantyRequestComment.js').default) });
app.model({ namespace: 'warrantyRequestProduct', ...(require('/Users/angelit/Work/DaiViet/CRM/Web/src/models/warrantyRequestProduct.js').default) });
app.model({ namespace: 'warrantyRequestSchedule', ...(require('/Users/angelit/Work/DaiViet/CRM/Web/src/models/warrantyRequestSchedule.js').default) });
app.model({ namespace: 'warrantyStation', ...(require('/Users/angelit/Work/DaiViet/CRM/Web/src/models/warrantyStation.js').default) });
  return app;
}

export function getApp() {
  return app;
}

export class _DvaContainer extends Component {
  render() {
    const app = getApp();
    app.router(() => this.props.children);
    return app.start()();
  }
}

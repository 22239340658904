import moment from 'moment';
import React from 'react';
import nzh from 'nzh/cn';
import { parse, stringify } from 'qs';
import _ from 'lodash';
import storage from './storage';
import momentLocal from './moment';

export const MAX_PRICE = 1000000000000000000;
export const MAX_SMALL_INT = 32767;

export function isArray(data) {
  if (!data) {
    return false;
  }
  return typeof data === 'object' && typeof data.length !== 'undefined';
}

export function isObject(data) {
  return typeof data === 'object' && typeof data.length === 'undefined';
}

export function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(decodeURIComponent(escape(window.atob(base64))));
}

export function fixedZero(val) {
  return val * 1 < 10 ? `0${val}` : val;
}

export function getTimeDistance(type) {
  const now = new Date();
  const oneDay = 1000 * 60 * 60 * 24;

  if (type === 'today') {
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    return [moment(now), moment(now.getTime() + (oneDay - 1000))];
  }

  if (type === 'week') {
    let day = now.getDay();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);

    if (day === 0) {
      day = 6;
    } else {
      day -= 1;
    }

    const beginTime = now.getTime() - day * oneDay;

    return [moment(beginTime), moment(beginTime + (7 * oneDay - 1000))];
  }

  if (type === 'month') {
    const year = now.getFullYear();
    const month = now.getMonth();
    const nextDate = moment(now).add(1, 'months');
    const nextYear = nextDate.year();
    const nextMonth = nextDate.month();

    return [
      moment(`${year}-${fixedZero(month + 1)}-01 00:00:00`),
      moment(moment(`${nextYear}-${fixedZero(nextMonth + 1)}-01 00:00:00`).valueOf() - 1000),
    ];
  }

  const year = now.getFullYear();
  return [moment(`${year}-01-01 00:00:00`), moment(`${year}-12-31 23:59:59`)];
}

export function getPlainNode(nodeList, parentPath = '') {
  const arr = [];
  nodeList.forEach(node => {
    const item = node;
    item.path = `${parentPath}/${item.path || ''}`.replace(/\/+/g, '/');
    item.exact = true;
    if (item.children && !item.component) {
      arr.push(...getPlainNode(item.children, item.path));
    } else {
      if (item.children && item.component) {
        item.exact = false;
      }
      arr.push(item);
    }
  });
  return arr;
}

export function digitUppercase(n) {
  return nzh.toMoney(n);
}

function getRelation(str1, str2) {
  if (str1 === str2) {
    console.warn('Two path are equal!'); // eslint-disable-line
  }
  const arr1 = str1.split('/');
  const arr2 = str2.split('/');
  if (arr2.every((item, index) => item === arr1[index])) {
    return 1;
  }
  if (arr1.every((item, index) => item === arr2[index])) {
    return 2;
  }
  return 3;
}

function getRenderArr(routes) {
  let renderArr = [];
  renderArr.push(routes[0]);
  for (let i = 1; i < routes.length; i += 1) {
    // 去重
    renderArr = renderArr.filter(item => getRelation(item, routes[i]) !== 1);
    // 是否包含
    const isAdd = renderArr.every(item => getRelation(item, routes[i]) === 3);
    if (isAdd) {
      renderArr.push(routes[i]);
    }
  }
  return renderArr;
}

/**
 * Get router routing configuration
 * { path:{name,...param}}=>Array<{name,path ...param}>
 * @param {string} path
 * @param {routerData} routerData
 */
export function getRoutes(path, routerData) {
  let routes = Object.keys(routerData).filter(
    routePath => routePath.indexOf(path) === 0 && routePath !== path
  );
  // Replace path to '' eg. path='user' /user/name => name
  routes = routes.map(item => item.replace(path, ''));
  // Get the route to be rendered to remove the deep rendering
  const renderArr = getRenderArr(routes);
  // Conversion and stitching parameters
  const renderRoutes = renderArr.map(item => {
    const exact = !routes.some(route => route !== item && getRelation(route, item) === 1);
    return {
      exact,
      ...routerData[`${path}${item}`],
      key: `${path}${item}`,
      path: `${path}${item}`,
    };
  });
  return renderRoutes;
}

export function getPageQuery() {
  return parse(window.location.href.split('?')[1]);
}

export function getQueryPath(path = '', query = {}) {
  const search = stringify(query);
  if (search.length) {
    return `${path}?${search}`;
  }
  return path;
}

/* eslint no-useless-escape:0 */
const reg = /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;

export function isUrl(path) {
  return reg.test(path);
}

export function formatWan(val) {
  const v = val * 1;
  if (!v) return '';

  let result = val;
  if (val > 10000) {
    result = Math.floor(val / 10000);
    result = (
      <span>
        {result}
        <span
          style={{
            position: 'relative',
            top: -2,
            fontSize: 14,
            fontStyle: 'normal',
            marginLeft: 2,
          }}
        />
      </span>
    );
  }
  return result;
}

export const importCDN = (url, name) =>
  new Promise(resolve => {
    const dom = document.createElement('script');
    dom.src = url;
    dom.type = 'text/javascript';
    dom.onload = () => {
      resolve(window[name]);
    };
    document.head.appendChild(dom);
  });

/**
 *
 * @param {object} json
 * @return {string}
 */
export function toQueryString(json) {
  const encodeURI = k => `${k}=${encodeURIComponent(json[k])}`;
  const handleArr = (data, k) => {
    const para = [];
    data.map(el => para.push(`${k}=${el}`));
    return para.join('&');
  };

  return Object.keys(json)
    .map(k => (_.isArray(json[k]) ? handleArr(json[k], k) : encodeURI(k)))
    .join('&');
}

/**
 * Check empty string
 * @param val
 * @param returnVal
 * @returns {string}
 */
export function handleVar(val, returnVal = null) {
  return !_.isEmpty(val) && val !== 'undefined' ? val : returnVal;
}

/**
 * Handle params
 * @param data
 * @returns array | object
 */
export function handleParams(data) {
  const query = Object.assign({});
  _.forEach(data, (val, key) => {
    if (val) {
      _.set(query, key, val);
    }
  });
  return query;
}

/**
 * format currency
 * @param value
 * @returns {*}
 */
export function formatCurrency(value) {
  if (_.isNil(value)) {
    return '';
  }
  const parts = value.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
}

export const lightboxCustomStyles = {
  overlay: {
    zIndex: 3600,
  },
};

export function formatExportUrl(url) {
  const encAuthToken = storage.getSocketToken();
  if (_.includes(url, '?')) {
    return `${url}&enc_auth_token=${encAuthToken}`;
  }
  return `${url}?enc_auth_token=${encAuthToken}`;
}
export function formatSearchParams(values) {
  return new window.URLSearchParams(handleParams(values)).toString();
}

export function defaultDatePicker() {
  return [momentLocal.substract({ number: 14, type: 'days' }), momentLocal.moment()];
}
export function hanldeRowspanHistory(data) {
  const objC = [];
  return data.map((el, idz) => {
    const check = data.filter(els => els.id === el.id).length;
    let checkAvailable = 0;
    let isMerge = -1;
    if (check > 1) {
      isMerge = 0;
      if (objC.indexOf(el.id) < 0) {
        objC.push(el.id);
        isMerge = 1;
      }
      checkAvailable = check;
    }

    el.checkAvailable = checkAvailable;
    el.isMerge = isMerge;
    return el;
  });
}

const locale = 'vi-VN';
export const currencyFormatter = value => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'VND',
  }).format(value);
};

export const numberFormatter = value => {
  return Intl.NumberFormat().format(value);
};

export const isNumberDiffZero = value => {
  return value != null && value != undefined && value != 0
}

export const currencyParser = val => {
  try {
    // for when the input gets clears
    if (typeof val === 'string' && !val.length) {
      val = '0.0';
    }

    // detecting and parsing between comma and dot
    const group = new Intl.NumberFormat(locale).format(1111).replace(/1/g, '');
    const decimal = new Intl.NumberFormat(locale).format(1.1).replace(/1/g, '');
    let reversedVal = val.replace(new RegExp(`\\${group}`, 'g'), '');
    reversedVal = reversedVal.replace(new RegExp(`\\${decimal}`, 'g'), '.');
    //  => 1232.21 €

    // removing everything except the digits and dot
    reversedVal = reversedVal.replace(/[^0-9.]/g, '');
    //  => 1232.21

    // appending digits properly
    const digitsAfterDecimalCount = (reversedVal.split('.')[1] || []).length;
    const needsDigitsAppended = digitsAfterDecimalCount > 2;

    if (needsDigitsAppended) {
      reversedVal *= 10 ** digitsAfterDecimalCount - 2;
    }

    return Number.isNaN(reversedVal) ? 0 : reversedVal;
  } catch (error) {
    console.error(error);
  }
};

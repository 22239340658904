import _ from 'lodash';
import { notification } from 'antd';
import * as API from '../services/employeeProvince';

export default {
  namespace: 'employeeProvince',

  state: {
    searchData: {},
    visibleModal: false,
    visibleCustomerModal: false,
    visibleCustomerRelationModal: false,
    modalType: '',
    showTable: false,
    list: [],
    item: {},
    customerRelation: [],
    relationShip: [],
    customerTypes: [],
    historyInfo: [],
    customerRelationSelected: {},
  },

  effects: {
    *fetch({ payload }, { call, put }) {
      const query = Object.assign({});
      _.forEach(payload, (val, key) => {
        if (val) {
          _.set(query, key, val);
        }
      });

      yield put({
        type: 'saveSearchData',
        payload: query,
      });

      const response = yield call(API.getAll, query);
      if (response.success) {
        yield put({
          type: 'saveList',
          payload: {
            ...response.result,
          },
        });
      }
    },
    *refreshFetch(_, { put, select }) {
      const searchData = yield select(state => state.customer.searchData);

      yield put({
        type: 'fetch',
        payload: searchData,
      });
    },

    *get({ payload }, { call, put }) {
      const response = yield call(API.get, payload.id);
      if (response.success) {
        yield put({
          type: 'save',
          payload: {
            ...response,
            visibleModal: true,
          },
        });
      }
    },

    *create({ payload }, { call, put }) {
      const response = yield call(API.create, payload);
      if (response.success) {
        yield put({
          type: 'save',
          payload: {
            result: {},
            visibleModal: false,
            customerRelation: [],
          },
        });

        yield notification.success({
          message: 'Thêm mới',
          description: 'Đã thêm mới thành công',
        });

        yield put({
          type: 'refreshFetch',
        });

        console.log(response)
      }
    },


    *update({ payload }, { call, put }) {
      const response = yield call(API.update, payload);
      if (response.success) {
        yield put({
          type: 'save',
          payload: {
            result: {},
            visibleModal: false,
            customerRelation: [],
          },
        });

        yield notification.success({
          message: 'Cập nhật',
          description: 'Đã cập nhật thông tin thành công',
        });

        yield put({
          type: 'refreshFetch',
        });
      }
    },

    *changeVisibleModal({ payload }, { call, put }) {
      yield put({
        type: 'saveVisibleModal',
        payload: {
          status: payload.status,
          modalType: payload.modalType,
          item: {},
        },
      });

      yield put({
        type: 'address/resetAddress',
      });
    },
  },

  reducers: {
    saveVisibleModal(state, { payload }) {
      return {
        ...state,
        visibleModal: payload.status,
        modalType: payload.modalType,
        showTable: payload.showTable,
        ...payload,
      };
    },
    changeVisibleTableModal(state, { payload }) {
      return {
        ...state,
        showTable: payload.status,
      };
    },
    saveList(state, { payload }) {
      return {
        ...state,
        list: payload,
      };
    },
    saveListForSelect(state, { payload }) {
      return {
        ...state,
        listForSelect: payload,
      };
    },
    save(state, { payload }) {
      const { result, visibleModal, customerRelation } = payload;
      return {
        ...state,
        item: result,
        visibleModal,
        customerRelation,
        customerRelationSelected: {},
      };
    },
    saveHistoriesCustomers(state, { payload }) {
      const { data } = payload;
      return {
        ...state,
        historyInfo: data,
      };
    },
    saveCustomerRelation(state, { payload }) {
      return {
        ...state,
        customerRelation: payload.data,
        customerRelationSelected: {}
      };
    },
    saveCustomerTypes(state, { payload }) {
      return {
        ...state,
        customerTypes: payload.items,
      };
    },
    saveSearchData(state, { payload }) {
      return {
        ...state,
        searchData: {
          ...payload,
        },
      };
    },

    changeVisibleCustomerModal(state, { payload }) {
      return {
        ...state,
        visibleCustomerModal: payload.status,
      };
    },

    changeVisibleCustomerRelationModal(state, { payload }) {
      return {
        ...state,
        visibleCustomerRelationModal: payload.status,
      };
    },

    saveCustomerRelationSelected(state, { payload }) {
      return {
        ...state,
        customerRelationSelected: payload.data,
      };
    },
  },
};

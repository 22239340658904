import _ from "lodash";
import {notification} from "antd";
import * as warrantyRequestAPI from "@/services/warrantyRequest";

export default {
  namespace: 'warrantyRequestAnalysis',

  state: {
    accessoriesList: [],
    accessoriesSelected: [] ,
  },

  effects: {
    *fetchAccessories({ payload }, { call, put }) {
      const query = Object.assign({});
      _.forEach(payload, (val, key) => {
        if (val) {
          _.set(query, key, val);
        }
      });

      const response = yield call(warrantyRequestAPI.getAccessories, query);
      if (response.success) {
        yield put({
          type: 'saveAccessoriesList',
          payload: {
            ...response.result,
          },
        });

        return response.result.items;
      }

      return [];
    },

    *start({ payload }, { call, put }) {
      const response = yield call(warrantyRequestAPI.analyzeStart, payload);
      if (response.success) {
        yield put({
          type: 'warrantyRequest/changeCurrentStep',
          payload: {
            item: response.result
          },
        });

        yield notification.success({
          message: 'Phân tích lỗi',
          description: 'Bắt đầu tiến hành phân tích lỗi',
        });
      }
    },

    *finish({ payload }, { call, put }) {
      const response = yield call(warrantyRequestAPI.analyzeFinish, payload);
      if (response.success) {

        yield put({
          type: 'warrantyRequest/changeCurrentStep',
          payload: {
            item: response.result
          },
        });

        if (payload.rightDirection) {
          yield notification.success({
            message: 'Chăm sóc lại',
            description: 'Đã cập nhật tình trạng cần chăm sóc lại',
          });
        } else {
          yield notification.success({
            message: 'Phân tích lỗi',
            description: 'Đã cập nhật phân tích lỗi thành công',
          });
        }
      }
    },
    *skip({ payload }, { call, put }) {
      const response = yield call(warrantyRequestAPI.analyzeSkip, payload);
      if (response.success) {
        yield put({
          type: 'warrantyRequest/changeCurrentStep',
          payload: {
            item: response.result
          },
        });
      }
    },
  },

  reducers: {
    saveAccessoriesList(state, { payload }) {
      return {
        ...state,
        accessoriesList: payload.items
      };
    },
    saveAccessoriesSelected(state, { payload }) {
      return {
        ...state,
        accessoriesSelected: payload.data
      };
    },
  }
};

import _ from 'lodash';
import { notification } from 'antd';
import * as signalR from '@aspnet/signalr';
import * as callCenterAPI from '../services/callCenter';
import { handleVar } from '@/utils/utils';
import storage from '@/utils/storage';
import {
  CALL_CENTER_INBOUND,
  CALL_CENTER_OUTBOUND,
  PAGINATION_PAGE_SIZE,
} from '@/configs/constants';
import { WARRANTY_TYPE, WARRANTY_FROM } from './warrantyRequest';

const delay = timeout => new Promise(resolve => setTimeout(resolve, timeout));

const callSocket = transport => {
  const encAuthToken = storage.getSocketToken();
  const connection = new signalR.HubConnectionBuilder()
    .withUrl(`${SOCKET_CALL_CENTER_URL}${encAuthToken}`, transport)
    .configureLogging(signalR.LogLevel.Information)
    .build();

  connection
    .start()
    .then(function () {
      console.log('Connected signalR socket');
      notification.success({
        message: 'Kết nối',
        description: 'Đã kết nối thành công tổng đài',
      });
      connection.on('crm.cc.telephony', data => {
        console.log('signalR data : ', data);
        window.g_app._store.dispatch({
          type: 'callCenter/getCallingDetail',
          payload: data,
        });
      });

      connection.on('crm.cc.accessories', data => {
        console.log('received accessories notify', data);
        notification.warn({
          message: _.get(data, 'title'),
          description: _.get(data, 'message'),
        });
        setTimeout(() => {
          window.g_app._store.dispatch({
            type: 'global/fetchNotices',
          });
        }, 1000);
      });

      connection.on('crm.notification', data => {
        notification.info({
          message: _.get(data, 'title'),
          description: _.get(data, 'message'),
        });
        setTimeout(() => {
          window.g_app._store.dispatch({
            type: 'global/fetchNotices',
          });
        }, 1000);
      });

      connection.on('crm.cc.import', data => {
        notification.info({
          message: _.get(data, 'title'),
          description: _.get(data, 'message'),
        });
        setTimeout(() => {
          window.g_app._store.dispatch({
            type: 'global/fetchNotices',
          });
        }, 1000);
      });

      connection.onclose(error => {
        console.log('signalR closed with error', error);
        setTimeout(() => {
          callSocket(signalR.HttpTransportType.WebSockets);
        }, 1000);
      });
    })
    .catch(err => {
      console.log(
        `Cannot start the connection using ${signalR.HttpTransportType[transport]} transport -> ${transport}.`
      );
      if (transport === 4) {
        notification.error({
          message: 'Lỗi kết nối',
          description: 'Không thể kết nối với tổng đài',
        });
      }

      if (transport !== signalR.HttpTransportType.LongPolling) {
        callSocket(transport + 1);
      } else {
        console.log('Fail socket : ', err);
        setTimeout(() => {
          callSocket(signalR.HttpTransportType.WebSockets);
        }, 10000);
      }
    });
};

const initState = {
  searchData: {},
  visibleModalCustomerCase: false,
  modalType: '',
  list: [],
  customerSelected: {},
  user: {},
  customer: {},
  infoCalling: {},
  outBoundInfo: {},
  item: {},
  isCalling: false,
  recentCases: [],
  activityLogs: [],
  productsWarrantyHistory: [],
  warrantyRequestId: 0,
  recentViewItem: {},
  historiesCase: [],
  objectSelected: {},
  childCases: [],
  listModal: false,
};

export default {
  namespace: 'callCenter',

  state: {
    ...initState,
  },

  effects: {
    *fetch({ payload = { CurrentPage: 1, MaxResultCount: PAGINATION_PAGE_SIZE } }, { call, put }) {
      const query = Object.assign({});
      _.forEach(payload, (val, key) => {
        if (val) {
          _.set(query, key, val);
        }
      });
      if (!_.isEmpty(query.ToDate) && !_.isEmpty(query.FromDate)) {
        const response = yield call(callCenterAPI.getAllData, query);
        if (response.success) {
          yield put({
            type: 'saveSearchData',
            payload: query,
          });
          yield put({
            type: 'saveList',
            payload: {
              ...response.result,
            },
          });

          yield put({
            type: 'address/resetAddress',
          });
        }
      }


    },

    *refreshFetch(_, { put, select }) {
      const searchData = yield select(state => state.callCenter.searchData);
      yield put({
        type: 'fetch',
        payload: searchData,
      });

    },

    *get({ payload }, { call, put }) {
      const response = yield call(callCenterAPI.get, payload.id);
      if (response.success) {
        yield put({
          type: 'save',
          payload: {
            ...response,
            visibleModalCallCenter: _.has(payload, 'visibleModalCallCenter')
              ? payload.visibleModalCallCenter
              : true,
          },
        });

        const { result } = response;
        const { ref } = result;
        result.infoCalling = ref ? handleVar(ref.rootPbx, {}) : {};
        yield put({
          type: 'getRelationCallCenter',
          payload: result,
        });

        yield put({
          type: 'saveObjectSelected',
          payload: {
            data: response.result.object,
          },
        });
      }
    },

    *getCallingDetail({ payload }, { call, put }) {
      const response = yield call(
        callCenterAPI.getCallingDetail,
        payload.caseId,
        payload.rootPbxId,
        payload.pbxId
      );
      if (response.success) {
        const { pbx, rootPbx } = response.result;

        if (
          pbx.callStatus === 'DialAnswer' &&
          (pbx.direction === CALL_CENTER_INBOUND || pbx.direction === CALL_CENTER_OUTBOUND)
        ) {
          const { user, customer } = pbx;
          yield put({
            type: 'changeCustomerCalling',
            payload: {
              isCalling: true,
              infoCalling: rootPbx,
              outBoundInfo: pbx,
              user,
              customer,
              case: response.result.case,
              warrantyRequestId: payload.warrantyRequestId,
            },
          });
        }

        if (
          pbx.callStatus === 'HangUp' &&
          (pbx.direction === CALL_CENTER_INBOUND || pbx.direction === CALL_CENTER_OUTBOUND)
        ) {
          yield put({
            type: 'saveIsCalling',
            payload: {
              isCalling: false,
            },
          });
        }
      }
    },

    *create({ payload }, { call, put }) {
      const response = yield call(callCenterAPI.create, payload);
      if (response.success) {
        if (payload.isFinally) {
          yield put({
            type: 'saveCreateAndUpdate',
            payload: {
              visibleModalCallCenter: _.has(payload, 'visibleModalCallCenter')
                ? payload.visibleModalCallCenter
                : false,
            },
          });

          if (response.result.warrantyRequestId > 0) {
            yield put({
              type: 'warrantyRequest/get',
              payload: {
                id: response.result.warrantyRequestId,
                warrantyType: WARRANTY_TYPE.NOMARL,
                from: WARRANTY_FROM.CC,
              },
            });
          }
        }

        /* START : REFRESH DATA FOLLOW FILTER */
        yield put({
          type: 'refreshFetch',
        });
        /* START : REFRESH DATA FOLLOW FILTER */

        yield notification.success({
          message: 'Ca bảo hành',
          description: 'Đã lưu thông tin tiếp nhận mới thành công',
        });
      }
    },

    *autoCreate(data, { call }) {
      const response = yield call(callCenterAPI.autoCreate);
      if (response.success) {
        return response.result;
      }
      return null;
    },

    *autoCreateWithCustomer({ payload }, { call }) {

      const response = yield call(callCenterAPI.autoCreateWithCustomer, payload.id);
      if (response.success) {
        return response.result;
      }
      return null;
    },

    *clone({ payload }, { call, put }) {
      const response = yield call(callCenterAPI.cloneCustomerCase, payload.id);
      if (response.success) {
        yield notification.success({
          message: 'Ca bảo hành',
          description: 'Đã sao chép thông tin tiếp nhận và tạo ca BH  thành công',
        });

        if (!_.isEmpty(response.result)) {
          const item = { ...response.result };
          yield put({
            type: 'saveItem',
            payload: {
              item,
            },
          });
        }

        yield put({
          type: 'saveCreateAndUpdate',
          payload: {
            visibleModalCallCenter: _.has(payload, 'visibleModalCallCenter')
              ? payload.visibleModalCallCenter
              : false,
          },
        });

        if (response.result.warrantyRequestId > 0) {
          yield put({
            type: 'warrantyRequest/get',
            payload: {
              id: response.result.warrantyRequestId,
              warrantyType: WARRANTY_TYPE.NOMARL,
              from: WARRANTY_FROM.CC,
            },
          });
        }

        /* START : REFRESH DATA FOLLOW FILTER */
        yield put({
          type: 'refreshFetch',
        });
        /* START : REFRESH DATA FOLLOW FILTER */
      }
    },

    *update({ payload }, { call, put, select }) {
      const response = yield call(callCenterAPI.update, payload);
      if (response.success) {
        yield notification.success({
          message: 'Ca bảo hành',
          description: 'Đã cập nhật thông tin tiếp nhận thành công',
        });

        if (!_.isEmpty(response.result)) {
          const item = { ...response.result };
          yield put({
            type: 'saveItem',
            payload: {
              item,
            },
          });
        }

        if (payload.isFinally) {
          yield put({
            type: 'saveCreateAndUpdate',
            payload: {
              visibleModalCallCenter: _.has(payload, 'visibleModalCallCenter')
                ? payload.visibleModalCallCenter
                : false,
            },
          });

          if (response.result.warrantyRequestId > 0) {
            yield put({
              type: 'warrantyRequest/get',
              payload: {
                id: response.result.warrantyRequestId,
                warrantyType: WARRANTY_TYPE.NOMARL,
                from: WARRANTY_FROM.CC,
              },
            });
          }
        } else {
          const customer = yield select(state => state.callCenter.customer);
          const infoCalling = yield select(state => state.callCenter.infoCalling);

          yield put({
            type: 'getRelationCallCenter',
            payload: {
              customer,
              infoCalling,
            },
          });
        }

        /* START : REFRESH DATA FOLLOW FILTER */
        yield put({
          type: 'refreshFetch',
        });
        /* START : REFRESH DATA FOLLOW FILTER */
      }
    },

    watchSocket: [
      function* ({ take, call }) {
        while (true) {
          yield take('user/fetchCurrent');
          yield call(delay, 100);
          yield callSocket(signalR.HttpTransportType.WebSockets);
        }
      },
      { type: 'watcher' },
    ],

    *getRecentCases({ payload }, { call, put }) {
      const response = yield call(callCenterAPI.getRecentCases, payload);
      if (response.success) {
        yield put({
          type: 'saveRecentCases',
          payload: {
            data: response.result.items,
          },
        });
      }
    },

    *getActivityLogs({ payload }, { call, put }) {
      const response = yield call(callCenterAPI.getActivityLogs, payload);
      if (response.success) {
        yield put({
          type: 'saveActivityLogs',
          payload: {
            data: response.result,
          },
        });
      }
    },

    *getRecentViewItem({ payload }, { call, put }) {
      const response = yield call(callCenterAPI.get, payload.id);
      if (response.success) {
        yield put({
          type: 'saveRecentViewItem',
          payload: {
            ...response,
          },
        });
        return response.result;
      } else {
        return null;
      }
    },

    *getHistoriesCase({ payload }, { call, put }) {
      const response = yield call(callCenterAPI.getHistoriesCase, payload.id);
      if (response.success) {
        yield put({
          type: 'saveHistoriesCase',
          payload: {
            data: response.result,
          },
        });
      }
    },

    *getProducts({ payload }, { call, put }) {
      let data = [];
      const response = yield call(callCenterAPI.getProducts, payload);
      if (response.success) {
        data = response.result;
      }

      yield put({
        type: 'saveProducts',
        payload: {
          data,
        },
      });
    },

    *changeCustomerCalling({ payload }, { put }) {
      yield put({
        type: 'saveCustomerCalling',
        payload,
      });

      yield put({
        type: 'getRelationCallCenter',
        payload,
      });
    },

    *getRelationCallCenter({ payload }, { put, select }) {
      const { customer, infoCalling } = payload;
      const phoneCalling = !_.isEmpty(infoCalling) ? infoCalling.customerNumber : null;
      const phone = !_.isEmpty(customer) ? customer.phoneNumber : phoneCalling;
      const customerId = !_.isEmpty(customer) ? customer.id : 0;

      yield put({
        type: 'getRecentCases',
        payload: {
          Keyword: phone,
          CurrentPage: 1,
          MaxResultCount: 10,
        },
      });

      yield put({
        type: 'getActivityLogs',
        payload: {
          Keyword: phone,
          CurrentPage: 1,
          MaxResultCount: 10,
        },
      });

      yield put({
        type: 'getProducts',
        payload: {
          id: customerId > 0 ? customerId : 0,
          Keyword: phone,
          CurrentPage: 1,
          MaxResultCount: 10,
        },
      });

      const item = yield select(state => state.callCenter.item);
      yield put({
        type: 'getChildCases',
        payload: {
          id: item.id,
          CurrentPage: 1,
          MaxResultCount: 10,
        },
      });
    },

    *updateCustomerCase({ payload }, { call }) {
      yield call(callCenterAPI.updateCustomerCase, payload);
    },

    *changeVisibleCallCenterModal({ payload }, { put }) {
      yield put({
        type: 'saveVisibleCallCenterModal',
        payload,
      });
    },

    *getChildCases({ payload }, { call, put }) {
      const response = yield call(callCenterAPI.childCases, payload);
      if (response.success) {
        yield put({
          type: 'saveChildCases',
          payload: {
            data: response.result.items,
          },
        });
      }
    },

    *setWarrantyListModal({ payload }, { put }) {
      yield put({
        type: 'saveWarrantyListModal',
        payload,
      });
    },

    *updateInfoAfterDone({ payload }, { call }) {
      const response = yield call(callCenterAPI.updateAfterDone, payload);
      return response
    },

  },

  reducers: {
    saveVisibleCallCenterModal(state, { payload }) {
      return {
        ...state,
        visibleModalCallCenter: payload.status,
        modalType: payload.modalType,
      };
    },

    saveList(state, { payload }) {
      return {
        ...state,
        list: payload,
      };
    },

    save(state, { payload }) {
      const { result, visibleModalCallCenter } = payload;
      const { user, customer, ref } = result;
      const { pbx, rootPbx } = ref || {};
      const infoCalling = handleVar(rootPbx, {});
      const outBoundInfo = handleVar(pbx, {});

      return {
        ...state,
        user: handleVar(user, {}),
        customer: handleVar(customer, {}),
        item: payload.result,
        infoCalling,
        outBoundInfo,
        visibleModalCallCenter,
      };
    },

    saveCreateAndUpdate(state, { payload }) {
      const { visibleModalCallCenter } = payload;

      return {
        ...state,
        visibleModalCallCenter,
      };
    },

    saveCustomerSelected(state, { payload }) {
      return {
        ...state,
        customerSelected: payload.data,
      };
    },

    saveCustomerCalling(state, { payload }) {
      const { isCalling, warrantyRequestId } = payload;
      const user = handleVar(payload.user, {});
      const customer = handleVar(payload.customer, {});
      const infoCalling = handleVar(payload.infoCalling, {});
      const outBoundInfo = handleVar(payload.outBoundInfo, {});
      const item = handleVar(payload.case, {});

      return {
        ...state,
        user,
        customer,
        infoCalling,
        outBoundInfo,
        item,
        visibleModalCallCenter: true,
        isCalling,
        warrantyRequestId,
      };
    },

    saveCustomer(state, { payload }) {
      return {
        ...state,
        customer: payload.customer,
        visibleModalCallCenter: true,
      };
    },
    saveIsCalling(state, { payload }) {
      return {
        ...state,
        isCalling: payload.isCalling,
      };
    },
    saveRecentCases(state, { payload }) {
      return {
        ...state,
        recentCases: payload.data,
      };
    },
    saveRecentViewItem(state, { payload }) {
      return {
        ...state,
        recentViewItem: payload.result,
      };
    },
    saveActivityLogs(state, { payload }) {
      return {
        ...state,
        activityLogs: payload.data,
      };
    },
    saveProducts(state, { payload }) {
      return {
        ...state,
        productsWarrantyHistory: payload.data,
      };
    },

    saveRedirectWarrantyRequest(state, { payload }) {
      return {
        ...state,
        redirectWarrantyRequest: payload.status,
      };
    },

    saveSearchData(state, { payload }) {
      return {
        ...state,
        searchData: payload,
      };
    },

    saveHistoriesCase(state, { payload }) {
      const { data } = payload;
      return {
        ...state,
        historiesCase: data,
      };
    },

    resetState(_, { payload }) {
      const state = {
        ...initState,
        ...payload,
      };
      return {
        ...state,
      };
    },

    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },

    saveItem(state, { payload }) {
      const { item } = payload;
      return {
        ...state,
        item,
      };
    },

    saveObjectSelected(state, { payload }) {
      return {
        ...state,
        objectSelected: payload.data,
      };
    },

    saveChildCases(state, { payload }) {
      return {
        ...state,
        childCases: payload.data,
      };
    },

    saveWarrantyListModal(state, { payload }) {
      return {
        ...state,
        listModal: payload,
      };
    },
  },
};

import { apiGet, apiPost, apiPut,apiDelete } from '@/utils/request';

export function getAll(query) {
  return apiGet(`${BASE_API_URL}/services/app/Station/stations`, query);
}

export function get(id) {
  return apiGet(`${BASE_API_URL}/services/app/Station/GetDetail`, {'id': id});
}

export function create(body) {
  return apiPost(`${BASE_API_URL}/services/app/Station/Create`, {body} );
}

export function update(body) {
  return apiPut(`${BASE_API_URL}/services/app/Station/Update`, {body} );
}
export function deleteAddress({ stationId, id }) {
  return apiDelete(`${BASE_API_URL}/services/app/Station/${stationId}/Address/${id}` );
}
export function addAddress(body) {
  return apiPost(`${BASE_API_URL}/services/app/Station/Address`, {body} );
}

export function updateLocationAddress({ stationId, id,body }) {
  return apiPut(`${BASE_API_URL}/services/app/Station/${stationId}/Address/${id}`,{stationId,id} );
}

import { apiGet, apiPost, apiPut, apiDelete } from '@/utils/request';

export function getAll(query) {
  return apiGet(`${BASE_API_URL}/services/app/AccessoriesOrder/GetAll`, query);
}

export function getAllData(query) {
  return apiGet(`${BASE_API_URL}/services/app/AccessoriesOrder/GetAllData`, query);
}

export function get(id) {
  return apiGet(`${BASE_API_URL}/services/app/AccessoriesOrder/GetDetail`, { id: id });
}
export function getReceiveInfo(id) {
  return apiGet(`${BASE_API_URL}/services/app/AccessoriesOrder/${id}/receive/viewinfo`, { id: id });
}

export function create(body) {
  return apiPost(`${BASE_API_URL}/services/app/AccessoriesOrder/Create`, { body });
}

export function update(body) {
  return apiPut(`${BASE_API_URL}/services/app/AccessoriesOrder/Update`, { body });
}

export function getForSelect(query) {
  return apiGet(`${BASE_API_URL}/services/app/AccessoriesOrder/GetForSelect`, query);
}

export function getTypeAccessoriesOrderRef(ref) {
  return apiGet(`${BASE_API_URL}/services/app/Ref/TypeAccessoriesOrder/${ref}`);
}
export function getWareHouse() {
  return apiGet(`${BASE_API_URL}/services/app/Warehouse/GetForSelect`);
}

export function getAccessoriesOrderRef(ref) {
  return apiGet(`${BASE_API_URL}/services/app/Ref/AccessoriesOrder/${ref}`);
}

export function getConditionType(ref) {
  return apiGet(`${BASE_API_URL}/services/app/ConditionType/GetForSelect`);
}

export function approve(id) {
  return apiPut(`${BASE_API_URL}/services/app/AccessoriesOrder/${id}/approve`);
}

export function reject(id) {
  return apiPut(`${BASE_API_URL}/services/app/AccessoriesOrder/${id}/reject`);
}
export function cancel(id, body) {
  return apiPut(`${BASE_API_URL}/services/app/AccessoriesOrder/${id}/cancel`, { body });
}

export function revertToReceiving(id) {
  return apiPut(`${BASE_API_URL}/services/app/AccessoriesOrder/${id}/revert-to-receiving`);
}

export function checkInventory({ warehouseCode, body }) {
  return apiPost(`${BASE_API_URL}/services/app/Inventory/${warehouseCode}/inventory/products`, {
    body,
  });
}
export function checkProductLinesLimit(body) {
  return apiPost(`${BASE_API_URL}/services/app/AccessoriesOrder/CheckLimitProductLines`, { body });
}


export function checkSAInventory({ internalWarehouseId, body }) {
  return apiPost(
    `${BASE_API_URL}/services/app/Inventory/sa/${internalWarehouseId}/inventory/products`,
    {
      body,
    }
  );
}

export function getDeliveryInfo(code) {
  return apiGet(`${BASE_API_URL}/externals/wms/saleorder/${code}/metadata`, {
    catchError: true,
  });
}
export function getHistoriesAccessories(id) {
  return apiGet(`${BASE_API_URL}/services/app/AccessoriesOrder/${id}/histories`);
}
export function uploadImage({ id, lineNumber, body }) {
  return apiPut(`${BASE_API_URL}/services/app/AccessoriesOrder/${id}/line/${lineNumber}/images`, {
    body,
  });
}
export function getProductImages({ id, lineNumber }) {
  return apiGet(`${BASE_API_URL}/services/app/AccessoriesOrder/${id}/line/${lineNumber}/images`);
}
export function deleteProductImage({ id, lineNumber, imageLineNumber }) {
  return apiDelete(
    `${BASE_API_URL}/services/app/AccessoriesOrder/${id}/line/${lineNumber}/images/${imageLineNumber}`
  );
}
export function processCS(id, body) {
  return apiPut(`${BASE_API_URL}/services/app/AccessoriesOrder/${id}/receive/processCS`, { body });
}
export function validateSerial(id, body) {
  return apiPut(`${BASE_API_URL}/services/app/AccessoriesOrder/${id}/receive/validate/${body.serial}/${body.lineNumber}`, { body });
}
export function calFee(codetpl, body) {
  return apiPost(`${URL_HUB_ORDER}/client/orders/${codetpl}/fee`, { body });
}
export function getShippingTypes(codetpl, body) {
  return apiPost(`${URL_HUB_ORDER}/client/orders/${codetpl}/getshippingtypes`, { body });
}



import _ from 'lodash';
import React from 'react';
import { Button } from 'antd';
import { PAGINATION_PAGE_SIZE } from '@/configs/constants';

export function renderData({ data, CurrentPage = 1, MaxResultCount, type = 'id' }) {
  let result = [];
  let total = 0;
  const pageSize = MaxResultCount === undefined ? PAGINATION_PAGE_SIZE : MaxResultCount;
  if (!_.isEmpty(data)) {
    const { items, totalCount } = data;
    result = items;
    result.map(el => (el.key = type ? el[type] : Math.random()));
    total = totalCount;
  }

  return {
    list: result,
    pagination: {
      total,
      pageSize,
      current: _.parseInt(CurrentPage) || CurrentPage,
    },
  };
}

export function renderDataMasterDetail({
  data,
  CurrentPage = 1,
  MaxResultCount,
  NumberRow,
  type = 'id',
}) {
  let result = [];
  let total = 0;
  const pageSize = MaxResultCount === undefined ? PAGINATION_PAGE_SIZE : MaxResultCount * NumberRow;
  if (!_.isEmpty(data)) {
    const { items, totalCount } = data;
    result = items;
    result.map(el => (el.key = type ? el[type] : Math.random()));
    total = totalCount;
  }

  return {
    list: result,
    pagination: {
      total,
      pageSize,
      current: _.parseInt(CurrentPage) || CurrentPage,
    },
  };
}

export function renderDataSummary({ data, CurrentPage = 1, MaxResultCount, type = 'id' }) {
  let result = [];
  let total = 0;
  const pageSize = MaxResultCount === undefined ? PAGINATION_PAGE_SIZE : MaxResultCount;
  if (!_.isEmpty(data) && data != undefined) {
    const { item, summaryValue } = data;
    result = item;

    result.map(el => (el.key = type ? el[type] : Math.random()));
    total = summaryValue.totalRows;
  }

  return {
    list: result,
    pagination: {
      total,
      pageSize,
      current: _.parseInt(CurrentPage) || CurrentPage,
    },
  };
}

/**
 * Render layout columns, rows
 * @returns {{formItemLayout: {wrapperCol: {md: {span: number}, sm: {span: number}, xs: {span: number}}, labelCol: {sm: {span: number}, xs: {span: number}}}, formItemLayoutAddress: {wrapperCol: {md: {span: number}, sm: {span: number}, xs: {span: number}}, labelCol: {sm: {span: number}, xs: {span: number}}}}}
 */
export function renderFormViewLayout() {
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 18 },
    },
  };

  const formItemLayoutAddress = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 3 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
      md: { span: 17 },
    },
  };

  return {
    formItemLayout,
    formItemLayoutAddress,
  };
}

/**
 * Render layout columns, rows
 * @returns {{formItemLayout: {wrapperCol: {md: {span: number}, sm: {span: number}, xs: {span: number}}, labelCol: {sm: {span: number}, xs: {span: number}}}, formItemLayoutAddress: {wrapperCol: {md: {span: number}, sm: {span: number}, xs: {span: number}}, labelCol: {sm: {span: number}, xs: {span: number}}}}}
 */
export function renderEditFormLayout() {
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
    wrappercol: {
      xs: { span: 24 },
      sm: { span: 12 },
      md: { span: 24 },
    },
  };
  const formItemLayoutAddress = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
    wrappercol: {
      xs: { span: 24 },
      sm: { span: 12 },
      md: { span: 24 },
    },
  };

  const formShortItemAddress = {
    labelCol: {
      xs: { span: 20 },
      sm: { span: 18 },
    },
    wrappercol: {
      xs: { span: 21 },
      sm: { span: 21 },
      md: { span: 21 },
    },
  };

  return {
    formItemLayout,
    formItemLayoutAddress,
    formShortItemAddress,
  };
}

export function renderFooterForm({
  handleSubmit,
  handleModalVisible,
  handleModalVisibleConfirmAddress,
  buttonSave = 'Lưu',
  buttonCancel = 'Thoát',
  buttonConfirmAddress = 'Xác định vị trí',
  renderMore = null,
  loading,
}) {
  // @ts-ignore
  return (
    <React.Fragment>
      {renderMore || (
        <div className="crm-modal-footer">
          {handleModalVisible ? (
            <Button
              type="default"
              style={{ marginLeft: 8 }}
              onClick={e => handleModalVisible(false, e)}
              loading={loading}
            >
              {buttonCancel}
            </Button>
          ) : null}

          {handleSubmit ? (
            <Button type="primary" htmlType="submit" onClick={e => handleSubmit(e)}>
              {buttonSave}
            </Button>
          ) : null}
          {handleModalVisibleConfirmAddress ? (
            <Button type="primary" htmlType="submit" onClick={e => handleSubmit(e)}>
              {buttonConfirmAddress}
            </Button>
          ) : null}
        </div>
      )}
    </React.Fragment>
  );
}

import _ from 'lodash';
import { apiGet, apiPost, apiPut } from '@/utils/request';

export function getAll(query) {
  return apiGet(`${BASE_API_URL}/services/app/CustomerCase/GetAll`, query);
}

export function getAllData(query) {
  return apiGet(`${BASE_API_URL}/services/app/CustomerCase/GetAllData`, query);
}

export function get(id) {
  return apiGet(`${BASE_API_URL}/services/app/CustomerCase/GetDetail`, { id: id });
}

export function getCallingDetail(caseId, rootPbxId, pbxId) {
  return apiGet(
    `${BASE_API_URL}/services/app/CallCenter/GetTelephonyDetail`,
    _.omitBy(
      {
        caseId,
        rootPbxId,
        pbxId,
      },
      _.isNil
    )
  );
}

export function create(body) {
  return apiPost(`${BASE_API_URL}/services/app/CustomerCase/Create`, { body });
}

export function autoCreate() {
  return apiPost(`${BASE_API_URL}/services/app/CustomerCase/AutoCreate`);
}

export function autoCreateWithCustomer(id) {
  return apiPost(`${BASE_API_URL}/services/app/CustomerCase/AutoCreate?customerId=${id}`);
}

export function update(body) {
  return apiPut(`${BASE_API_URL}/services/app/CustomerCase/Update`, { body });
}

export async function getRecentCases(query) {
  return await apiGet(`${BASE_API_URL}/services/app/CustomerCase/Recent`, query);
}

export async function getHistoriesCase(id) {
  return await apiGet(`${BASE_API_URL}/services/app/CustomerCase/${id}/histories`);
}

export async function getActivityLogs(query) {
  return await apiGet(`${BASE_API_URL}/services/app/CallCenter/GetAll`, query);
}

export async function getProducts({ id, Keyword }) {
  return await apiGet(
    `${BASE_API_URL}/services/app/Customer/${id}/products/recent?phone=${Keyword}`
  );
}

export async function updateExternalWarranty(body) {
  return await apiPost(`${BASE_API_URL}/externals/cpm/update-warranty-activation`, { body });
}

export async function callTelephony(body) {
  return await apiPost(`${BASE_API_URL}/services/app/CallCenter/Telephony`, { body });
}
export async function callTelephonyRecall(body) {
  return await apiPost(`${BASE_API_URL}/services/app/CallCenter/Telephony/recare`, { body });
}

export async function callDialingCare(query) {
  return await apiGet(`${BASE_API_URL}/services/app/CallCenter/Telephony`, query);
}

export async function callDialAnswerCare(query) {
  return await apiGet(`${BASE_API_URL}/services/app/CallCenter/Telephony`, query);
}

export async function updateCustomerCase({ caseId, body }) {
  return await apiPut(`${BASE_API_URL}/services/app/CustomerCase/${caseId}/customer`, { body });
}

export async function createCustomerCase({ id, body }) {
  return await apiPost(`${BASE_API_URL}/services/app/CustomerCase/${id}/child`, { body });
}

export async function confirmCustomerCase({ id, body }) {
  return await apiPut(`${BASE_API_URL}/services/app/CustomerCase/${id}/object-confirm`, { body });
}

export async function confirmedCustomerCase({ id, body }) {
  return await apiPut(`${BASE_API_URL}/services/app/CustomerCase/${id}/confirmed`, { body });
}

export async function closeCustomerCase({ id, body }) {
  return await apiPut(`${BASE_API_URL}/services/app/CustomerCase/${id}/close`, { body });
}

export async function childCases({ id, ...query }) {
  return await apiGet(`${BASE_API_URL}/services/app/CustomerCase/${id}/child`, query);
}
export async function updateAfterDone(body) {
  return await apiPut(`${BASE_API_URL}/services/app/CustomerCase/UpdatedInfoAfterDone`, { body });
}
export async function cloneCustomerCase(id) {
  return await apiPost(`${BASE_API_URL}/services/app/CustomerCase/clone/${id}`);
}

import { notification } from 'antd';
import _ from 'lodash';
import { handleParamInventory, updateInventoryAccLines } from '@/pages/OrderAccessories/utils';
import * as orderAccessoriesAPI from '../services/orderAccessories';

export default {
  namespace: 'orderAccessories',

  state: {
    data: [],
    item: {},
    conditionType: [],
    typeAccessoriesOrders: [],
    wareHouse: [],
    statusList: [],
    visibleModalView: false,
    deliveryInfo: {},
    historyInfo: [],
    fileList: [],
    visibleModalReceive: false,
    receiveInfo: {},
  },

  effects: {
    *getAll({ payload }, { call, put }) {
      const response = yield call(orderAccessoriesAPI.getAllData, payload);
      if (response.success) {
        yield put({
          type: 'saveAccessories',
          payload: {
            items: response.result,
          },
        });
      }
    },

    *get({ payload }, { call, put }) {
      const response = yield call(orderAccessoriesAPI.get, payload.id);
      if (response.success) {
        const { result } = response;
        const { accessoriesOrderLines } = result;
        if (!_.isEmpty(accessoriesOrderLines)) {
          result.accessoriesOrderLines = accessoriesOrderLines.map(el => {
            el.key = Math.random();
            return el;
          });
        }

        /**
         * Save item
         */
        yield put({
          type: 'saveItem',
          payload: {
            item: result,
          },
        });

        /**
         * Save shipping info when get detail
         */
        const { shippingInfo } = result;
        yield put({
          type: 'payment/saveShippingInfo',
          payload: {
            data: shippingInfo,
          },
        });

        return result;
      }

      return null;
    },
    *getReceiveInfo({ payload }, { call, put }) {
      const response = yield call(orderAccessoriesAPI.getReceiveInfo, payload.id);
      if (response.success) {
        const { result } = response;

        /**
         * Save item
         */
        yield put({
          type: 'saveItemReceiveInfo',
          payload: {
            item: result,
          },
        });
        return result;
      }

      return null;
    },
    *getHistoriesAccessories({ payload }, { call, put }) {
      const response = yield call(orderAccessoriesAPI.getHistoriesAccessories, payload.id);
      if (response.success) {
        yield put({
          type: 'saveHistoriesAccessories',
          payload: {
            data: response.result.items,
          },
        });
      }
    },

    *create({ payload }, { call, put }) {
      const response = yield call(orderAccessoriesAPI.create, payload);
      if (response.success) {
        yield put({
          type: 'saveVisibleModalView',
          payload: {
            status: false,
          },
        });

        yield notification.success({
          message: 'Đặt linh kiện',
          description: 'Đã đặt linh kiện thành công',
        });
      }
      return Promise.resolve(response.success);
    },

    *update({ payload }, { call, put }) {
      const response = yield call(orderAccessoriesAPI.update, payload);
      if (response.success) {
        yield put({
          type: 'saveVisibleModalView',
          payload: {
            status: false,
          },
        });
        yield put({
          type: 'saveItem',
          payload: {
            item: response.result,
          },
        });

        yield notification.success({
          message: 'Cập nhật đặt linh kiện',
          description: 'Đã cập nhật đặt linh kiện thành công',
        });
      }
      return Promise.resolve(response.success);
    },

    *getAccessoriesConditionType(_, { call, put }) {
      const response = yield call(orderAccessoriesAPI.getConditionType);
      if (response.success) {
        yield put({
          type: 'saveAccessoriesConditionType',
          payload: {
            items: response.result.items,
          },
        });
      }
    },

    *getTypeAccessoriesOrderRef({ payload }, { select, put }) {
      const businessData = yield select(state => state.business.businessData);
      const data = _.get(businessData, 'AccessoriesOrder.Type');
      if (data) {
        yield put({
          type: 'saveTypeAccessoriesOrder',
          payload: {
            items: data,
          },
        });
      }
    },

    *getStatusAccessoriesOrderRef({ payload }, { select, put }) {
      const businessData = yield select(state => state.business.businessData);
      const data = _.get(businessData, 'AccessoriesOrder.Status');
      if (data) {
        yield put({
          type: 'saveStatusAccessoriesOrder',
          payload: {
            items: data,
          },
        });
      }
    },

    *getWareHouse(_, { call, put }) {
      const response = yield call(orderAccessoriesAPI.getWareHouse);
      if (response.success) {
        yield put({
          type: 'saveWareHouse',
          payload: {
            items: response.result.items,
          },
        });
      }
    },

    *approve({ payload }, { put, call }) {
      const response = yield call(orderAccessoriesAPI.approve, payload.id);
      if (response.success) {
        yield put({
          type: 'saveItem',
          payload: {
            item: response.result,
          },
        });

        yield notification.success({
          message: 'Xác nhận',
          description: 'Xác nhận thành công',
        });
      }
    },

    *reject({ payload }, { put, call }) {
      const response = yield call(orderAccessoriesAPI.reject, payload.id);
      if (response.success) {
        yield put({
          type: 'saveItem',
          payload: {
            item: response.result,
          },
        });

        yield notification.success({
          message: 'Từ chối',
          description: 'Từ chối thành công',
        });
      }
    },

    *cancel({ payload }, { put, call }) {
      const response = yield call(orderAccessoriesAPI.cancel, payload.id, payload.note);
      if (response.success) {
        yield put({
          type: 'saveItem',
          payload: {
            item: response.result,
          },
        });

        yield notification.success({
          message: 'Hủy phiếu linh kiện',
          description: 'Hủy phiếu linh kiện thành công',
        });
      }
    },

    *revertToReceiving({ payload }, { put, call }) {
      const response = yield call(orderAccessoriesAPI.revertToReceiving, payload.id);
      if (response.success) {
        yield put({
          type: 'saveItem',
          payload: {
            item: response.result,
          },
        });

        yield notification.success({
          message: 'Chuyển trạng thái phiếu LK',
          description: 'Đã chuyển trạng thái phiếu linh kiện thành công',
        });
      } else {
        yield notification.error({
          message: 'Chuyển trạng thái phiếu LK',
          description: 'Lỗi khi chuyển trạng thái phiếu LK',
        });
      }
    },

    *changeVisibleModalView({ payload }, { put }) {
      yield put({
        type: 'saveVisibleModalView',
        payload,
      });
    },
    *changeVisibleModalReceive({ payload }, { put }) {
      yield put({
        type: 'saveVisibleModalReceive',
        payload,
      });
    },

    *checkInventory({ payload }, { call }) {
      try {
        const response = yield call(orderAccessoriesAPI.checkInventory, payload);
        if (response.success) {
          return response.result.items;
        }

        return null;
      } catch (error) {
        notification.error({
          message: 'Lỗi!',
          description: 'Không thể lấy thông tin tồn kho',
        });
        return null;
      }
    },
    *checkProductLinesLimit({ payload }, { call }) {
      const response = yield call(orderAccessoriesAPI.checkProductLinesLimit, payload);
      if (response.success) {
        return response.result;
      }
    },

    *checkSAInventory({ payload }, { call }) {
      try {
        const response = yield call(orderAccessoriesAPI.checkSAInventory, payload);
        if (response.success) {
          return response.result.items;
        }

        return null;
      } catch (error) {
        notification.error({
          message: 'Lỗi!',
          description: 'Không thể lấy thông tin tồn kho',
        });
        return null;
      }
    },

    /**
     * Get Inventory for each accessoriesOrderLines
     */
    *handleInventory({ payload, forceUpdatePrice }, { put }) {
      const {
        warehouseCode,
        internalWarehouseId,
        conditionTypeCode,
        accessoriesOrderLines,
        receiverId,
        receiverType,
      } = payload;
      const type = _.isEmpty(warehouseCode) ? 'checkSAInventory' : 'checkInventory';
      const inventories = yield put({
        type,
        payload: {
          warehouseCode,
          internalWarehouseId,
          body: {
            items: handleParamInventory(accessoriesOrderLines, conditionTypeCode, type),
            objectId: receiverId,
            objectType: receiverType,
          },
        },
      });
      const newInventories = yield inventories;
      payload.accessoriesOrderLines = updateInventoryAccLines(
        accessoriesOrderLines,
        newInventories,
        forceUpdatePrice
      );

      /**
       * Save item
       */
      yield put({
        type: 'saveItem',
        payload: {
          item: payload,
        },
      });
    },

    *getDeliveryInfo({ payload }, { call, put }) {
      try {
        const response = yield call(orderAccessoriesAPI.getDeliveryInfo, payload.code);
        if (response.success) {
          yield put({
            type: 'saveDeliveryInfo',
            payload: {
              items: response.result || {},
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    *uploadImage({ payload }, { call, put }) {
      const response = yield call(orderAccessoriesAPI.uploadImage, payload);
      return _.get(response, 'result');
    },
    *getProductImages({ payload }, { call, put }) {
      const response = yield call(orderAccessoriesAPI.getProductImages, payload);
      return _.get(response, 'result');
    },
    *deleteProductImage({ payload }, { call, put }) {
      const response = yield call(orderAccessoriesAPI.deleteProductImage, payload);
      return _.get(response, 'success');
    },
    *receiveProcess({ payload }, { call, put }) {
      const response = yield call(orderAccessoriesAPI.processCS, payload.id, payload);
      return _.get(response, 'success');
    },
    *validateSerial({ payload }, { call, put }) {
      const response = yield call(orderAccessoriesAPI.validateSerial, payload.id, payload);
      return _.get(response, 'success');
    },
    *calFee({ payload }, { call }) {
      const { tplCode, item } = payload;
      const response = yield call(orderAccessoriesAPI.calFee, tplCode, item);
      return _.get(response, 'data');
    },

    *getShippingTypes({ payload }, { call }) {
      const { tplCode, item } = payload;
      const response = yield call(orderAccessoriesAPI.getShippingTypes, tplCode, item);
      return _.get(response, 'data');
    },
  },

  reducers: {
    saveSearchData(state, { payload }) {
      return {
        ...state,
        searchData: {
          ...payload,
        },
      };
    },
    saveAccessories(state, { payload }) {
      return {
        ...state,
        data: payload.items,
      };
    },
    saveAccessoriesConditionType(state, { payload }) {
      return {
        ...state,
        conditionType: payload.items,
      };
    },
    saveHistoriesAccessories(state, { payload }) {
      const { data } = payload;
      return {
        ...state,
        historyInfo: data,
      };
    },
    saveTypeAccessoriesOrder(state, { payload }) {
      return {
        ...state,
        typeAccessoriesOrders: payload.items,
      };
    },
    saveStatusAccessoriesOrder(state, { payload }) {
      return {
        ...state,
        statusList: payload.items,
      };
    },
    saveWareHouse(state, { payload }) {
      return {
        ...state,
        wareHouse: payload.items,
      };
    },
    saveVisibleModalView(state, { payload }) {
      return {
        ...state,
        visibleModalView: payload.status,
      };
    },
    saveVisibleModalReceive(state, { payload }) {
      return {
        ...state,
        visibleModalReceive: payload.status,
      };
    },
    saveItem(state, { payload }) {
      const { item } = payload;
      return {
        ...state,
        item,
      };
    },
    saveItemReceiveInfo(state, { payload }) {
      const { item } = payload;
      return {
        ...state,
        receiveInfo: item,
      };
    },
    saveDeliveryInfo(state, { payload }) {
      return {
        ...state,
        deliveryInfo: payload.items,
      };
    },
    saveImage(state, { payload }) {
      return {
        ...state,
        fileList: payload,
      };
    },
  },
};

export default {
  'validation.name.required': 'Vui lòng nhập họ tên',
  'validation.discountrate.required': 'Vui lòng tên chiết khấu',
  'validation.email.required': 'Vui lòng nhập email',
  'validation.phoneNumber.required': 'Vui lòng nhập số điện thoại',
  'validation.customerAddress.required': 'Vui lòng nhập địa chỉ',
  'validation.customerImportant.required': 'Vui lòng nhập chọn mức độ',
  'validation.userInputHeader.required': 'Vui lòng nhập tên dịch vụ hỗ trợ',
  'validation.customerStatus.required': 'Vui lòng nhập trạng thái hỗ trợ',
  'validation.userInputNote.required': 'Vui lòng nhập tên mô tả dịch vụ hỗ trợ',
  'validation.serviceType.required': 'Vui lòng chọn hình thức hỗ trợ',
  'validation.taxNumber.required': 'Vui lòng nhập mã số thuế',
  'validation.type.required': 'Vui lòng chọn loại',
  'validation.address.required': 'Vui lòng nhập địa chỉ',
  'validation.priority.required': 'Vui lòng chọn độ ưu tiên',
  'validation.code.required': 'Vui lòng nhập mã khách hàng',
  'validation.phone.required': 'Vui lòng nhập số điện thoại',
  'validation.stationName.required': 'Vui lòng nhập tên trạm',
  'validation.stationCode.required': 'Vui lòng nhập mã trạm',
  'validation.taxCode.required': 'Vui lòng nhập mã số thuế',
  'validation.contact.required': 'Vui lòng nhập người liên hệ',
  'validation.titleCode.required': 'Vui lòng nhập chức vụ',
  'validation.stationGroup.required': 'Vui lòng chọn nhóm trạm',
  'validation.relationshipCode.required': 'Vui lòng mối quan hệ',
  'validation.customerParentId.required': 'Vui lòng khách hàng liên quan',
  'validation.phoneAdded.required': 'Số điện thoại này đã được thêm',
  'validation.statusProduct.required': 'Vui lòng nhập tình trạng',
  'validation.qty.required': 'Vui lòng nhập số lượng',
  'validation.note.required': 'Vui lòng nhập ghi chú',
  'validation.accessories.required': 'Vui lòng chọn linh kiện',
  'validation.setProduct.required': 'Vui lòng chọn sản phẩm',
  'validation.buyingPlace.required': 'Vui lòng nhập nơi mua',
  'validation.gender.required': 'Vui lòng chọn giới tính',
  'validation.employeeCode.required': 'Vui lòng nhập mã nhân viên',
  'validation.emailAddress.required': 'Vui lòng nhập email',
  'validation.branchId.required': 'Vui lòng chọn chi nhánh',
  'validation.departmentId.required': 'Vui lòng chọn phòng ban',
  'validation.partId.required': 'Vui lòng chọn bộ phận',
  'validation.titleId.required': 'Vui lòng chọn chức vụ',
  'validation.stationId.required': 'Vui lòng chọn trạm bảo hành',
  'validation.password.required': 'Vui lòng nhập mật khẩu',
  'validation.passwordConfirm.required': 'Vui lòng nhập mật khẩu xác nhận',
  'validation.sex.required': 'Vui lòng nhập giới tính',
  'validation.province.required': 'Vui lòng nhập tỉnh thành',
  'validation.district.required': 'Vui lòng nhập quận huyện',
  'validation.ward.required': 'Vui lòng nhập phường xã',
  'validation.year.required': 'Vui lòng chọn năm',
  'validation.reasonNote.required': 'Vui lòng nhập lý do cập nhật',
  'validation.phoneNumber.notValid': 'Số điện thoại không hợp lệ',
  'validation.phoneNumber.notValidLength': 'Độ dài số điện thoại phải từ 10 đến 11 số',
  'validation.email.notValid': 'Email không hợp lệ',
  'validation.object.required': 'Vui lòng chọn đối tượng',
  'validation.warehouseCode.required': 'Vui lòng chọn kho',
  'validation.conditionTypeCode.required': 'Vui lòng chọn tình trạng hàng hóa',
  'validation.pricingTermCode.required': 'Vui lòng chọn loại giá',
  'validation.required': 'Vui lòng chọn',
  'validation.paymentMethod.required': 'Vui lòng chọn phương thức thanh toán',
  'validation.paymentTerm.required': 'Vui lòng chọn điều thánh thanh toán',
  'validation.vatPercent.required': 'Vui lòng điền VAT',
  'validation.prePaymentAmount.required': 'Vui lòng điền số tiền trả trước',
  'validation.contactName.required': 'Vui lòng nhập người liên lạc',
  'validation.warrantyDuration.required': 'Vui lòng nhập thời gian bảo hành',
  'validation.productName.required': 'Vui lòng nhập tên sản phẩm',
  'validation.storageType.required': 'Vui lòng chọn loại lưu trữ',
  'validation.sku.required': 'Vui lòng nhập mã',
  'validation.productCategory.required': 'Vui lòng chọn ngành hàng',
  'validation.unitCode.required': 'Vui lòng chọn đơn vị tính',
  'validation.productUnit.required': 'Vui lòng chọn đơn vị tính',
  'validation.productBrand.required': 'Vui lòng chọn nhãn hiệu',
  'validation.keyword.required': 'Vui lòng nhập từ khóa',
  'validation.keyword.length': 'Từ khóa phải lớn hơn {length} ký tự',
  'validation.max.length': 'Tối đa {length} ký tự',
  'validation.quotaCeilingAmountNormal.required': 'Vui lòng nhập hàng mức thường',
  'validation.quotaCeilingAmountHot.required': 'Vui lòng nhập hàng mức nóng',
  'validation.purchaseDate.required': 'Vui lòng nhập ngày mua hàng',
  'validation.warrantyDate.required': 'Vui lòng nhập hạn bảo hành',
};

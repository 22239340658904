/* eslint-disable no-undef */
import { apiGet, apiPost } from '@/utils/request';

export function query() {
  return apiGet('/api/users');
}

export function current() {
  return apiGet(`${BASE_API_URL}/services/app/Session/GetCurrentLoginInformations`);
}

export function getForSelect(params) {
  return apiGet(`${BASE_API_URL}/services/app/User/GetForSelect`, params);
}

export function changePassword(body) {
  return apiPost(`${BASE_API_URL}/services/app/User/ChangePassword`, { body });
}

export function getKeyRequireSignature() {
  return apiGet(`${BASE_API_URL}/services/app/User/key-require-signature`);
}

export function updateKeyRequireSignature(body) {
  return apiPost(`${BASE_API_URL}/services/app/User/key-require-signature`, { body });
}

import { notification } from 'antd';
import _ from 'lodash';
import * as roleAPI from '../services/role';
import { setAuthority } from '@/utils/authority';
import { reloadAuthorized } from '@/utils/Authorized';

export default {
  namespace: 'role',

  state: {
    data: [],
    currentRoles: [],
    item: {},
    itemForEdit: {},
    visibleModalView: false,
  },

  effects: {
    *getAll({ payload }, { call, put }) {
      const { success, result } = yield call(roleAPI.getAll, payload);
      if (success) {
        yield put({
          type: 'saveData',
          payload: {
            items: result,
          },
        });
        return result.items;
      }
      return [];
    },
    *getForSelect({ payload }, { call, put }) {
      const { success, result } = yield call(roleAPI.getForSelect, payload);
      if (success) {
        yield put({
          type: 'saveSelectData',
          payload: {
            items: result,
          },
        });
        return result.items;
      }
      return [];
    },

    *get({ payload }, { call, put }) {
      const response = yield call(roleAPI.get, payload.id);
      if (response.success) {
        const { result } = response;
        yield put({
          type: 'saveItem',
          payload: {
            item: result,
          },
        });
        return result;
      }
      return null;
    },

    *getRoles({ payload }, { call, put }) {
      const { success, result,dispath } = yield call(roleAPI.getRole);
      if (success) {
        const per = _.get(result, 'auth.grantedPermissions', []);
        const setting = _.get(result, 'setting.values', []);
        const roles = _.keys(per);
        yield put({
          type: 'saveRoles',
          payload: {
            roles,
          },
        });
        yield put({
          type: 'setting/addSettingGlobal',
          payload: setting
        })
        
        setAuthority(roles);
        reloadAuthorized();

        return { success, roles };
      }
      return null;
    },

    *getForEdit({ payload }, { call, put }) {
      const response = yield call(roleAPI.getForEdit, payload.id);
      if (response.success) {
        const { result } = response;
        yield put({
          type: 'saveItemForEdit',
          payload: {
            item: result,
          },
        });
        return result;
      }
      return null;
    },

    *create({ payload }, { call, put }) {
      const response = yield call(roleAPI.create, payload);
      if (response.success) {
        yield put({
          type: 'saveVisibleModalView',
          payload: {
            status: false,
          },
        });

        yield notification.success({
          message: 'Tạo nhóm quyền',
          description: 'Đã tạo nhóm quyền thành công',
        });
        yield put({
          type: 'saveItem',
          payload: {
            item: response.result,
          },
        });
      }
      return Promise.resolve(response.success);
    },

    *update({ payload }, { call, put }) {
      const response = yield call(roleAPI.update, payload);
      if (response.success) {
        yield put({
          type: 'saveVisibleModalView',
          payload: {
            status: false,
          },
        });
        yield put({
          type: 'saveItem',
          payload: {
            item: response.result,
          },
        });

        yield notification.success({
          message: 'Cập nhật nhóm quyền',
          description: 'Đã cập nhật nhóm quyền thành công',
        });
      }
      return Promise.resolve(response.success);
    },

    *delete({ payload }, { call }) {
      const response = yield call(roleAPI.deleteRole, payload.id);
      if (response.success) {
        yield notification.success({
          message: 'Xóa nhóm quyền',
          description: 'Đã xóa nhóm quyền thành công',
        });
      }
      return Promise.resolve(response.success);
    },

    *changeVisibleModalView({ payload }, { put }) {
      yield put({
        type: 'saveVisibleModalView',
        payload,
      });
    },
  },

  reducers: {
    saveSearchData(state, { payload }) {
      return {
        ...state,
        searchData: {
          ...payload,
        },
      };
    },
    saveData(state, { payload }) {
      return {
        ...state,
        data: payload.items,
      };
    },
    saveSelectData(state, { payload }) {
      return {
        ...state,
        selectData: payload.items,
      };
    },
    saveVisibleModalView(state, { payload }) {
      return {
        ...state,
        visibleModalView: payload.status,
      };
    },
    saveItem(state, { payload }) {
      const { item } = payload;
      return {
        ...state,
        item,
      };
    },
    saveRoles(state, { payload }) {
      const { roles } = payload;
      return {
        ...state,
        currentRoles: roles.join(','),
      };
    },
    saveItemForEdit(state, { payload }) {
      const { item } = payload;
      return {
        ...state,
        itemForEdit: item,
      };
    },
  },
};

import { queryNotices, readNotification, readAllNotification } from '@/services/api';
import _ from 'lodash';

export default {
  namespace: 'global',

  state: {
    collapsed: false,
    notices: [],
  },

  effects: {
    *fetchNotices({ payload = { CurrentPage: 1, MaxResultCount: 20 } }, { call, put }) {
      const data = yield call(queryNotices, payload);
      const items = _.get(data, 'result.items', []);
      yield put({
        type: 'saveNotices',
        payload: {
          notices: items,
          pagination: payload
        },
      });
      yield put({
        type: 'user/changeNotifyCount',
        payload: {
          totalCount: _.get(data, 'result.totalCount', 0),
          unreadCount: _.get(data, 'result.badge', 0),
        },
      });

      yield put({
        type: 'login/saveStatus',
        payload: {
          status: true
        },
      });
    },
    *fetchingMoreNotices({ payload = { CurrentPage: 1, MaxResultCount: 20 } }, { call, put, select }) {
      const data = yield call(queryNotices, payload);
      const items = _.get(data, 'result.items', []);
      const notices = yield select(state => {
        return state.global.notices || [];
      })
      yield put({
        type: 'saveNotices',
        payload: {
          notices: notices.concat(items),
          pagination: payload
        },
      });
      yield put({
        type: 'user/changeNotifyCount',
        payload: {
          totalCount: _.get(data, 'result.totalCount', 0),
          unreadCount: _.get(data, 'result.badge', 0),
        },
      });
    },
    *clearNotices({ payload }, { put, select }) {
      yield put({
        type: 'saveClearedNotices',
        payload,
      });
      const count = yield select(state => state.global.notices.length);
      const unreadCount = yield select(
        state => state.global.notices.filter(item => !item.read).length
      );
      yield put({
        type: 'user/changeNotifyCount',
        payload: {
          totalCount: count,
          unreadCount,
        },
      });
    },

    *clearAllNotices({ payload }, { call, put, select }) {
      yield call(readAllNotification);
      const notices = yield select(state =>
        state.global.notices.map(item => {
          const notice = { ...item };
          notice.state = 1;
          return notice;
        })
      );

      yield put({
        type: 'saveNotices',
        payload: {
          notices
        },
      });
      yield put({
        type: 'user/changeNotifyCount',
        payload: {
          totalCount: 0,
          unreadCount: 0,
        },
      });
    },
    *changeNoticeReadState({ payload }, { call, put, select }) {
      yield call(readNotification, payload);
      const notices = yield select(state =>
        state.global.notices.map(item => {
          const notice = { ...item };
          if (notice.id === payload) {
            notice.state = 1;
          }
          return notice;
        })
      );
      const currentUser = yield select(state => {
        return _.get(state.user, 'currentUser');
      });
      yield put({
        type: 'saveNotices',
        payload: {
          notices
        },
      });
      yield put({
        type: 'user/changeNotifyCount',
        payload: {
          totalCount: currentUser.notifyCount,
          unreadCount: currentUser.unreadCount > 0 ? currentUser.unreadCount - 1 : 0,
        },
      });
    },
  },

  reducers: {
    changeLayoutCollapsed(state, { payload }) {
      return {
        ...state,
        collapsed: payload,
      };
    },
    saveNotices(state, { payload }) {
      return {
        ...state,
        notices: payload.notices,
        noticePagination: payload.pagination || state.noticePagination
      };
    },
    saveClearedNotices(state, { payload }) {
      return {
        ...state,
        notices: state.notices.filter(item => item.type !== payload),
      };
    },
  },

  subscriptions: {
    setup({ history }) {
      // Subscribe history(url) change, trigger `load` action if pathname is `/`
      return history.listen(({ pathname, search }) => {
        if (typeof window.ga !== 'undefined') {
          window.ga('send', 'pageview', pathname + search);
        }
      });
    },
  },
};

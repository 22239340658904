import { apiGet, apiPost, apiPut, apiDownloadFile } from '@/utils/request';

export function getAll(query) {
  return apiGet(`${BASE_API_URL}/services/app/Product/GetAll`, query);
}

export function get(id) {
  return apiGet(`${BASE_API_URL}/services/app/Product/GetDetail`, { id: id });
}

export function create(body) {
  return apiPost(`${BASE_API_URL}/services/app/Product/Create`, { body });
}

export function update(body) {
  return apiPut(`${BASE_API_URL}/services/app/Product/Update`, { body });
}

export function search(query) {
  return apiGet(`${BASE_API_URL}/services/app/Product/Search`, query);
}

export function searchSerial(query) {
  return apiGet(`${BASE_API_URL}/services/app/Product/SearchSerialSkuWarrantyRequest`, query);
}

export function getCategories(query) {
  return apiGet(`${BASE_API_URL}/services/app/Category/GetAll`, query);
}

export function getBrands(query) {
  return apiGet(`${BASE_API_URL}/services/app/Brand/GetAll`, query);
}

export function getUnits(query) {
  return apiGet(`${BASE_API_URL}/services/app/Unit/GetAll`, query);
}
export function exportProductImport(body) {
  return apiDownloadFile(
    `${BASE_API_URL}/services/app/Product/exportProductImport?id=${body.id}`,
    body
  );
}
export function exportProductPrice(payload) {
  console.log('payloadService', payload);
  return apiDownloadFile(`${BASE_API_URL}/services/app/Product/exportProductPrice`, payload);
}
export function importProductPrice(body) {
  return apiPost(`${BASE_API_URL}/services/app/Product/ImportPriceProduct`, { body });
}

import _ from 'lodash';
import { notification } from 'antd';
import * as customerAPI from '../services/customer';
import {hanldeRowspanHistory} from '../utils/utils'

export default {
  namespace: 'customer',

  state: {
    searchData: {},
    visibleModal: false,
    visibleCustomerModal: false,
    visibleCustomerRelationModal: false,
    modalType: '',
    showTable: false,
    list: [],
    item: {},
    customerRelation: [],
    relationShip: [],
    customerTypes: [],
    historyInfo: [],
    customerRelationSelected: {},
  },

  effects: {
    *fetch({ payload }, { call, put }) {
      const query = Object.assign({});
      _.forEach(payload, (val, key) => {
        if (val) {
          _.set(query, key, val);
        }
      });

      yield put({
        type: 'saveSearchData',
        payload: query,
      });

      const response = yield call(customerAPI.getAll, query);
      if (response.success) {
        yield put({
          type: 'saveList',
          payload: {
            ...response.result,
          },
        });
      }
    },
    *fetchForSelect({ payload }, { call, put }) {
      const query = Object.assign({});
      _.forEach(payload, (val, key) => {
        if (val) {
          _.set(query, key, val);
        }
      });

      const response = yield call(customerAPI.getForSelect, query);
      if (response.success) {
        yield put({
          type: 'saveListForSelect',
          payload: {
            ...response.result,
          },
        });
      }
    },

    *refreshFetch(_, { put, select }) {
      const searchData = yield select(state => state.customer.searchData);

      yield put({
        type: 'fetch',
        payload: searchData,
      });
    },

    *get({ payload }, { call, put }) {
      const response = yield call(customerAPI.get, payload.id);
      if (response.success) {
        yield put({
          type: 'save',
          payload: {
            ...response,
            visibleModal: true,
          },
        });
        let count = 0;
        const customerPhones = response.result.customerPhones.map(el => {
          el.key = count++;
          return el;
        });

        yield put({
          type: 'saveCustomerRelation',
          payload: {
            data: customerPhones,
          },
        });

        if (!_.has(payload, 'parent')) {
          yield put({
            type: 'saveCustomerRelationSelected',
            payload: {
              data: response.result.parent,
            },
          });
        }

        yield put({
          type: 'address/saveDataAddress',
          payload: {
            address: response.result.customerAddress,
          },
        });
      }
    },
    *getHistoriesCustomers({ payload }, { call, put }) {
      const response = yield call(customerAPI.getHistoriesCustomers, payload.id);
      if (response.success) {
        const newData = yield hanldeRowspanHistory(response.result);
        yield put({
          type: 'saveHistoriesCustomers',
          payload: {
            data: newData,
          },
        });
      }
    },

    *create({ payload }, { call, put }) {
      const response = yield call(customerAPI.create, payload);
      if (response.success) {
        yield put({
          type: 'save',
          payload: {
            result: {},
            visibleModal: false,
            customerRelation: [],
          },
        });

        yield notification.success({
          message: 'Thêm khách hàng',
          description: 'Đã thêm khách hàng mới thành công',
        });

        yield put({
          type: 'refreshFetch',
        });
      }
    },

    *createCallCenter({ payload }, { call, put }) {
      let response = null;
      if (payload.id > 0) {
        response = yield call(customerAPI.update, payload);
      } else {
        response = yield call(customerAPI.create, payload);
      }

      if (response.success) {
        yield put({
          type: 'callCenter/saveCustomer',
          payload: {
            customer: response.result,
          },
        });

        yield put({
          type: 'save',
          payload: {
            ...response,
            visibleModal: false,
            result: {},
          },
        });

        yield put({
          type: 'callCenter/getRelationCallCenter',
          payload: {
            customer: response.result,
          },
        });

        /* START:  Update customerId with case id */
        yield put({
          type: 'callCenter/updateCustomerCase',
          payload: {
            caseId: payload.caseId,
            body: {
              customerId: response.result.id,
            },
          },
        });
        /* END:  Update customerId with case id */

        yield notification.success({
          message: 'Thành công',
          description: 'Đã thêm khách hàng cho PTN',
        });
      }
    },

    *update({ payload }, { call, put }) {
      const response = yield call(customerAPI.update, payload);
      if (response.success) {
        yield put({
          type: 'save',
          payload: {
            result: {},
            visibleModal: false,
            customerRelation: [],
          },
        });

        yield notification.success({
          message: 'Cập nhật khách hàng',
          description: 'Đã cập nhật thông tin khách hàng thành công',
        });

        yield put({
          type: 'refreshFetch',
        });
      }
    },

    *changeVisibleModal({ payload }, { call, put }) {
      yield put({
        type: 'saveVisibleModal',
        payload: {
          status: payload.status,
          modalType: payload.modalType,
          item: {},
        },
      });

      yield put({
        type: 'address/resetAddress',
      });
    },

    *getCustomerTypes({ payload }, { select, put }) {
      const businessData = yield select(state => state.business.businessData);
      const data = _.get(businessData, 'Customer.Type');
      if (data) {
        yield put({
          type: 'saveCustomerTypes',
          payload: {
            items: data,
          },
        });
      }
    },

    *getRelationshipForSelect({ payload }, { select, put }) {
      const businessData = yield select(state => state.business.businessData);
      const data = _.get(businessData, 'RelationShip');
      if (data) {
        yield put({
          type: 'saveRelationship',
          payload: {
            items: data,
          },
        });
      }
    },
  },

  reducers: {
    saveVisibleModal(state, { payload }) {
      return {
        ...state,
        visibleModal: payload.status,
        modalType: payload.modalType,
        showTable: payload.showTable,
        ...payload,
      };
    },
    changeVisibleTableModal(state, { payload }) {
      return {
        ...state,
        showTable: payload.status,
      };
    },
    saveList(state, { payload }) {
      return {
        ...state,
        list: payload,
      };
    },
    saveListForSelect(state, { payload }) {
      return {
        ...state,
        listForSelect: payload,
      };
    },
    save(state, { payload }) {
      const { result, visibleModal, customerRelation } = payload;
      return {
        ...state,
        item: result,
        visibleModal,
        customerRelation,
        customerRelationSelected: {},
      };
    },
    saveHistoriesCustomers(state, { payload }) {
      const { data } = payload;
      return {
        ...state,
        historyInfo: data,
      };
    },
    saveCustomerRelation(state, { payload }) {
      return {
        ...state,
        customerRelation: payload.data,
        customerRelationSelected: {}
      };
    },
    saveRelationship(state, { payload }) {
      return {
        ...state,
        relationShip: payload.items,
      };
    },
    saveCustomerTypes(state, { payload }) {
      return {
        ...state,
        customerTypes: payload.items,
      };
    },
    updateCustomerPhones(state, { payload }) {
      const { phoneNumber } = payload;
      const { customerRelation } = state;
      const customerPhones = !_.isEmpty(customerRelation) ? customerRelation : [];
      const lastItem = _.last(customerPhones);
      const count = !_.isEmpty(lastItem) ? lastItem.key + 1 : 0;
      const exists = customerPhones.filter(item => item.phoneNumber === phoneNumber);
      if (_.isEmpty(exists)) {
        customerPhones.push({
          phoneNumber,
          lineNumber: 0,
          key: count,
        });
      }
      return {
        ...state,
        customerRelation: customerPhones,
      };
    },
    saveSearchData(state, { payload }) {
      return {
        ...state,
        searchData: {
          ...payload,
        },
      };
    },

    changeVisibleCustomerModal(state, { payload }) {
      return {
        ...state,
        visibleCustomerModal: payload.status,
      };
    },

    changeVisibleCustomerRelationModal(state, { payload }) {
      return {
        ...state,
        visibleCustomerRelationModal: payload.status,
      };
    },

    saveCustomerRelationSelected(state, { payload }) {
      return {
        ...state,
        customerRelationSelected: payload.data,
      };
    },
  },
};

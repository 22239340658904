import { apiGet, apiPost, apiPut, apiDelete } from '@/utils/request';

export function getAll(query) {
  return apiGet(`${BASE_API_URL}/services/app/Configuration/GetDayOffConfig`, query);
}

export function get(id) {
  return apiGet(`${BASE_API_URL}/services/app/Configuration/GetDayOffConfigDetail`, {'id': id});
}

export function deleteConfig(id) {
  return apiDelete(`${BASE_API_URL}/services/app/Configuration/DeleteDayOffConfig?id=${id}`);
}

export function create(body) {
  return apiPost(`${BASE_API_URL}/services/app/Configuration/ConfigWorkingTime`, {body} );
}

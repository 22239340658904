import { apiGet, apiPost, apiPut } from '@/utils/request';

export function getAll(query) {
  return apiGet(`${BASE_API_URL}/services/app/User/GetAll`, query);
}
export function getForSelect(query) {
  return apiGet(`${BASE_API_URL}/services/app/User/GetForSelect`, query);
}

export function get(id) {
  return apiGet(`${BASE_API_URL}/services/app/User/GetDetail`, {'id': id});
}

export function create(body) {
  return apiPost(`${BASE_API_URL}/services/app/User/Create`, {body} );
}

export function update(body) {
  return apiPut(`${BASE_API_URL}/services/app/User/Update`, {body} );
}

export default {
  'menu.home': 'Home',
  'menu.login': 'Login',
  'menu.register': 'Register',
  'menu.register.result': 'Register Result',
  'menu.dashboard': 'Dashboard',
  'menu.dashboard.analysis': 'Analysis',
  'menu.dashboard.monitor': 'Monitor',
  'menu.dashboard.workplace': 'Workplace',
  'menu.form': 'Form',
  'menu.form.basicform': 'Basic Form',
  'menu.form.stepform': 'Step Form',
  'menu.form.stepform.info': 'Step Form(write transfer information)',
  'menu.form.stepform.confirm': 'Step Form(confirm transfer information)',
  'menu.form.stepform.result': 'Step Form(finished)',
  'menu.form.advancedform': 'Advanced Form',
  'menu.list': 'List',
  'menu.list.searchtable': 'Search Table',
  'menu.list.basiclist': 'Basic List',
  'menu.list.cardlist': 'Card List',
  'menu.list.searchlist': 'Search List',
  'menu.list.searchlist.articles': 'Search List(articles)',
  'menu.list.searchlist.projects': 'Search List(projects)',
  'menu.list.searchlist.applications': 'Search List(applications)',
  'menu.profile': 'Profile',
  'menu.profile.basic': 'Basic Profile',
  'menu.profile.advanced': 'Advanced Profile',
  'menu.result': 'Result',
  'menu.result.success': 'Success',
  'menu.result.fail': 'Fail',
  'menu.exception': 'Exception',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': 'Trigger',
  'menu.account': 'Account',
  'menu.account.center': 'Tài khoản',
  'menu.account.settings': 'Cài đặt',
  'menu.account.changePassword': 'Đổi mật khẩu',
  'menu.account.userguide': 'Hướng dẫn sử dụng',
  'menu.account.techguide': 'Hướng dẫn kỹ thuật',
  'menu.account.updateversion': 'Nhật ký cập nhật',
  'menu.account.trigger': 'Trigger Error',
  'menu.account.logout': 'Đăng xuất',
  'menu.editor': 'Graphic Editor',
  'menu.editor.flow': 'Flow Editor',
  'menu.editor.mind': 'Mind Editor',
  'menu.editor.koni': 'Koni Editor',
  'menu.category': 'Danh mục',
  'menu.category.employees': 'Nhân viên',
  'menu.category.employeeProvince': 'Cấu hình Nhân viên - Tỉnh thành',
  'menu.category.customer': 'Khách hàng',
  'menu.category.warranty_station': 'Trạm bảo hành',
  'menu.category.role': 'Nhóm quyền',
  'menu.category.product': 'Sản phẩm',
  'menu.category.dayoff-config': 'Lịch làm việc',
  'menu.category.discount-rate': 'Chiết khấu',
  'menu.call': 'Tiếp nhận',
  'menu.call_management': 'Cuộc gọi',
  'menu.warranty_request': 'Ca BH',
  'menu.report': 'Báo cáo',
  'menu.report.report_warranty': 'Ca BH',
  'menu.report.report_warranty.report_warranty_request_history': 'Tiếp nhận CA',
  'menu.report.report_warranty.report_tech_revenue': 'Doanh số kỹ thuật',
  'menu.report.report_customer': 'Danh sách khách hàng',
  'menu.report.report_warranty.report_customer_care': 'Tổng xử lý lịch',
  'menu.report.report_accessories_order': 'Đặt linh kiện',
  'menu.report.report_accessories.report_booking_accessories': 'Đặt linh kiện',
  'menu.report.activities.report_working_diary': 'Nhật Ký Công Trình',
  'menu.report.report_accessories.report_accessories_noreturn': 'Linh Kiện Không Thu Hồi',
  'menu.report.report_accessories.report_revenue_of_sale_accessories':
    'Thông tin tính doanh số linh kiện',
  'menu.report.report_accessories.report_return_order': 'Trả linh kiện',
  'menu.report.view_log': 'Log',
  'menu.report_warranty_request_object': 'Ca bảo hành theo đối tượng',
  'menu.report.report_warranty.report_warranty_request_object': 'Ca bảo hành theo đối tượng',
  'menu.report.report_warranty.statisticCase': 'Thống kê Xử lý CA',
  'menu.report.inventories.serial_history': 'Lịch sử Serial',
  'menu.report.inventories.inv_waitingout': 'Linh kiện chờ xuất',
  'menu.report.inventories.inventory': 'Báo cáo Tồn kho',
  'menu.report.inventories.inventory_accessories': 'Báo cáo sao kê tồn kho linh kiện',
  'menu.report.inventories.inventory_inbound_outbound': 'Báo cáo xuất nhập tồn',
  'menu.report.inventories.inventory_dv': 'Báo cáo Tồn kho Đại Việt',
  'menu.report.report_accessories.accessories_order_prducts': 'LK trong phiếu đặt LK',
  'menu.report.activities.activity_visit': 'Nhật ký công việc',
  'menu.report.receivablemonthly': 'Công nợ theo KT trong tháng',
  'menu.report.summaryreceivablebymonth': 'Tổng hợp phải thu theo tháng',
  'menu.report.activities.timesheets_histories': 'Lịch sử chấm công',
  'menu.report.tech_revenue_summary': 'Tổng hợp doanh số kỹ thuật',
  'menu.report.station_revenue_summary': 'Tổng hợp doanh số TBH',
  'menu.report.station_revenue_detail': 'Chi tiết doanh số TBH',
  'menu.report.station_reward_punishment': 'Chi tiết thưởng phạt TBH',
  'menu.report.station_summary_reward_punishment': 'Tổng hợp thưởng phạt TBH',
  'menu.report.report_warranty.performance_service': 'Tổng hợp thực hiện dịch vụ',
  'menu.report.product_warranty_times': 'Thống kê số lần bảo hành của sản phẩm',
  'menu.order_management': 'Linh kiện',
  'menu.order_management.accessories_order': 'Đặt linh kiện',
  'menu.order_management.accessories-notakenpack': 'Linh kiện không thu hồi xác',
  'menu.order_management.return_order': 'Trả linh kiện',
  'menu.order_management.sa_orders': 'Xuất hàng linh kiện',
  'menu.order_management.transfer_orders': 'Phiếu mượn linh kiện',
  'menu.order_management.orders': 'Đơn hàng linh kiện',
  'menu.order_management.saleorders': 'Đơn hàng TT bán',
  'menu.order_management.conver_session': 'Chuyển đổi linh kiện',
  'menu.order_management.configlimit': 'Cấu hình hạn mức công nợ',
  'menu.category.config-signature': 'Cấu hình',
  //  'menu.sa_orders': 'Xuất hàng',
  'menu.supviser': 'Giám sát',
  'menu.order_management.techaccessoriesreturn': 'ĐHLK Kỹ Thuật',
  'menu.report.logs': 'Logs',
  'menu.warranty_activation': 'Tra Cứu BH',
  'menu.cashflows': 'Thu / Chi',
  'menu.cashflows.cashflow_sessions_detail': 'Chi tiết lần duyệt',
  'menu.cashflows.cashflow_sessions': 'Duyệt doanh số',
  'menu.cashflows.debitcredit': 'Quản lý chứng từ',
  'menu.cashflows.cashflow_debitcredit_detail': 'Chi tiết công nợ',
  'menu.cashflows.debt_accessories_management': 'Quản lý công nợ linh kiện',
  'menu.cashflows.debt_accessories_management_detail': 'Quản lý chi tiết công nợ linh kiện',
  'menu.report.debit': 'Công nợ - Phải chi',
  'menu.report.report_accessories': 'Linh kiện',
  'menu.report.inventories': 'Tồn kho',
  'menu.report.activities': 'Nhật ký',
};

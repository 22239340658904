import _ from 'lodash';
import { notification } from 'antd';
import * as productAPI from '@/services/product';

export default {
  namespace: 'product',

  state: {
    visibleModal: false,
    modalType: '',
    list: [],
    item: {},
    productsSearch: [],
    categories: [],
    brands: [],
    units: [],
    storageTypes: [],
    filterCurrent: {},
  },

  effects: {
    *fetch({ payload }, { call, put }) {
      yield put({
        type: 'saveFilterCurrent',
        payload,
      });
      const query = Object.assign({});
      _.forEach(payload, (val, key) => {
        if (val) {
          _.set(query, key, val);
        }
      });

      const response = yield call(productAPI.getAll, query);
      if (response.success) {
        yield put({
          type: 'saveList',
          payload: {
            ...response.result,
          },
        });
      }
    },

    *get({ payload }, { call, put }) {
      const response = yield call(productAPI.get, payload.id);
      if (response.success) {
        yield put({
          type: 'save',
          payload: {
            ...response,
            visibleModal: true,
          },
        });
      }
    },

    *create({ payload }, { call, put, select }) {
      const response = yield call(productAPI.create, payload);
      if (response.success) {
        yield put({
          type: 'save',
          payload: {
            ...response,
            visibleModal: false,
          },
        });

        yield notification.success({
          message: 'Thêm sản phẩm',
          description: 'Đã thêm sản phẩm mới thành công',
        });

        const filterCurrent = yield select(state => state.product.filterCurrent);

        yield put({
          type: 'fetch',
          payload: filterCurrent,
        });
      }
    },

    *update({ payload }, { call, put, select }) {
      const response = yield call(productAPI.update, payload);
      if (response.success) {
        yield put({
          type: 'save',
          payload: {
            ...response,
            visibleModal: false,
          },
        });

        yield notification.success({
          message: 'Cập nhật sản phẩm',
          description: 'Đã cập nhật thông tin sản phẩm thành công',
        });

        const filterCurrent = yield select(state => state.product.filterCurrent);

        yield put({
          type: 'fetch',
          payload: filterCurrent,
        });
      }
    },

    *changeVisibleModal({ payload }, { call, put }) {
      yield put({
        type: 'saveVisibleModal',
        payload: {
          status: payload.status,
          modalType: payload.modalType,
        },
      });
    },

    *searchProducts({ payload }, { call, put }) {
      const query = Object.assign({});
      _.forEach(payload, (val, key) => {
        if (val) {
          _.set(query, key, val);
        }
      });

      const response = yield call(productAPI.search, query);

      if (response.success) {
        response.result.items.map(
          el => (el.key = `${el.id}-${el.serialNo ? el.serialNo : '0'}-${Math.random()}`)
        );
        yield put({
          type: 'saveProductsSearch',
          payload: {
            ...response.result,
          },
        });
      }
    },

    *getCategories({ payload }, { call, put }) {
      const response = yield call(productAPI.getCategories, payload);
      if (response.success) {
        yield put({
          type: 'saveCategories',
          payload: {
            items: response.result.items,
          },
        });
      }
    },

    *getBrands({ payload }, { call, put }) {
      const response = yield call(productAPI.getBrands, payload);
      if (response.success) {
        yield put({
          type: 'saveBrands',
          payload: {
            items: response.result.items,
          },
        });
      }
    },

    *getUnits({ payload }, { call, put }) {
      const response = yield call(productAPI.getUnits, payload);
      if (response.success) {
        yield put({
          type: 'saveUnits',
          payload: {
            items: response.result.items,
          },
        });
      }
    },

    *getStorageTypes({ payload }, { select, put }) {
      const businessData = yield select(state => state.business.businessData);
      const data = _.get(businessData, 'Product.StorageType');
      if (data) {
        yield put({
          type: 'saveStorageTypes',
          payload: {
            items: data,
          },
        });
      }
    },
    *exportProductImport({ payload }, { call }) {
      return yield call(productAPI.exportProductImport, payload);
    },
    *exportProductPrice({ payload }, { call }) {
      console.log('payload', payload);
      return yield call(productAPI.exportProductPrice, payload);
    },
    *importProductPrice({ payload }, { call }) {
      return yield call(productAPI.importProductPrice, payload);
    },
  },

  reducers: {
    saveVisibleModal(state, { payload }) {
      return {
        ...state,
        visibleModal: payload.status,
        modalType: payload.modalType,
        extensions: [],
      };
    },

    saveList(state, { payload }) {
      return {
        ...state,
        list: payload,
      };
    },
    save(state, { payload }) {
      return {
        ...state,
        item: payload.result,
        visibleModal: payload.visibleModal,
      };
    },
    saveProductsSearch(state, { payload }) {
      return {
        ...state,
        productsSearch: payload,
      };
    },
    saveCategories(state, { payload }) {
      return {
        ...state,
        categories: payload.items,
      };
    },
    saveBrands(state, { payload }) {
      return {
        ...state,
        brands: payload.items,
      };
    },
    saveUnits(state, { payload }) {
      return {
        ...state,
        units: payload.items,
      };
    },
    saveStorageTypes(state, { payload }) {
      return {
        ...state,
        storageTypes: payload.items,
      };
    },
    saveFilterCurrent(state, { payload }) {
      return {
        ...state,
        filterCurrent: payload,
      };
    },
  },
};

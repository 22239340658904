import { apiGet, apiPost, apiPut } from '@/utils/request';

export function getAll() {
  return apiGet(`${BASE_API_URL}/services/app/Payment/GetAll`);
}

export function getPaymentTerms(query) {
  return apiGet(`${BASE_API_URL}/services/app/Payment/GetPaymentTermsForSelect`, query);
}

export function getDefaultShippingInfo(query) {
  return apiGet(`${BASE_API_URL}/services/app/Payment/GetDefaultShippingInfo`, query);
}

export function updateDefaultShippingInfo(body) {
  return apiPut(`${BASE_API_URL}/services/app/Payment/UpdateDefaultShippingInfo`, { body });
}

export function getAllObjects(query = { CurrentPage: 1, MaxResultCount: 20 }) {
  return apiGet(`${BASE_API_URL}/services/app/Payment/GetAllObjects`, query);
}

export function createShippingInfo(body) {
  return apiPost(`${BASE_API_URL}/services/app/Payment/CreateShippingInfo`, { body });
}

export function getShippingInfoes(query) {
  return apiGet(`${BASE_API_URL}/services/app/Payment/GetShippingInfoes`, query);
}

import _ from 'lodash';

export const handleInventory = (assignees, inventory) => {
  const result = assignees.map(assignee => {
     const inData  = inventory.filter(item => {
       const { id, stationId } = assignee.assignee;
       const asgId = stationId > 0 ? stationId : id;
       return ( asgId === item.objectId && item.objectType === "STATION" )
         || (asgId === item.objectId && item.objectType === "EMPLOYEE" );
     });

    const info = [];
    inData.map(el => {
      info.push({
        sku: el.product.sku,
        available: el.available
      })
    });
    assignee.info = info;
    assignee.key = Math.random();
    return assignee;
  });

  return [...result];
};

export const getAssigneeIds = items => {
  const assigneeIds = [];
  _.map(items, i => {
    assigneeIds.push(i.assignee.id);
  });
  return assigneeIds;
};

export const findItemInventory = (items, sku) => {
  const obj = _.find(items, (o) => { return o.sku === sku; });
  return !_.isEmpty(obj) ? obj : {
    sku: null,
    available: 0
  };
};

export const checkAssigneesOrderingQty = assignees => {
  for (let i = 0; i < assignees.length; i++) {
    if (assignees[i].orderingQty > 0) {
      return true;
    }
  }
  return false;
};

export const checkStatusAccessories = data => {
  let flagAccessories = false;
  let assigneesIsExists = false;
  for (let i = 0; i < data.length; i++) {
    const {assignees} = data[i];
    if (!_.isEmpty(assignees)) {
      assigneesIsExists = true;
    }
    flagAccessories = checkAssigneesOrderingQty(assignees);

    if (flagAccessories) break;
  }
  return {
    statusProcess: !flagAccessories && assigneesIsExists,
    statusSetAccessories: flagAccessories,
  };
};

export const hanldeRowspanAccessories = data => {
  const objC = [];
  return data.map((el, idz) => {
    const check = data.filter(els => els.accessories.sku === el.accessories.sku).length;
    let checkAvailable = 0;
    let isMerge = -1;
    if (check > 1 ) {
      isMerge = 0;
      if (objC.indexOf(el.accessories.sku) < 0) {
        objC.push(el.accessories.sku);
        isMerge = 1;
      }
      checkAvailable = check;
    }

    el.checkAvailable = checkAvailable;
    el.isMerge = isMerge;
    return el;
  });
};

/**
 * Auto set primary = true if the data only has one record
 */
export const handleSetPrimary = data => {
  const assignees = data;
  if (!_.isEmpty(assignees) && assignees.length > 0) {
    assignees[0].isPrimary = true;
  }

  return assignees;
};
export const handleSetPercentResponsibility = data => {
  const assignees = data;
  if (!_.isEmpty(assignees) && assignees.length > 0) {
    const percentRow =_.divide(100, assignees.length); 
    _.map(assignees,e=>{
      e.percentResponsibility = e.isPrimary === true ?_.ceil(percentRow): _.floor(percentRow);
    })
  }

  return assignees;
};

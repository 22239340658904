import _ from 'lodash';
import { handleParams } from '@/utils/utils';
import * as businessAPI from '../services/business';
import * as orderAccessoriesAPI from '../services/orderAccessories';
import * as reportAPI from "../services/report";

export default {
  namespace: 'business',

  state: {
    businessData: [],
    branchs: [],
    departments: [],
    parts: [],
    titles: [],
    refs: [],
    stations: [],
    genders: [],
    priorities: [],
    serviceTypes: [],
    serviceStatus: [],
    stationGroup: [],
    customerTypes: [],
    region: [],
    regionForSearchBox: [],
    currentStep: [],
    accessoriesOrder: [],
    callType: [],
    callStatus: [],
    objectTypes: [],
    wareHouses: [],
    processors: [],
    processorsNoPermission: [],
    statusContracts: [],
    taxTypeNumbers: [],
    discounts: [],
    reasonsReject: [],
    regionAll: [],
    provinceAll: []
  },

  effects: {
    *getBusinessData({ payload }, { call, put, all }) {
      const response = yield call(businessAPI.getRefAll, payload);

      if (response.success) {
        const responseProvince = yield call(businessAPI.getProvincesPermission, payload);
        const responseRegion = yield call(businessAPI.getRegionsPermission, payload);
        if (responseProvince.success)
          response.result.Province = responseProvince.result;
        if (responseRegion.success)
          response.result.Region = responseRegion.result;

        yield put({
          type: 'saveBusinessData',
          payload: {
            data: response.result
          },
        });
      }
    },

    *getBusinessAll({ payload }, { put }) {
      yield put({
        type: 'getBranchs',
        payload
      });

      yield put({
        type: 'getTitles',
        payload
      });

      yield put({
        type: 'getStations',
        payload
      });

      yield put({
        type: 'getRefs',
      });

      yield put({
        type: 'getGenders',
      });
    },

    *getServicesCallCenter({ payload }, { put }) {
      yield put({
        type: 'getPriorities',
      });

      yield put({
        type: 'getServiceTypes',
      });

      yield put({
        type: 'getServiceStatus',
      });
    },

    *getBranchs({ payload }, { call, put }) {
      const response = yield call(businessAPI.getBranchs, payload);
      if (response.success) {
        yield put({
          type: 'saveBranchs',
          payload: {
            items: response.result.items,
          },
        });
      }
    },

    *getDepartments({ payload }, { call, put }) {
      const response = yield call(businessAPI.getDepartments, payload);
      if (response.success) {
        yield put({
          type: 'saveDepartments',
          payload: {
            items: response.result.items,
          },
        });
      }
    },

    *getParts({ payload }, { call, put }) {
      const response = yield call(businessAPI.getParts, payload);
      if (response.success) {
        yield put({
          type: 'saveParts',
          payload: {
            items: response.result.items,
          },
        });
      }
    },

    *getTitles({ payload }, { call, put }) {
      const response = yield call(businessAPI.getTitles, payload);
      if (response.success) {
        yield put({
          type: 'saveTitles',
          payload: {
            items: response.result.items,
          },
        });
      }
    },

    *getRefs({ payload }, { put, select }) {
      const businessData = yield select(
        state => state.business.businessData
      );
      const data = _.get(businessData, 'AbpUsers.Type');

      if (data) {
        yield put({
          type: 'saveRefs',
          payload: {
            items: data
          },
        });
      }
    },

    *getStations({ payload }, { call, put }) {
      const response = yield call(businessAPI.getStations, payload);
      if (response.success) {
        yield put({
          type: 'saveStations',
          payload: {
            items: response.result.items,
          },
        });
      }
    },

    *getGenders({ payload }, { put, select }) {
      const businessData = yield select(
        state => state.business.businessData
      );
      const data = _.get(businessData, 'AbpUsers.Sex');
      if (data) {
        yield put({
          type: 'saveGenders',
          payload: {
            items: data
          },
        });
      }
    },

    *updateCategory({ payload }, { call }) {
      const response = yield call(businessAPI.updateCategory, payload);
      return response.success
    },



    *getPriorities({ payload }, { select, put }) {
      const businessData = yield select(
        state => state.business.businessData
      );
      const data = _.get(businessData, 'Cases.Priority');
      if (data) {
        yield put({
          type: 'savePriorities',
          payload: {
            items: data
          },
        });
      }
    },

    *getServiceTypes({ payload }, { select, put }) {
      const businessData = yield select(
        state => state.business.businessData
      );
      const data = _.get(businessData, 'Cases.ServiceType');
      if (data) {
        yield put({
          type: 'saveServiceTypes',
          payload: {
            items: data
          },
        });
      }
    },

    *getServiceStatus({ payload }, { select, put }) {
      const businessData = yield select(
        state => state.business.businessData
      );
      const data = _.get(businessData, 'Cases.Status');
      if (data) {
        yield put({
          type: 'saveServiceStatus',
          payload: {
            items: data
          },
        });
      }
    },

    *getStationGroup({ payload }, { select, put }) {
      const businessData = yield select(
        state => state.business.businessData
      );
      const data = _.get(businessData, 'Station.Group');
      if (data) {
        yield put({
          type: 'saveStationGroup',
          payload: {
            items: data
          },
        });
      }
    },
    *getTaxTypeNumbers({ payload }, { select, put }) {
      const businessData = yield select(
        state => state.business.businessData
      );
      const data = _.get(businessData, 'Station.TaxTypeCode');
      if (data) {
        yield put({
          type: 'saveTaxTypeNumbers',
          payload: {
            items: data
          },
        });
      }
    },
    *getStatusContractStation({ payload }, { select, put }) {
      const businessData = yield select(
        state => state.business.businessData
      );
      const data = _.get(businessData, 'StationContract.Status');
      if (data) {
        yield put({
          type: 'saveStatusContractStation',
          payload: {
            items: data
          },
        });
      }
    },

    *getRegion({ payload }, { select, put }) {
      const businessData = yield select(
        state => state.business.businessData
      );
      const data = _.get(businessData, 'Region');
      if (data) {
        yield put({
          type: 'saveRegion',
          payload: {
            items: data
          },
        });
      }
    },

    *getRegionAll({ payload }, { call, put }) {
      const response = yield call(businessAPI.getRegion, payload);
      if (response.success) {
        yield put({
          type: 'saveRegionAll',
          payload: response.result,
        });
      }
    },

    *getProvincesAll({ payload }, { call, put }) {
      const response = yield call(businessAPI.getProvinces, payload);
      if (response.success) {
        yield put({
          type: 'saveProvincesAll',
          payload: response.result,
        });
      }
    },

    *getRegionForSearchBox({ payload }, { call, put }) {
      const response = yield call(businessAPI.getRegionForSearchBox, payload);
      if (response.success) {
        yield put({
          type: 'saveRegionForSearchBox',
          payload: {
            items: response.result.items,
          },
        });
      }
    },

    *getCurrentStep({ payload }, { call, put }) {
      const response = yield call(businessAPI.getCurrentStep, handleParams(payload));
      if (response.success) {
        yield put({
          type: 'changeCurrentStep',
          payload: {
            items: response.result.items,
          },
        });
      }
    },

    *getAccessoriesOrder({ payload }, { select, put }) {
      const businessData = yield select(
        state => state.business.businessData
      );
      const data = _.get(businessData, 'AccessoriesOrder.Status');
      if (data) {
        yield put({
          type: 'saveAccessoriesOrder',
          payload: {
            items: data
          },
        });
      }
    },

    *getCallType({ payload }, { select, put }) {
      const businessData = yield select(
        state => state.business.businessData
      );
      const data = _.get(businessData, 'PbxManager.Direction');
      if (data) {
        yield put({
          type: 'saveCallType',
          payload: {
            items: data
          },
        });
      }
    },

    *getCallStatus({ payload }, { select, put }) {
      const businessData = yield select(
        state => state.business.businessData
      );
      const data = _.get(businessData, 'PbxManager.Disposition');
      if (data) {
        yield put({
          type: 'saveCallStatus',
          payload: {
            items: data
          },
        });
      }
    },

    *getObjectTypes({ payload }, { select, put }) {
      const businessData = yield select(
        state => state.business.businessData
      );
      const data = _.get(businessData, 'Cases.ObjectType');
      if (data) {
        yield put({
          type: 'saveObjectType',
          payload: {
            items: data
          },
        });
      }
    },

    *getWarehouse(_, { call, put }) {
      const response = yield call(orderAccessoriesAPI.getWareHouse);
      if (response.success) {
        yield put({
          type: 'saveWarehouse',
          payload: {
            items: response.result.items
          },
        });
      }
    },

    *getDiscount(_, { call, put }) {
      const response = yield call(businessAPI.getDiscountRate);
      if (response.success) {
        yield put({
          type: 'saveDiscounts',
          payload: {
            items: response.result.items
          },
        });
      }
    },

    *getProcessors(_, { put, call }) {
      const response = yield call(reportAPI.getUserProcessers);
      if (response.success) {
        yield put({
          type: 'saveProcessors',
          payload: response.result.items,
        });
      }
    },


    *getProcessorsNoPermission(_, { put, call }) {
      const response = yield call(reportAPI.getUserProcessers, { isGetAll: true });
      if (response.success) {
        yield put({
          type: 'saveProcessorsNoPermission',
          payload: response.result.items,
        });
      }
    },
  },

  reducers: {
    saveBusinessData(state, { payload }) {
      return {
        ...state,
        businessData: payload.data
      };
    },
    saveBranchs(state, { payload }) {
      return {
        ...state,
        branchs: payload.items
      };
    },
    saveDiscounts(state, { payload }) {
      return {
        ...state,
        discounts: payload.items
      };
    },
    saveDepartments(state, { payload }) {
      return {
        ...state,
        departments: payload.items
      };
    },
    saveParts(state, { payload }) {
      return {
        ...state,
        parts: payload.items
      };
    },
    saveTitles(state, { payload }) {
      return {
        ...state,
        titles: payload.items
      };
    },
    saveRefs(state, { payload }) {
      return {
        ...state,
        refs: payload.items
      };
    },
    saveStations(state, { payload }) {
      return {
        ...state,
        stations: payload.items
      };
    },
    saveGenders(state, { payload }) {
      return {
        ...state,
        genders: payload.items
      };
    },
    savePriorities(state, { payload }) {
      return {
        ...state,
        priorities: payload.items
      };
    },
    saveServiceTypes(state, { payload }) {
      return {
        ...state,
        serviceTypes: payload.items
      };
    },
    saveServiceStatus(state, { payload }) {
      return {
        ...state,
        serviceStatus: payload.items
      };
    },
    saveStationGroup(state, { payload }) {
      return {
        ...state,
        stationGroup: payload.items
      };
    },
    saveStatusContractStation(state, { payload }) {
      return {
        ...state,
        statusContracts: payload.items
      };
    },
    saveTaxTypeNumbers(state, { payload }) {
      return {
        ...state,
        taxTypeNumbers: payload.items
      };
    },
    saveCustomerType(state, { payload }) {
      return {
        ...state,
        customerTypes: payload.items
      };
    },
    saveRegion(state, { payload }) {
      return {
        ...state,
        region: payload.items
      };
    },
    saveRegionAll(state, { payload }) {
      return {
        ...state,
        regionAll: payload.items
      };
    },
    saveProvincesAll(state, { payload }) {
      return {
        ...state,
        provinceAll: payload.items
      };
    },
    saveRegionForSearchBox(state, { payload }) {
      return {
        ...state,
        regionForSearchBox: payload.items
      };
    },
    changeCurrentStep(state, { payload }) {
      return {
        ...state,
        currentStep: payload.items
      };
    },
    saveAccessoriesOrder(state, { payload }) {
      return {
        ...state,
        accessoriesOrder: payload.items
      };
    },
    saveCallType(state, { payload }) {
      return {
        ...state,
        callType: payload.items
      };
    },
    saveCallStatus(state, { payload }) {
      return {
        ...state,
        callStatus: payload.items
      };
    },
    saveObjectType(state, { payload }) {
      return {
        ...state,
        objectTypes: payload.items
      };
    },
    saveWarehouse(state, { payload }) {
      return {
        ...state,
        wareHouses: payload.items
      };
    },
    saveProcessors(state, { payload }) {
      return {
        ...state,
        processors: payload,
      };
    },
    saveProcessorsNoPermission(state, { payload }) {
      return {
        ...state,
        processorsNoPermission: payload,
      };
    },
  },
};

import _ from 'lodash';
import { notification, message } from 'antd';
import * as productAPI from '@/services/product';
import * as warrantyRequestAPI from '@/services/warrantyRequest';
import { WORK_ORDER_STEP } from '@/configs/constants';

export default {
  namespace: 'warrantyRequestProduct',

  state: {
    visibleModal: false,
    productsRequest: [],
    productsSerialRequest: [],
    productSelected: {},
    activeItem: null,
    activeEdit: false,
    productCopySelected: {},
    fileList: [],
  },

  effects: {
    *searchProducts({ payload }, { call, put, select }) {
      const query = Object.assign({});
      _.forEach(payload, (val, key) => {
        if (val) {
          _.set(query, key, val);
        }
      });

      const response = yield call(productAPI.search, query);

      if (response.success) {
        const { result } = response;
        result.items.map(
          el => (el.key = `${el.id}-${el.serialNo ? el.serialNo : '0'}-${Math.random()}`)
        );
        yield put({
          type: 'saveProductsRequest',
          payload: {
            ...result,
          },
        });

        const item = yield select(state => state.warrantyRequest.item);
        const { currentStepCode } = item;
        let productSelected = {};
        if (currentStepCode === WORK_ORDER_STEP.six) {
          productSelected = !_.isEmpty(result.items) ? result.items[0] : {};
        }

        yield put({
          type: 'saveProductSelected',
          payload: {
            data: productSelected,
          },
        });

        yield put({
          type: 'saveActiveEdit',
          payload: {
            activeEdit: false,
          },
        });
      }
    },

    *searchProductSerials({ payload }, { call, put, select }) {
      const query = Object.assign({});
      _.forEach(payload, (val, key) => {
        if (val) {
          _.set(query, key, val);
        }
      });

      yield put({
        type: 'saveProductsSerialRequest',
        payload: {},
      });

      const response = yield call(productAPI.searchSerial, query);

      if (response.success) {
        const { result } = response;
        result.items.map(
          el => (el.key = `${el.id}-${el.serialNo ? el.serialNo : '0'}-${Math.random()}`)
        );
        yield put({
          type: 'saveProductsSerialRequest',
          payload: {
            ...result,
          },
        });
        yield put({
          type: 'saveActiveEdit',
          payload: {
            activeEdit: false,
          },
        });
      }
    },



    *changeProducts({ payload }, { call, put }) {
      const { lines, visibleModal, openEditProduct } = payload;
      yield put({
        type: 'warrantyRequest/saveLines',
        payload: lines,
      });

      yield put({
        type: 'saveProducts',
        payload: {
          visibleModal,
          openEditProduct,
        },
      });
    },

    *editProduct({ payload }, { put }) {
      const { activeId, lines, step } = payload;
      const item = lines.filter(el => el.rowKey === activeId);
      const activeItem = !_.isEmpty(item) ? item[0] : {};
      if (step === WORK_ORDER_STEP.six) {
        yield put({
          type: 'changeVisibleModal',
          payload: {
            status: true,
          },
        });
      }

      if (!_.isEmpty(activeItem)) {
        activeItem.id = activeItem.productId;
        activeItem.key = activeItem.productId;
        if (activeItem.product) {
          activeItem.sku = activeItem.product.sku;
          activeItem.name = activeItem.product.name;
        }

        yield put({
          type: 'saveProductsRequest',
          payload: {
            items: [activeItem],
            totalCount: 1,
          },
        });

        yield put({
          type: 'saveProductActive',
          payload: {
            activeItem,
          },
        });

        yield put({
          type: 'saveActiveEdit',
          payload: {
            activeEdit: true,
          },
        });

        if (step === WORK_ORDER_STEP.six) {
          yield put({
            type: 'saveProductSelected',
            payload: {
              data: activeItem,
            },
          });
        }
      }
    },

    *updateLine({ payload }, { call, put }) {
      const response = yield call(warrantyRequestAPI.updateLine, payload);
      if (response.success) {
        yield notification.success({
          message: 'Cập nhật sản phẩm',
          description: 'Đã cập nhật sản phẩm cho ca bảo hành thành công',
        });

        yield put({
          type: 'saveProductActive',
          payload: {
            activeItem: {},
          },
        });
      }

      return response;
    },

    *copyProducts({ payload }, { put, select }) {
      const item = yield select(state => state.warrantyRequest.item);
      const productCopySelected = yield select(
        state => state.warrantyRequestProduct.productCopySelected
      );

      if (productCopySelected.serialNo || productCopySelected.hiddenSerialNo) {
        notification.error({
          message: 'Chú ý',
          description: 'Sản phẩm này không thể sao chép. Vì sản phẩm đã được xác minh',
        });
        return;
      }

      /**
       * reset selected product to copy
       */
      yield put({
        type: 'saveProductCopySelected',
        payload: {},
      });

      const { lines } = item;
      const { numberCopy } = payload;
      for (let i = 0; i < numberCopy; i++) {
        const data = _.clone(productCopySelected, true);
        data.lineNumber = 0;
        data.rowKey = Math.random();
        data.qty = 1;
        lines.push(data);
      }

      yield message.success('Đã sao chép sản phẩm thành công');

      yield put({
        type: 'changeProducts',
        payload: {
          lines,
        },
      });
    },
    *uploadImage({ payload }, { call }) {
      const { id, lineNumber, body } = payload;
      const formData = new FormData();
      Object.entries(body).map(([key, value]) => {
        if (key === 'images') {
          return value.forEach(file => {
            if (file.originFileObj) {
              formData.append('files', file.originFileObj);
            }
          });
        }
        return formData.append(key, value);
      });
      const { result } = yield call(warrantyRequestAPI.uploadImage, {
        id,
        lineNumber,
        body: formData,
      });
      return result;
    },
    *getProductImages({ payload }, { call, put }) {
      const response = yield call(warrantyRequestAPI.getProductImages, payload);
      return _.get(response, 'result');
    },
    *getAllCheckoutImages({ payload }, { call, put }) {
      const response = yield call(warrantyRequestAPI.getAllCheckoutImages, payload);
      return _.get(response, 'result');
    },
    *deleteProductImage({ payload }, { call, put }) {
      const response = yield call(warrantyRequestAPI.deleteProductImage, payload);
      return _.get(response, 'success');
    },
    *reset({ payload }, { put }) {
      yield put({
        type: 'saveProductActive',
        payload: {
          activeItem: null,
        },
      });
    },
  },

  reducers: {
    changeVisibleModal(state, { payload }) {
      let { productSelected, productsRequest } = state;
      if (payload.status) {
        productsRequest = [];
        productSelected = {};
      }
      return {
        ...state,
        visibleModal: payload.status,
        productSelected,
        productsRequest,
      };
    },

    saveProductsRequest(state, { payload }) {
      return {
        ...state,
        productsRequest: payload,
      };
    },
    saveProductsSerialRequest(state, { payload }) {
      return {
        ...state,
        productsSerialRequest: payload,
      };
    },

    

    saveProducts(state, { payload }) {
      const { visibleModal, openEditProduct } = payload;
      return {
        ...state,
        visibleModal,
        productsRequest: [],
        openEditProduct: openEditProduct || false,
      };
    },

    saveProductSelected(state, { payload }) {
      return {
        ...state,
        productSelected: payload.data,
      };
    },

    saveProductActive(state, { payload }) {
      const { activeItem, activeEdit } = payload;
      return {
        ...state,
        activeItem,
        activeEdit: activeEdit || false,
      };
    },

    /**
     * Reset function auto edit product when chosen a product on popup add product in tab ca bao hanh
     */
    saveActiveEdit(state, { payload }) {
      const { activeEdit } = payload;
      return {
        ...state,
        activeEdit,
      };
    },

    saveProductCopySelected(state, { payload }) {
      return {
        ...state,
        productCopySelected: payload,
      };
    },
    saveImage(state, { payload }) {
      return {
        ...state,
        fileList: payload,
      };
    },
  },
};

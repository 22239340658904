import { notification } from 'antd';
import _ from 'lodash';
import { handleParamInventory, updateInventoryAccLines } from '@/pages/OrderAccessories/utils';
import * as transferOrdersAPI from '../services/transferOrders';
import * as returnOrderAPI from '../services/returnOrder';

export default {
  namespace: 'transferOrders',

  state: {
    data: [],
    item: {},
    conditionType: [],
    typeAccessoriesOrders: [],
    wareHouse: [],
    statusList: [],
    visibleModalView: false,
  },

  effects: {
    *getAll({ payload }, { call, put }) {
      const response = yield call(transferOrdersAPI.getAll, payload);
      if (response.success) {
        yield put({
          type: 'saveAccessories',
          payload: {
            items: response.result,
          },
        });
      }
    },

    *get({ payload }, { call, put }) {
      const response = yield call(transferOrdersAPI.get, payload.id);
      if (response.success) {
        const { result } = response;
        /**
         * Save item
         */
        yield put({
          type: 'saveItem',
          payload: {
            item: result,
          },
        });

        /**
         * Save shipping info when get detail
         */
        const { shippingInfo } = result;
        yield put({
          type: 'payment/saveShippingInfo',
          payload: {
            data: shippingInfo,
          },
        });

        return result;
      }

      return null;
    },

    *create({ payload }, { call, put }) {
      const response = yield call(transferOrdersAPI.create, payload);
      if (response.success) {
        yield put({
          type: 'saveVisibleModalView',
          payload: {
            status: false,
          },
        });

        yield put({
          type: 'saveVisibleSAModalView',
          payload: {
            status: false,
          },
        });

        yield notification.success({
          message: 'Mượn linh kiện',
          description: 'Đã tạo phiếu mượn linh kiện thành công',
        });
      }
      return Promise.resolve(response.success);
    },

    *finish({ payload }, { put, call }) {
      const response = yield call(transferOrdersAPI.finish, payload);
      if (response.success) {
        yield put({
          type: 'saveItem',
          payload: {
            item: response.result,
          },
        });

        yield notification.success({
          message: 'Thành công',
          description: 'Đã hoàn tất phiếu xuất hàng',
        });
      }
    },

    *start({ payload }, { put, call }) {
      const response = yield call(transferOrdersAPI.start, payload);
      if (response.success) {
        yield put({
          type: 'saveItem',
          payload: {
            item: response.result,
          },
        });

        yield notification.success({
          message: 'Thành công',
          description: 'Đang bắt đầu xử lý phiếu xuất hàng',
        });
      }
    },

    *reject({ payload }, { put, call }) {
      const response = yield call(orderAccessoriesAPI.reject, payload.id);
      if (response.success) {
        yield put({
          type: 'saveItem',
          payload: {
            item: response.result,
          },
        });

        yield notification.success({
          message: 'Từ chối',
          description: 'Từ chối thành công',
        });
      }
    },

    *cancel({ payload }, { put, call }) {
      const response = yield call(orderAccessoriesAPI.cancel, payload.id, payload.note);
      if (response.success) {
        yield put({
          type: 'saveItem',
          payload: {
            item: response.result,
          },
        });

        yield notification.success({
          message: 'Hủy phiếu linh kiện',
          description: 'Hủy phiếu linh kiện thành công',
        });
      }
    },

    *changeVisibleModalView({ payload }, { put }) {
      yield put({
        type: 'saveVisibleModalView',
        payload,
      });
    },

    *changeVisibleSAModalView({ payload }, { put }) {
      yield put({
        type: 'saveVisibleSAModalView',
        payload,
      });
    },

    *checkInventory({ payload }, { call }) {
      try {
        const response = yield call(orderAccessoriesAPI.checkInventory, payload);
        if (response.success) {
          return response.result.items;
        }

        return null;
      } catch (error) {
        notification.error({
          message: 'Lỗi!',
          description: 'Không thể lấy thông tin tồn kho',
        });
        return null;
      }
    },

    *getReturnable({ payload }, { call, put }) {
      const response = yield call(returnOrderAPI.getReturnable, payload);
      if (response.success) {
        yield put({
          type: 'saveInventoryLines',
          payload: response.result.items,
        });
      }
    },

    *getReturnableSerials({ payload }, { call, put }) {
      const response = yield call(returnOrderAPI.getReturnableSerials, payload);
      if (response.success) {
        return response.result.items;
      }

      return [];
    },

    /**
     * Get Inventory for each accessoriesOrderLines
     */
    *handleInventory({ payload, forceUpdatePrice }, { put }) {
      const {
        warehouseCode,
        conditionTypeCode,
        accessoriesOrderLines,
        receiverId,
        receiverType,
      } = payload;

      const inventories = yield put({
        type: 'checkInventory',
        payload: {
          warehouseCode,
          body: {
            items: handleParamInventory(
              accessoriesOrderLines,
              conditionTypeCode,
            ),
            objectId: receiverId,
            objectType: receiverType
          },
        },
      });
      const newInventories = yield inventories;
      payload.accessoriesOrderLines = updateInventoryAccLines(
        accessoriesOrderLines,
        newInventories,
        forceUpdatePrice
      );

      /**
       * Save item
       */
      yield put({
        type: 'saveItem',
        payload: {
          item: payload,
        },
      });
    },
  },

  reducers: {
    saveSearchData(state, { payload }) {
      return {
        ...state,
        searchData: {
          ...payload,
        },
      };
    },
    saveAccessories(state, { payload }) {
      return {
        ...state,
        data: payload.items,
      };
    },
    saveAccessoriesConditionType(state, { payload }) {
      return {
        ...state,
        conditionType: payload.items,
      };
    },
    saveTypeAccessoriesOrder(state, { payload }) {
      return {
        ...state,
        typeAccessoriesOrders: payload.items,
      };
    },
    saveStatusAccessoriesOrder(state, { payload }) {
      return {
        ...state,
        statusList: payload.items,
      };
    },
    saveWareHouse(state, { payload }) {
      return {
        ...state,
        wareHouse: payload.items,
      };
    },
    saveVisibleModalView(state, { payload }) {
      return {
        ...state,
        visibleModal: payload.status,
      };
    },
    saveVisibleSAModalView(state, { payload }) {
      return {
        ...state,
        visibleSAModal: payload.status,
      };
    },
    saveItem(state, { payload }) {
      const { item } = payload;
      return {
        ...state,
        item,
      };
    },
    saveInventoryLines(state, { payload }) {
      return {
        ...state,
        inventoryLines: payload,
      };
    },
  },
};

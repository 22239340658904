import { apiGet, apiPost, apiPut, apiDelete } from '@/utils/request';

export function getAll(query) {
  return apiGet(`${BASE_API_URL}/services/app/Role/GetAll`, query);
}

export function getForSelect(query) {
  return apiGet(`${BASE_API_URL}/services/app/Role/GetForSelect`, query);
}

export function get(id) {
  return apiGet(`${BASE_API_URL}/services/app/Role/Get`, { id });
}

export function create(body) {
  return apiPost(`${BASE_API_URL}/services/app/Role/Create`, { body });
}

export function update(body) {
  return apiPut(`${BASE_API_URL}/services/app/Role/Update`, { body });
}

export function deleteRole(id) {
  return apiDelete(`${BASE_API_URL}/services/app/Role/Delete?id=${id}`);
}

export function getForEdit(query) {
  return apiGet(`${BASE_API_URL}/services/app/Role/GetRoleForEdit`, query);
}
export function getRole() {
  return apiGet(`${BASE_URL}/AbpUserConfiguration/GetAll`);
}

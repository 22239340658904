import { apiGet, apiPost, apiPut, apiDownloadFile, apiDelete } from '@/utils/request';

export function getAll(query) {
  return apiGet(`${BASE_API_URL}/services/app/QuotaCeiling/GetAllData`, query);
}

export function get(id) {
  return apiGet(`${BASE_API_URL}/services/app/Product/GetDetail`, { id: id });
}

export function create(body) {
  return apiPost(`${BASE_API_URL}/services/app/QuotaCeiling/Create`, { body });
}

export function update(body) {
  return apiPut(`${BASE_API_URL}/services/app/QuotaCeiling/Update`, { body });
}
export function deleteConfig(body) {
  const { objectId, objectType } = body;
  return apiDelete(
    `${BASE_API_URL}/services/app/QuotaCeiling/deleteConfig?objectId=${objectId}&objectType=${objectType}`
  );
}

export function search(query) {
  return apiGet(`${BASE_API_URL}/services/app/Product/Search`, query);
}
export function getAmountConfitLimtitByUserId(query) {
  return apiGet(`${BASE_API_URL}/services/app/QuotaCeiling/getAmountUser`, query);
}

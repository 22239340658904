import React, { PureComponent } from 'react';
import { DownOutlined, UndoOutlined, UpOutlined } from '@ant-design/icons';
import { Button, Col } from 'antd';
import { defaultDatePicker } from "@/utils/utils";
import styles from '../../pages/List/TableList.less';

class MasterFilter extends PureComponent {
  state = {
    expandForm: false,
  };

  toggleForm = () => {
    const { expandForm } = this.state;
    this.setState({
      expandForm: !expandForm,
    });
  };

  handleCollapse = () => {
    const { expandForm } = this.state;
    const collapse = [];
    const title = !expandForm ? 'Mở rộng' : 'Thu gọn';
    collapse.push(title);
    const icon = !expandForm ? <DownOutlined key="down" /> : <UpOutlined key="up" />;
    collapse.push(icon);
    return collapse;
  };

  reset = () => {
    const { form } = this.props;
    form.resetFields();
    form.setFieldsValue({
      CreatorUserId: '',
      date: defaultDatePicker(),
      UserId: '',
    });
  };

  renderCollapse(col = 4, collapse = false, reset = true) {
    return (
      <>
        {collapse ? (
          <Col md={col} sm={24} style={{ marginLeft: 10 }}>
            <span className={styles.submitButtons}>
              <Button type="dashed" shape="round" onClick={this.toggleForm}>
                {this.handleCollapse()}
              </Button>
            </span>
          </Col>
        ) : null}

        {reset ? (
          <Col md={col} sm={24} style={{ marginLeft: 10 }}>
            <span className={styles.submitButtons}>
              <Button type="default" shape="round" onClick={this.reset}>
                <UndoOutlined />
                Làm mới
              </Button>
            </span>
          </Col>
        ) : null}
      </>
    );
  }
}

export default MasterFilter;

import { apiGet, apiPost, apiPut, apiDownloadFile } from '@/utils/request';

export function getSessions(query) {
  return apiGet(`${BASE_API_URL}/services/app/cashflow/sessions`, query);
}

export function get(id, body) {
  return apiGet(`${BASE_API_URL}/services/app/cashflow/sessions/${id}`, body);
}
export function pushCashflowsSession(id) {
  return apiGet(`${BASE_API_URL}/services/app/cashflow/sessions/${id}/push-cashflows-session`);
}

export function create(body) {
  return apiPost(`${BASE_API_URL}/services/app/cashflow/sessions`, { body });
}
export function createCashFlow(body) {
  return apiPost(`${BASE_API_URL}/services/app/cashflow/create`, { body });
}

export function update({ id, body }) {
  return apiPut(`${BASE_API_URL}/services/app/cashflow/sessions/${id}`, { body });
}

export function approve({ id, status }) {
  return apiPut(`${BASE_API_URL}/services/app/cashflow/sessions/${id}/approve?status=${status}`);
}
export function getType() {
  return apiGet(`${BASE_API_URL}/services/app/CashFlow/types`);
}
export function getCashFlow(query) {
  return apiGet(`${BASE_API_URL}/services/app/CashFlow/get`, query);
}

export function getDebitCredits(query) {
  return apiGet(`${BASE_API_URL}/services/app/cashflow/debitcredits`, query);
}

export function getDebitCredit({ id }) {
  return apiGet(`${BASE_API_URL}/services/app/CashFlow/debitcredit/${id}`);
}

export function deleteDebitCredit({ id }) {
  return apiPut(`${BASE_API_URL}/services/app/CashFlow/debitcredit/${id}/delete`);
}

export function updateDebitCredit({ id, body }) {
  return apiPut(`${BASE_API_URL}/services/app/cashflow/debitcredit/${id}`, { body });
}

export function getReportDebit(query) {
  return apiGet(`${BASE_API_URL}/services/app/CashFlow/reportDebit`, query);
}

export function importCashFlow(body) {
  return apiPost(`${BASE_API_URL}/services/app/cashflow/ImportCashFlow`, { body });
}

export function exportCashFlow(body) {
  return apiDownloadFile(`${BASE_API_URL}/services/app/cashflow/exportTemplateCashFlow`, body);
}

export function updateDebitNote(params) {
  return apiPut(`${BASE_API_URL}/services/app/cashflow/${params.id}/note`, { body: params });
}

export function approveAccessories(body) {
  return apiPut(`${BASE_API_URL}/services/app/cashflow/approve`, { body });
}

export function exportDebtManagement(body) {
  return apiDownloadFile(`${BASE_API_URL}/services/app/exportexcel/accessories-debt`, body);
}

export function exportDebtManagementDetail(body) {
  return apiDownloadFile(`${BASE_API_URL}/services/app/exportexcel/accessories-debt-detail`, body);
}

import { apiGet, apiPost, apiPut } from '@/utils/request';

export function getAll(query) {
  return apiGet(`${BASE_API_URL}/services/app/Customer/GetAll`, query);
}

export function get(id) {
  return apiGet(`${BASE_API_URL}/services/app/Customer/GetDetail`, { id: id });
}

export function create(body) {
  return apiPost(`${BASE_API_URL}/services/app/Customer/Create`, { body });
}

export function update(body) {
  return apiPut(`${BASE_API_URL}/services/app/Customer/Update`, { body });
}

export function getHistoriesCustomers(id) {
  return apiGet(`${BASE_API_URL}/services/app/Customer/${id}/histories`);
}

export function getForSelect(query) {
  return apiGet(`${BASE_API_URL}/services/app/Customer/GetForSelect`, query);
}

export function getRelationshipForSelect(query) {
  return apiGet(`${BASE_API_URL}/services/app/Customer/GetRelationshipForSelect`, query);
}

/**
 * Get Customer type
 * @returns {*}
 */
export function getCustomerType(query) {
  return apiGet(`${BASE_API_URL}/services/app/Ref/Customer/Type`, query);
}

import { apiGet, apiPost, apiPut } from '@/utils/request';

export function getAll(query) {
  return apiGet(`${BASE_API_URL}/services/app/ReturnOrder/GetAll`, query);
}

export function get(id) {
  return apiGet(`${BASE_API_URL}/services/app/ReturnOrder/GetDetail`, {'id': id});
}

export function create(body) {
  return apiPost(`${BASE_API_URL}/services/app/ReturnOrder/Create`, { body });
}

export function update(body) {
  return apiPut(`${BASE_API_URL}/services/app/ReturnOrder/Update`, { body });
}

export function confirm({ id }) {
  return apiPut(`${BASE_API_URL}/services/app/ReturnOrder/${id}/confirm`);
}

export function cancel({ id }) {
  return apiPut(`${BASE_API_URL}/services/app/ReturnOrder/${id}/cancel`);
}

export function reject({ id }) {
  return apiPut(`${BASE_API_URL}/services/app/ReturnOrder/${id}/reject`);
}

export function received({ id }) {
  return apiPut(`${BASE_API_URL}/services/app/ReturnOrder/${id}/received`);
}

export function getReturnable({type, userId, query}) {
  return apiGet(`${BASE_API_URL}/services/app/${type}/${userId}/inventory/returnable`, query);
}

export function getInventoryProducts({type, userId, query}) {
  return apiGet(`${BASE_API_URL}/services/app/${type}/${userId}/inventory`, query);
}

export function getReturnableSerials({type, userId, query}) {
  return apiGet(`${BASE_API_URL}/services/app/${type}/${userId}/inventory/returnable-serials`, query);
}

export function getConditionType() {
  return apiGet(`${BASE_API_URL}/services/app/ReturnOrder/GetConditionTypeForSelect`);
}


import React, { PureComponent, Fragment } from 'react';
import { Table, Alert } from 'antd';
import PropTypes from 'prop-types';
import styles from './index.less';

function initTotalList(columns) {
  const totalList = [];
  columns.forEach(column => {
    if (column.needTotal) {
      totalList.push({ ...column, total: 0 });
    }
  });
  return totalList;
}

class StandardTable extends PureComponent {
  static propTypes = {
    rowSelectionStatus: PropTypes.bool,
    showTotalRow: PropTypes.bool,
    expandedRowRender: PropTypes.func,
    paginationShow: PropTypes.bool,
    rowClassName: PropTypes.string,
  };

  static defaultProps = {
    rowSelectionStatus: true,
    showTotalRow: true,
    expandedRowRender: null,
    paginationShow: true,
    rowClassName: null,
  };

  constructor(props) {
    super(props);
    const { columns } = props;
    const needTotalList = initTotalList(columns);

    this.state = {
      selectedRowKeys: [],
      needTotalList,
    };
  }

  static getDerivedStateFromProps(nextProps) {
    // clean state
    if (nextProps.selectedRows.length === 0) {
      const needTotalList = initTotalList(nextProps.columns);
      return {
        selectedRowKeys: [],
        needTotalList,
      };
    }
    return null;
  }

  handleRowSelectChange = (selectedRowKeys, selectedRows) => {
    let { needTotalList } = this.state;
    needTotalList = needTotalList.map(item => ({
      ...item,
      total: selectedRows.reduce((sum, val) => sum + parseFloat(val[item.dataIndex], 10), 0),
    }));
    const { onSelectRow } = this.props;
    if (onSelectRow) {
      onSelectRow(selectedRows);
    }

    this.setState({ selectedRowKeys, needTotalList });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(pagination, filters, sorter);
    }
  };

  cleanSelectedKeys = () => {
    this.handleRowSelectChange([], []);
  };

  render() {
    const { selectedRowKeys, needTotalList } = this.state;
    const {
      data = {},
      rowKey,
      bordered,
      size,
      rowSelectionStatus,
      showTotalRow,
      expandedRowRender,
      paginationShow,
      rowClassName,
      ...rest
    } = this.props;
    const { list = [], pagination } = data;
    const { total } = pagination;

    const paginationProps = paginationShow
      ? {
          showSizeChanger: true,
          showQuickJumper: true,
          ...pagination,
        }
      : false;

    const rowSelection = rowSelectionStatus
      ? {
          selectedRowKeys,
          onChange: this.handleRowSelectChange,
          getCheckboxProps: record => ({
            disabled: record.disabled,
          }),
        }
      : null;

    return (
      <div className={styles.standardTable}>
        <div className={styles.tableAlert}>
          <Alert
            message={
              <Fragment>
                Chosen <a style={{ fontWeight: 600 }}>{selectedRowKeys.length}</a> item&nbsp;&nbsp;
                {needTotalList.map(item => (
                  <span style={{ marginLeft: 8 }} key={item.dataIndex}>
                    {item.title}
                    total&nbsp;
                    <span style={{ fontWeight: 600 }}>
                      {item.render ? item.render(item.total) : item.total}
                    </span>
                  </span>
                ))}
                <a onClick={this.cleanSelectedKeys} style={{ marginLeft: 24 }}>
                  Empty
                </a>
              </Fragment>
            }
            type="info"
            showIcon
          />
        </div>
        {showTotalRow ? (
          <div className={styles.tableAlertInfo}>
            <Alert
              message={<Fragment>Tổng cộng: {total || 0} kết quả&nbsp;&nbsp;</Fragment>}
              type="info"
              showIcon
            />
          </div>
        ) : null}

        <Table
          rowKey={rowKey || 'key'}
          rowSelection={rowSelection}
          dataSource={list}
          pagination={paginationProps}
          onChange={this.handleTableChange}
          bordered={bordered}
          size={size || 'default'}
          expandedRowRender={expandedRowRender}
          rowClassName={rowClassName}
          {...rest}
        />
      </div>
    );
  }
}

export default StandardTable;

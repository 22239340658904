import _ from 'lodash';
import { notification } from 'antd';
import * as discountRateAPI from '@/services/discountRate';

export default {
  namespace: 'discountRate',

  state: {
    visibleModal: false,
    modalType: '',
    list: [],
    item: {},
    discountRatesSearch: [],
    categories: [],
    brands: [],
    units: [],
    storageTypes: [],
    filterCurrent:{}
  },

  effects: {
    *fetch({ payload }, { call, put }) {
      yield put({
        type: 'saveFilterCurrent',
        payload,
      });
      const query = Object.assign({});
      _.forEach(payload, (val, key) => {
        if (val) {
          _.set(query, key, val);
        }
      });

      const response = yield call(discountRateAPI.getAll, query);
      if (response.success) {
        yield put({
          type: 'saveList',
          payload: {
            ...response.result,
          },
        });
      }
    },

    *get({ payload }, { call, put }) {
      const response = yield call(discountRateAPI.get, payload.id);
      if (response.success) {
        yield put({
          type: 'save',
          payload: {
            ...response,
            visibleModal: true,
          },
        });
      }
    },

    *create({ payload }, { call, put,select }) {
      const response = yield call(discountRateAPI.create, payload);
      if (response.success) {
        yield put({
          type: 'save',
          payload: {
            ...response,
            visibleModal: false,
          },
        });

        yield notification.success({
          message: 'Thêm sản phẩm',
          description: 'Đã thêm sản phẩm mới thành công',
        });

        const filterCurrent = yield select(
          state => state.discountRate.filterCurrent
        );

        yield put({
          type: 'fetch',
          payload: filterCurrent,
        });
      }
    },

    *update({ payload }, { call, put,select }) {
      const response = yield call(discountRateAPI.update, payload);
      if (response.success) {
        yield put({
          type: 'save',
          payload: {
            ...response,
            visibleModal: false,
          },
        });

        yield notification.success({
          message: 'Cập nhật sản phẩm',
          description: 'Đã cập nhật thông tin sản phẩm thành công',
        });

        const filterCurrent = yield select(
          state => state.discountRate.filterCurrent
        );

        yield put({
          type: 'fetch',
          payload: filterCurrent,
        });
      }
    },

    *changeVisibleModal({ payload }, { call, put }) {
      yield put({
        type: 'saveVisibleModal',
        payload: {
          status: payload.status,
          modalType: payload.modalType,
        },
      });
    },

    *searchDiscountRates({ payload }, { call, put }) {
      const query = Object.assign({});
      _.forEach(payload, (val, key) => {
        if (val) {
          _.set(query, key, val);
        }
      });

      const response = yield call(discountRateAPI.search, query);

      if (response.success) {
        response.result.items.map(
          el => (el.key = `${el.id}-${el.serialNo ? el.serialNo : '0'}-${Math.random()}`)
        );
        yield put({
          type: 'saveDiscountRatesSearch',
          payload: {
            ...response.result,
          },
        });
      }
    },

    *getCategories({ payload }, { call, put }) {
      const response = yield call(discountRateAPI.getCategories, payload);
      if (response.success) {
        yield put({
          type: 'saveCategories',
          payload: {
            items: response.result.items,
          },
        });
      }
    },

    *getBrands({ payload }, { call, put }) {
      const response = yield call(discountRateAPI.getBrands, payload);
      if (response.success) {
        yield put({
          type: 'saveBrands',
          payload: {
            items: response.result.items,
          },
        });
      }
    },

    *getUnits({ payload }, { call, put }) {
      const response = yield call(discountRateAPI.getUnits, payload);
      if (response.success) {
        yield put({
          type: 'saveUnits',
          payload: {
            items: response.result.items,
          },
        });
      }
    },

    *getStorageTypes({ payload }, { select, put }) {
      const businessData = yield select(state => state.business.businessData);
      const data = _.get(businessData, 'DiscountRate.StorageType');
      if (data) {
        yield put({
          type: 'saveStorageTypes',
          payload: {
            items: data,
          },
        });
      }
    },
    *exportDiscountRateImport({ payload }, { call }) {
      return yield call(discountRateAPI.exportDiscountRateImport,payload);
    },
    *importDiscountRatePrice({ payload }, { call }) {
      return yield call(discountRateAPI.importDiscountRatePrice, payload);
    },
  },

  reducers: {
    saveVisibleModal(state, { payload }) {
      return {
        ...state,
        visibleModal: payload.status,
        modalType: payload.modalType,
        extensions: [],
      };
    },

    saveList(state, { payload }) {
      return {
        ...state,
        list: payload,
      };
    },
    save(state, { payload }) {
      return {
        ...state,
        item: payload.result,
        visibleModal: payload.visibleModal,
      };
    },
    saveDiscountRatesSearch(state, { payload }) {
      return {
        ...state,
        discountRatesSearch: payload,
      };
    },
    saveCategories(state, { payload }) {
      return {
        ...state,
        categories: payload.items,
      };
    },
    saveBrands(state, { payload }) {
      return {
        ...state,
        brands: payload.items,
      };
    },
    saveUnits(state, { payload }) {
      return {
        ...state,
        units: payload.items,
      };
    },
    saveStorageTypes(state, { payload }) {
      return {
        ...state,
        storageTypes: payload.items,
      };
    },
    saveFilterCurrent(state, { payload }) {
      return {
        ...state,
        filterCurrent: payload,
      };
    },
  },
};

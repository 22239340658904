export default {
  'app.exception.back': 'Back to home',
  'app.exception.[Login failed]': '{details}',
  'app.exception.[Role name is already taken]': 'Tên nhóm quyền đã tồn tại',
  'app.exception.Employee_Code_Is_Existing': 'Mã nhân viên {details} đã tồn tại',
  'app.exception.': 'Back to home',
  'app.exception.description.403': 'Xin lỗi, bạn không có quyền truy cập',
  'app.exception.description.404': 'Xin lỗi, không thể thực hiện được',
  'app.exception.description.500': 'Sorry, the server is reporting an error',
  'app.exception.Customer_Phone_Is_Existing': 'Số điện thoại {details} đã tồn tại',
  'app.exception.UserExtension_Is_Mapping_To_Another_User':
    'Số nội bộ đã được dùng cho nhân viên khác',
  'app.exception.WorkOrder_Step_Was_Wrong': 'Bước thực hiện không hợp lý',
  'app.exception.User_Is_ReChecking_In_Another_Case': 'Bạn đang chăn sóc lại một ca bảo hành khác',
  'app.exception.User_Is_Calling_To_Confirm_Another_Case':
    'Bạn đang gọi để xác nhận một ca bảo hành khác',
  'app.exception.Customer_TaxNumber_Is_Existing': 'Mã số thuế này đã tồn tại',
  'app.exception.Customer_Is_Not_Allow_To_UpdateCode':
    'Bạn không có quyền thay đổi mã khách hàng này',
  'app.exception.Customer_Code_Is_Existing': 'Mã Khách hàng này đã tồn tại',
  'app.exception.Customer_Type_Is_Invalid': 'Loại người dùng khộng hợp lệ',
  'app.exception.Customer_Is_Required': 'Vui lòng nhập khách hàng',
  'app.exception.Keyword_Is_Required': 'Vui lòng nhập từ khóa',
  'app.exception.Employee_PhoneNumber_Is_Existing': 'Số điện thoại này đã tồn tại',
  'app.exception.Employee_Email_Is_Existing': 'Email này đã tồn tại',
  'app.exception.WarrantyRequest_Step_Was_Wrong': 'Bước thực hiện không hợp lệ',
  'app.exception.WarrantyRequest_Step_Is_Working_By_Other_User':
    'Nhân viên khác đang xử lý, bạn không thể thực hiện',
  'app.exception.WarrantyRequest_WorkOrder_Status_Was_Wrong': 'Bước thực hiện không hợp lý',
  'app.exception.Accessories_Is_Not_Enough': 'Số lượng linh kiện không đủ',
  'app.exception.Invalid_Input': 'Nội dung không hợp lệ. Vui lòng thử lại',
  'app.exception.SerialNo_Is_Existing': 'Serial hoặc mã cào đã tồn tại hoặc khác model vs ca khác',
  'app.exception.WarrantyRequest_Is_Invalid': 'Ca bảo hành không hợp lệ',
  'app.exception.HiddenSerialNo_Is_Existing': 'Serial hoặc mã cào đã tồn tại hoặc khác model vs ca khác',
  'app.exception.SerialNo_Or_Hidden_SerialNo_NotFound': 'Serial hoặc mã cào không tồn tại hoặc khác model vs ca khác',
  'app.exception.HiddenSerialNo_Is_Used_Or_NotExisting': 'Mã cào đã tồn tại hoặc khác model vs ca khác',
  'app.exception.Warranty_Req_Is_Invalid': 'Thông tin ca BH không hợp lệ',
  'app.exception.Accessorie_Order_Of_Warranty_Request_Is_Wrong_Employee_Or_Station_Assigeed':
    'Mã Ca không thuộc đối tượng THBH đã chọn',
  'app.exception.Accessories_Order_Is_Not_Allowed_Updating':
    'Đặt linh kiện không được phép sửa đổi',
  'app.exception.Accessories_Order_ShippingInfo_Is_Required': 'Địa chỉ giao hàng bắt buộc nhập',
  'app.exception.WarrantyRequest_Was_Locked': 'Ca đã đóng',
  'app.exception.LockingQty_Must_LessThanOrEqual_Total': 'Số lượng lấy phải nhỏ hơn đề xuất',
  'app.exception.Serial_Does_Not_Existing': 'SerialNo không tồn tại',
  'app.exception.New_Orders_Type_Is_Is_Valid': 'Phiếu đặt linh kiện không cùng loại với nhau',
  'app.exception.AccessoriesOrder_Is_Not_Allow_To_Update':
    'Bạn không có quyền sửa phiếu linh kiện này',
  'app.exception.AccessoriesOrder_Is_UnUpdateable': 'Phiếu linh kiện không được phép cập nhật',
  'app.exception.Qty_Must_Be_Int_For_Serial_Product':
    'Sản phẩm serial không được nhập số thập phân',
  'app.exception.WarrantyRequest_Lines_Is_Required':
    'Vui lòng nhập thông tin sản phẩm cần bảo hành',
  'app.exception.WarrantyRequest_Lines_StatNote_Is_Required': 'Vui lòng nhập tình trạng lỗi',
  'app.exception.Current_Password_Did_Not_Match': 'Mật khẩu hiện tại không đúng',
  'app.exception.User_Is_Not_Active': 'Tài khoản của bạn đã bị khóa',
  'app.exception.NotEnough_Inventory': 'Không đủ tồn kho',
  'app.exception.PackedQty_Must_LessThan_Or_Equal_OrderQty':
    'Số lượng thực xuất không dược lớn hơn số lượng đặt',
  'app.exception.Assignee_Employee_Title_Not_Valid':
    'Bên mua không phải là KH và không thuộc nhóm đối tượng quản lý tồn kho, xin liên hệ quản lý phần mềm để hỗ trợ',
  'app.exception.TimeSheetRules_Is_Required': 'Vui lòng cài đặt thông số giờ làm việc',
  'app.exception.TimeSheetRules_Is_Invalid': 'Thông tin giờ làm việc không hợp lệ',
  'app.exception.TimeSheetRules_Is_Overlap': 'Thông tin giờ làm việc không được trùng',
  'app.exception.EffectiveDate_Is_Overlap': 'Ngày hiệu lực không được trùng',
  'app.exception.TimeSheetRules_Is_Missing': 'Thiêu cài đặt cho giờ làm việc',
  'app.exception.WarrantyRequest_Step_Is_Started_By_Another_User':
    'Bước xử lý trong ca đã có người dùng khác thao tác, xin tải lại trang để xem cập nhật.',
  'app.exception.Assignee_Employee_Not_Valid':
    'Bên mua không phải là KH và không thuộc nhóm đối tượng quản lý tồn kho, xin liên hệ quản lý phần mềm để hỗ trợ',
  'app.exception.InvalidUserNameOrPassword': 'Email / Mật khẩu không chính xác',
  'app.exception.UserIsNotActiveAndCanNotLogin': 'Tài khoản của bạn đã bị khóa',
  'app.exception.Case_Status_Restrict_To_Update_Customer':
    'Không thể cập nhật khách hàng ở trạng thái này của ca xử lý',
  'app.exception.Accessories_Order_InBound_Is_Same_With_OutBound':
    'Bên mua và kho tỉnh không được giống nhau',
  'app.exception.ReturnOrder_InBound_Is_Same_With_OutBound':
    'Bên trả và kho tỉnh không được giống nhau',
  'app.exception.AccessoriesOrder_Exist_AOG':
    'Không thể hủy đơn hàng , vì còn PLK có trạng thái khác hủy',
  'app.exception.ReturnOrder_Status_Was_Wrong': 'Trạng thái phiếu không hợp lệ để hủy',
  'app.exception.MonthlyReview_Is_Already_Created': 'Tháng này đã tạo rồi',
  'app.exception.MonthlyReview_Has_No_CashFlows': 'Tháng này không có thu chi nào',
  'app.exception.WarrantyRequest_Line_IsNotDone': 'Ca bảo hành chưa hoàn thành cập nhật MODEL',
  'app.exception.Cashflow_TypeCode_Is_Not_Exist': 'Loại chứng từ không tồn tại trong danh mục',
  'app.exception.Object_Code_Is_Not_Exist': 'Đối tượng không tồn tại trong danh mục',
  'app.exception.No_Permission': 'Xin lỗi , bạn không có quyền thao tác chức năng này',
  'app.exception.Session_Of_This_TransDate_Was_Approved': 'Phiên làm của ngày này đã được xác nhận',
  'app.exception.CheckOut_Require_AtLeast_OneImage': 'Checkout bắt buộc phải có ít nhất một hình',
  'app.exception.Required_Serial_For_Replacement_Accessories':
    'Serial bắt buộc cho các phụ kiện thay thế',
  'app.exception.Employee_Finish_Warranty': 'Nhân viên đã hoàn thành ca bảo hành',
  'app.exception.WarrantyRequest_Can_Not_Revert_Has_Empoyee_DontCheckout':
    'Ca BH cần được kết thúc các lần thực hiện trước khi chuyển bước',
  'app.exception.WarrantyRequest_Not_Existing': 'Ca BH không tồn tại',
  'app.exception.WarrantyRequestLine_NotExist': 'Máy không tồn tại',
  'app.exception.User_NotMatch_CreateRequestLine':
    'Bạn không phải người tạo máy này nên không thể xóa',
  'app.exception.WarrantyRequestLine_Is_Used': 'Máy đã được thay linh kiện không thể xóa',
  'app.exception.WarrantyRequest_Min_OneLine': 'Không thể xóa hết máy trong ca bảo hành',
  'app.exception.WarrantyRequestLine_Is_Done': 'Máy đã được hoàn tất không thể xóa',
  'app.exception.Declarations_Info_Is_Invalid': 'Thông tin khai báo không thể trống',
  'app.exception.Serial_NotFound': 'Không tìm thấy serial trên',
  'app.exception.AddressStation_Minimum_One': 'Trạm bảo hành ít nhất có một địa chỉ',
  'app.exception.Customer_Info_Must_Be_Fully_Configurated': 'Khách hàng cần được thêm địa chỉ',
  'app.exception.Employee_Not_Found': 'Không tìm thấy nhân viên',
  'app.exception.Station_Not_Found': 'Không tìm thấy trạm bảo hành',
  'app.exception.ConditionType_Invalid': 'Tình trạng hàng hóa không hợp lệ',
  'app.exception.Line_Invalid': 'Không có thông tin chi tiết',
  'app.exception.Inv_Emp_Invalid': 'Tồn kho của đối tượng không hợp lệ',
  'app.exception.Invalid_Emp_Available_Least_Than_Qty': 'Số lượng khả dụng của đối tượng nhỏ hơn số lượng linh kiện chuyển',
  'app.exception.Invalid_Emp_Serial': 'Serial của đối tượng không tồn tại serial chuyển đổi',
  'app.exception.Amount_InValid': 'Số tiền không hợp lệ',
  'app.exception.Object_InValid': 'Đối tượng không hợp lệ',
  'app.exception.Object_Duplicate': 'Nhân viên đã tồn tại cấu hình',
  'app.exception.Employee_Has_No_Config': 'Nhân viên chưa được cấu hình',
  'app.exception.Employee_Has_Config': 'Nhân viên đã được cấu hình',
  'app.exception.Name_Discount_is_existing': 'Tên chiết khấu đã được tồn tại',
  'app.exception.Value_Discount_is_existing': 'Giá trị chiết khấu đã được tồn tại',
  'app.exception.DiscountRate_Is_Not_Existing': 'Chiết khấu không tồn tại',
  'app.exception.Value_Discount_is_NotValid': 'Giá trị chiết khấu không hợp lệ',
  'app.exception.WarrantyRequest_Not_FinishAssagin': 'Ca bảo hành chưa được phân lịch',
  'app.exception.TotaHour_Greater_72': 'Tổng số giờ vượt quá 72 giờ kể từ thời gian cuối cùng thao tác',
  'app.exception.CategoryModel_difference_categoryoldmodel': 'Ngành hàng model cập nhập khác với ngành hàng model cũ',
  'app.exception.Wr_Invalid': 'Mã Ca không tồn tại',
  'app.exception.AccessoriesOrder_Exist_WmsPackedQty': 'Phiếu linhh kiện đã xuất kho, không được hủy',
  'app.exception.Ro_Invalid': 'Mã trả hàng không tồn tại',
  'app.exception.AO_Invalid': 'Mã đơn hàng linh kiện không tồn tại',
  'app.exception.AO_Employee_Invalid': 'Đối tượng trong PLK không khớp đối tượng chuyển đổi',
  'app.exception.Ro_Employee_Invalid': 'Đối tượng trong Trả hàng không khớp đối tượng chuyển đổi',
  'app.exception.Wr_Employee_Invalid': 'Đối tượng trong CA không khớp đối tượng chuyển đổi',
  'app.exception.Serial_Invalid_Product_Current' :'Serial không thuộc sản phẩm đang chọn '
};

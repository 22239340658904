import { apiGet, apiPost, apiPut } from '@/utils/request';

export function getAll(query) {
  return apiGet(`${BASE_API_URL}/services/app/EmployeeProvince/GetAll`, query);
}

export function get(id) {
  return apiGet(`${BASE_API_URL}/services/app/EmployeeProvince/GetDetail`, { id });
}

export function create(body) {
  return apiPost(`${BASE_API_URL}/services/app/EmployeeProvince/Create`, { body });
}

export function update(body) {
  return apiPut(`${BASE_API_URL}/services/app/EmployeeProvince/Update`, { body });
}

import _ from 'lodash';
import { message, notification } from 'antd';
import * as configurationAPI from '@/services/configuration';
import { PAGINATION_PAGE_SIZE } from '@/configs/constants';

export default {
  namespace: 'configuration',

  state: {
    visibleModal: false,
    list: [],
    item: {},
  },

  effects: {
    *fetch({ payload = { CurrentPage: 1, MaxResultCount: PAGINATION_PAGE_SIZE } }, { call, put }) {
      const query = Object.assign({});
      _.forEach(payload, (val, key) => {
        if (val) {
          _.set(query, key, val);
        }
      });

      const response = yield call(configurationAPI.getAll, query);
      if (response.success) {
        yield put({
          type: 'saveList',
          payload: {
            ...response.result,
          },
        });
      }
    },

    *get({ payload }, { call, put }) {
      const response = yield call(configurationAPI.get, payload.id);
      if (response.success) {
        yield put({
          type: 'save',
          payload: {
            ...response,
            visibleModal: true,
          },
        });
      }
    },

    *delete({ payload }, { call, put }) {
      const response = yield call(configurationAPI.deleteConfig, payload.id);
      yield notification.success({
        message: 'Xóa lịch làm việc',
        description: 'Đã xóa lịch làm việc',
      });

      yield put({
        type: 'fetch',
      });
    },

    *create({ payload }, { call, put }) {
      const response = yield call(configurationAPI.create, payload);
      if (response.success) {
        yield put({
          type: 'save',
          payload: {
            visibleModal: false,
          },
        });

        yield notification.success({
          message: 'Thêm lịch làm việc',
          description: 'Đã thêm lịch làm việc mới thành công',
        });

        yield put({
          type: 'fetch',
        });
      }
    },

    *changeVisibleModal({ payload }, { call, put }) {
      yield put({
        type: 'saveVisibleModal',
        payload: {
          status: payload.status,
          modalType: payload.modalType,
        },
      });
    },
  },

  reducers: {
    saveVisibleModal(state, { payload }) {
      return {
        ...state,
        visibleModal: payload.status,
        modalType: payload.modalType,
        extensions: []
      };
    },

    saveList(state, { payload }) {
      return {
        ...state,
        list: payload,
      };
    },
    save(state, { payload }) {
      return {
        ...state,
        item: payload.result,
        visibleModal: payload.visibleModal
      };
    },
  },
};

import {notification} from 'antd';
import _ from 'lodash';
import * as warrantyRequestAPI from "../services/warrantyRequest";

export default {
  namespace: 'warrantyRequestComment',

  state: {
    list: [],
    visibleModal: false
  },

  effects: {
    *fetch({ payload }, { call, put }) {
      const query = Object.assign({});
      _.forEach(payload, (val, key) => {
        if (val) {
          _.set(query, key, val);
        }
      });

      const response = yield call(warrantyRequestAPI.getComments, query);
      if (response.success) {
        yield put({
          type: 'saveList',
          payload: {
            ...response.result,
          },
        });
      }
    },

    *create({ payload }, { call, put }) {
      const { warReqId } = payload;
      const response = yield call(warrantyRequestAPI.postComments, payload);
      if (response.success) {

        yield notification.success({
          message: 'Thêm ghi chú',
          description: 'Đã thêm ghi chú thành công',
        });

        

        yield put({
          type: 'fetch',
          payload: {
            warReqId
          }
        });
      }

      yield put({
        type: 'saveVisibleModal',
        payload: {
          status: false
        }
      });
    },

    *update({ payload }, { call, put }) {
      const { warReqId } = payload;
      const response = yield call(warrantyRequestAPI.updateComments, payload);
      if (response.success) {

        yield notification.success({
          message: 'Cập nhật ghi chú',
          description: 'Đã cập nhật ghi chú thành công',
        });

        yield put({
          type: 'fetch',
          payload: {
            warReqId
          }
        });
      }

      yield put({
        type: 'saveVisibleModal',
        payload: {
          status: false
        }
      });
    },

    *changeVisibleModal({ payload }, { call, put }) {
      yield put({
        type: 'saveVisibleModal',
        payload
      });
    },
  },

  reducers: {
    saveVisibleModal(state, { payload }) {
      return {
        ...state,
        visibleModal: payload.status
      }
    },
    saveList(state, { payload }) {
      return {
        ...state,
        list: payload,
      };
    },
  }
};

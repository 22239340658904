export default {
  'form.validate.required': 'Xin vui lòng nhập ',
  'form.get-captcha': 'Get Captcha',
  'form.captcha.second': 'sec',
  'form.optional': ' (optional) ',
  'form.submit': 'Lưu',
  'form.cancel': 'Thoát',
  'form.save': 'Save',
  'form.email.placeholder': 'Email',
  'form.confirm-password.placeholder': 'Confirm password',
  'form.phone-number.placeholder': 'Phone number',
  'form.verification-code.placeholder': 'Verification code',
  'form.title.label': 'Title',
  'form.title.placeholder': 'Give the target a name',
  'form.date.label': 'Start and end date',
  'form.date.placeholder.start': 'Start date',
  'form.date.placeholder.end': 'End date',
  'form.goal.label': 'Goal description',
  'form.goal.placeholder': 'Please enter your work goals',
  'form.standard.label': 'Metrics',
  'form.standard.placeholder': 'Please enter a metric',
  'form.client.label': 'Client',
  'form.client.label.tooltip': 'Target service object',
  'form.client.placeholder':
    'Please describe your customer service, internal customers directly @ Name / job number',
  'form.invites.label': 'Inviting critics',
  'form.invites.placeholder': 'Please direct @ Name / job number, you can invite up to 5 people',
  'form.weight.label': 'Weight',
  'form.weight.placeholder': 'Please enter weight',
  'form.public.label': 'Target disclosure',
  'form.public.label.help': 'Customers and invitees are shared by default',
  'form.public.radio.public': 'Public',
  'form.public.radio.partially-public': 'Partially public',
  'form.public.radio.private': 'Private',
  'form.publicUsers.placeholder': 'Open to',
  'form.publicUsers.option.A': 'Colleague A',
  'form.publicUsers.option.B': 'Colleague B',
  'form.publicUsers.option.C': 'Colleague C',
  'form.employeeCode.placeholder': 'Mã nhân viên',
  'form.emailAddress.placeholder': 'Email',
  'form.phoneNumber.placeholder': 'Số điện thoại',
  'form.name.placeholder': 'Họ tên',
  'form.discountrate.placeholder': 'Chiết khấu',
  'form.address.placeholder': 'Địa chỉ',
  'form.password.placeholder': 'Mật khẩu',
  'form.passwordConfirm.placeholder': 'Nhập lại mật khẩu',
  'form.province.placeholder': 'Tỉnh thành',
  'form.district.placeholder': 'Quận huyện',
  'form.ward.placeholder': 'Phường xã',
  'form.popupAddress.placeholder': 'Số nhà, đường, ấp',
  'form.Keyword.placeholder': 'Từ khóa',
  'form.passwordConfirm.notMatch': 'Mật khẩu xác nhận không khớp mật khẩu',
  'form.userInputHeader.placeholder': 'Tên dịch vụ',
  'form.customerEmail.placeholder': 'Email khách hàng',
  'form.taxNumber.placeholder': 'Mã số thuế',
  'form.code.placeholder': 'Mã khách hàng',
  'form.stationName.placeholder': 'Tên trạm',
  'form.stationCode.placeholder': 'Mã trạm',
  'form.titleCode.placeholder': 'Chức vụ',
  'form.taxCode.placeholder': 'Mã số thuế',
  'form.contact.placeholder': 'Người liên hệ',
  'form.stationGroup.placeholder': 'Nhóm trạm',
  'form.note.placeholder': 'Ghi chú',
  'form.statusProduct.placeholder': 'Tình trạng',
  'form.qty.placeholder': 'Số lượng',
  'form.accessories.placeholder': 'Linh kiện',
  'form.setProduct.placeholder': 'Đặt cho sản phẩm',
  'form.buyingPlace.placeholder': 'Nơi mua',
  'form.reasonNote.placeholder': 'Lý do cập nhật',
  'form.accessoriesSelect.placeholder': 'Vui lòng nhập linh kiện',
  'form.object.placeholder': 'Vui lòng chọn đối tượng',
  'form.relationCustomer.placeholder': 'Chọn khách hàng liên quan',
  'form.vatPercent.placeholder': 'VAT%',
  'form.prePaymentAmount.placeholder': 'Số tiền trả trước',
  'form.contactName.placeholder': 'Người liên lạc',
  'form.warrantyDuration.placeholder': 'Thời gian bảo hành',
  'form.productName.placeholder': 'Tên sản phẩm',
  'form.sku.placeholder': 'Mã sản phẩm',
  'form.accessoriesSelectModel.placeholder': 'Vui lòng nhập model',
  'form.accountHolder.placeholder': 'Chủ tài khoản thụ hưởng',
  'form.accountNumber.placeholder': 'Số tài khoản thụ hưởng',
  'form.personalIncomeTaxCode.placeholder': 'Mã số thuế thu nhập cá nhân',
  'form.identityCard.placeholder': 'Chứng minh nhân dân',
};

import { PureComponent } from 'react';
import _ from 'lodash';
import { PAGINATION_PAGE_SIZE } from '@/configs/constants';

class ListPage extends PureComponent {
  state = {
    paramsSearch: {
      CurrentPage: 1,
      MaxResultCount: PAGINATION_PAGE_SIZE,
    },
  };

  tableChange = ({ params, type, filterType }) => {
    const { dispatch } = this.props;
    const { paramsSearch } = this.state;

    paramsSearch.CurrentPage = _.has(params, 'CurrentPage') ? params.CurrentPage : 1;
    paramsSearch.MaxResultCount = _.has(params, 'MaxResultCount')
      ? params.MaxResultCount
      : PAGINATION_PAGE_SIZE;
    params = this.handleRemoveParams(params, ['CurrentPage', 'MaxResultCount']);

    const payload = {
      ...paramsSearch,
      ...params,
    };

    const promise = dispatch({
      type,
      payload,
    });
    if (filterType) {
      dispatch({
        type: filterType,
        payload,
      });
    }

    if (payload) {
      this.setState({
        paramsSearch: {
          ...paramsSearch
        },
      });
    }
    return promise;
  };

  tableSearch = ({ params, type, filterType }) => {
    return new Promise(resolve => {
      this.setState(
        {
          paramsSearch: params,
        },
        () => {
          const promise = this.tableChange({
            params: {
              CurrentPage: 1,
            },
            type,
            filterType,
          });
          if (promise instanceof Promise) {
            promise.then(() => {
              resolve();
            });
          } else {
            resolve();
          }
        }
      );
    });
  };

  handleRemoveParams = (params, els) => {
    const value = params;
    Object.keys(els).forEach(key => {
      if (_.has(params, key)) {
        delete value[key];
      }
    });

    return value;
  };
}

export default ListPage;

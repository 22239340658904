import { notification } from 'antd';
import _ from 'lodash';
import * as warrantyRequestAPI from '@/services/warrantyRequest';
import {
  handleInventory,
  getAssigneeIds,
  hanldeRowspanAccessories,
} from '@/pages/WarrantyRequest/Schedule/utils';

export default {
  namespace: 'warrantyRequestSchedule',

  state: {
    visibleEmployeesModal: false,
    employeesSelected: [],
    statusSetAccessories: false,
    statusProcess: false,
    inventory: [],
    currentRecord: { lines: [], checkin: false, addressStation: [] },
    locationSelected: {},
  },

  effects: {
    *start({ payload }, { call, put }) {
      const response = yield call(warrantyRequestAPI.planStart, payload);
      if (response.success) {
        yield put({
          type: 'warrantyRequest/changeCurrentStep',
          payload: {
            item: response.result,
          },
        });

        yield notification.success({
          message: 'Phân lịch',
          description: 'Bắt đầu tiến hành phân lịch',
        });
      }
    },

    *finish({ payload }, { call, put }) {
      const response = yield call(warrantyRequestAPI.planFinish, payload);
      if (response.success) {
        yield put({
          type: 'warrantyRequest/changeCurrentStep',
          payload: {
            item: response.result,
          },
        });

        yield notification.success({
          message: 'Phân lịch',
          description: 'Đã cập nhật phân lịch thành công',
        });

        yield put({
          type: 'saveEmployeesSelected',
          payload: {},
        });
      }
    },

    *createAccessories({ payload }, { select, put }) {
      const { data, assignees } = payload;
      const inventory = yield select(state => state.warrantyRequestSchedule.inventory);
      const inventoryData = yield handleInventory(assignees, inventory);
      const newAssignees = yield _.sortBy(inventoryData, 'assigneeId');
      yield put({
        type: 'saveEmployeesSelected',
        payload: newAssignees,
      });

      const newData = yield hanldeRowspanAccessories(data);
      yield put({
        type: 'warrantyRequest/saveAccessories',
        payload: newData,
      });
    },

    *updateLocationAddress({ payload }, { call }) {
      const { success } = yield call(warrantyRequestAPI.updateAddressLocation, payload);
      return success;
    },

    *updateAccessoriesSchedule({ payload }, { select, put }) {
      const { assignees } = payload;
      const accessories = yield select(state => state.warrantyRequest.item.accessories);
      if (_.has(payload, 'init')) {
        accessories.map(el => {
          el.assignees = assignees.map(els => {
            return {
              ...els,
              lockingQty: 0,
              orderingQty: el.qty,
            };
          });
          return {
            ...el,
          };
        });
      }

      yield put({
        type: 'createAccessories',
        payload: {
          data: accessories,
          assignees,
        },
      });
    },

    *getInventoryAssignees({ payload }, { call, put }) {
      const { assignees } = payload;
      payload.query = {
        assigneeIds: getAssigneeIds(assignees),
      };
      const response = yield call(warrantyRequestAPI.getInventoryAssignees, payload);
      if (response.success) {
        yield put({
          type: 'saveInventory',
          payload: response.result,
        });

        yield put({
          type: 'updateAccessoriesSchedule',
          payload: {
            assignees,
            init: true,
          },
        });
      }
    },

    *getInventoryAssigneesProcess({ payload }, { call, put }) {
      const { assignees } = payload;
      payload.query = {
        assigneeIds: [assignees],
      };
      const response = yield call(warrantyRequestAPI.getInventoryAssignees, payload);
      if (response.success) {
        yield put({
          type: 'saveInventory',
          payload: response.result,
        });
      }
    },

    *getInventoryEmployee({ payload }, { call }) {
      const { assignees, productIds, conditionCode } = payload;
      payload.query = {
        assigneeIds: [assignees],
        productIds,
        conditionCode,
      };
      const response = yield call(warrantyRequestAPI.getInventoryEmployeee, payload);
      if (response.success) {
        return response.result;
      }
      return [];
    },

    *getEmployeeRecord({ payload }, { call, put }) {
      const { success, result } = yield call(warrantyRequestAPI.getEmployeeRecord, payload);
      if (success) {
        yield put({
          type: 'saveCurrentRecord',
          payload: result,
        });
      }
    },

    *getReturnable({ payload }, { call, put }) {
      const { success, result } = yield call(warrantyRequestAPI.getReturnable, payload);
      if (success) {
        yield put({
          type: 'saveReturnable',
          payload: result,
        });
      }
    },
    *getStationInfo({ payload }, { call, put }) {
      const { success, result } = yield call(warrantyRequestAPI.getStationInfo, payload);
      if (success) {
        return result;
      }
    },
  },

  reducers: {
    changeVisibleEmployeesModal(state, { payload }) {
      return {
        ...state,
        visibleEmployeesModal: payload.status,
      };
    },
    saveEmployeesSelected(state, { payload }) {
      return {
        ...state,
        employeesSelected: payload,
      };
    },
    saveStatusAccessories(state, { payload }) {
      const { statusSetAccessories, statusProcess } = payload;
      return {
        ...state,
        statusProcess,
        statusSetAccessories,
      };
    },
    saveInventory(state, { payload }) {
      return {
        ...state,
        inventory: payload,
      };
    },
    saveCurrentRecord(state, { payload }) {
      return {
        ...state,
        currentRecord: payload,
      };
    },
    saveReturnable(state, { payload }) {
      return {
        ...state,
        returnable: payload,
      };
    },
    saveLocationSelected(state, { payload }) {
      return {
        ...state,
        locationSelected: payload,
      };
    },
  },
};

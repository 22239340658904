import React, { useState, useEffect, useRef } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Card, Button, Checkbox, Form } from 'antd';
import StandardTable from '@/components/StandardTable';
import PageHeaderWrapper from '@/components/PageHeaderWrapper';
import Authorized from '@/components/Authorized/Authorized';
import styles from './ManageTable.less';

const CheckboxGroup = Checkbox.Group;

const ManageTable = props => {
  const {
    title = 'Page List',
    columns = [],
    data = {},
    loading = false,
    renderFilterForm,
    openForm,
    operationRender,
    buttonAddNew = 'Thêm mới',
    tableWidth = 1000,
    tableHeight = 600,
    rowSelection = false,
    tableChange,
    onlyRowSelectChange,
    bordered = false,
    size = 'small',
    expandedRowRender,
    showTotalRow = true,
    rowKey = 'key' || 'id',
    authority,
    pagination,
    rowClassName,
    summary,
    editable,
    paginationComponent,
  } = props;

  const [selectedRows, setSelectedRows] = useState([]);
  const plainOptions = _.map(
    _.takeWhile(columns, o => {
      return o.title;
    }),
    'title'
  );
  const [checkedList, setCheckedList] = useState(plainOptions);
  const [checkAll, setcheckAll] = useState(true);
  const [indeterminate, setIndeterminate] = useState(false);
  const [formValues] = useState({});

  const rowSelectionProps = rowSelection
    ? {
        type: 'radio',
        onChange: (selectedRowKeys, selectedRows) => {
          if (onlyRowSelectChange) {
            onlyRowSelectChange(selectedRowKeys, selectedRows);
          }
        },
      }
    : null;

  const handleSelectRows = rows => {
    setSelectedRows(rows);
  };

  const EditableContext = React.createContext();
  const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    );
  };
  const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    canEdit,
    ...restProps
  }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef();

    useEffect(() => {
      if (editing) {
        inputRef.current.focus();
      }
    }, [editing]);

    const save = async e => {
      e.preventDefault();
      try {
        handleSave({ ...record, isApproved: !record.isApproved });
      } catch (errInfo) {
        console.log('Save failed:', errInfo);
      }
    };

    let childNode = children;
    if (editable) {
      switch (dataIndex) {
        case 'isApproved':
          childNode = <Checkbox disabled={!canEdit} checked={record.isApproved} onChange={save} />;
          break;
        default:
          break;
      }
    }
    return <td {...restProps}>{childNode}</td>;
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const handleTableChange = (pagination, filtersArg, sorter) => {
    const filters = Object.keys(filtersArg).reduce((obj, key) => {
      const newObj = { ...obj };
      newObj[key] = window.getValue(filtersArg[key]);
      return newObj;
    }, {});

    const params = {
      CurrentPage: pagination.current,
      MaxResultCount: pagination.pageSize,
      ...formValues,
      ...filters,
    };
    if (sorter.field) {
      params.sorter = `${sorter.field}_${sorter.order}`;
    }

    tableChange(params);
  };

  const onCheckedChange = checkedList => {
    setCheckedList(checkedList);
    setcheckAll(checkedList.length === plainOptions.length);
    setIndeterminate(!!checkedList.length && checkedList.length < plainOptions.length);
  };

  const onCheckAllChange = e => {
    setCheckedList(e.target.checked ? plainOptions : []);
    setcheckAll(e.target.checked);
    setIndeterminate(false);
  };

  // const columnsFilter = columns.filter(o => checkedList.includes(o.title) || o.title === undefined);

  return (
    <PageHeaderWrapper title={title} className="manage-table">
      <Card bordered={false}>
        <div className={styles.tableList}>
          <div className={styles.tableListForm}>{renderFilterForm}</div>
          {operationRender || buttonAddNew ? (
            <div className={styles.tableListOperator}>
              {buttonAddNew ? (
                <Authorized authority={authority}>
                  <Button
                    icon={<PlusOutlined />}
                    shape="round"
                    type="primary"
                    onClick={() => openForm({ open: true, modalType: 'create' })}
                  >
                    {buttonAddNew}
                  </Button>
                </Authorized>
              ) : null}

              {operationRender || null}
            </div>
          ) : null}

          {/* <div style={{ padding: 10 }}>
            <div className="site-checkbox-all-wrapper">
              <Checkbox
                indeterminate={indeterminate}
                onChange={onCheckAllChange}
                checked={checkAll}
              >
                Tất cả
              </Checkbox>
            </div>
            <br />
            <CheckboxGroup options={plainOptions} value={checkedList} onChange={onCheckedChange} />
          </div> */}

          <StandardTable
            rowKey={rowKey}
            selectedRows={selectedRows}
            loading={loading}
            data={data}
            columns={columns}
            scroll={{ x: tableWidth, y: tableHeight }}
            onSelectRow={handleSelectRows}
            onChange={handleTableChange}
            rowSelection={rowSelectionProps}
            bordered={bordered}
            size={size}
            expandedRowRender={expandedRowRender}
            paginationShow={pagination}
            showTotalRow={showTotalRow}
            rowClassName={rowClassName}
            summary={summary}
            components={editable && components}
          />
        </div>
      </Card>
    </PageHeaderWrapper>
  );
};

export default ManageTable;

import React, { PureComponent, Fragment } from 'react';
import { connect } from 'dva';
import { PlusSquareOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Col, Row, Input, Spin, Card, Modal, Button } from 'antd';
import { formatMessage } from 'umi-plugin-react/locale';
import _ from 'lodash';
import router from 'umi/router';
import moment from '@/utils/moment';
import styles from '@/components/ManageTable/ManageTable.less';
import { renderFooterForm, renderData } from '@/utils/table';
import stylesLocal from '../../style.less';
import StandardTable from '@/components/StandardTable';

const FormItem = Form.Item;

@connect(({ warrantyHistory, loading, address, warrantyRequest }) => ({
  warrantyHistory,
  loading,
  address,
  warrantyRequest,
}))
@Form.create()
class ProductRelation extends PureComponent {
  columns = [
    {
      title: 'Mã ca BH',
      dataIndex: 'warrantyRequest',
      key: 'warrantyRequest',
      render: (text, record) => {
        return (
          <a target="_blank" href={`#/warranty-request/${record.warrantyReqId}`}>
            {record.warrantyRequest.code}
          </a>
        );
      },
    },
    {
      title: 'Ngày kết thúc',
      dataIndex: 'warrantyEndDate',
      key: 'warrantyEndDate',
      width: '140px',
      render: (text, record) =>
        record.warrantyEndDate
          ? moment.formatDateTime(record.warrantyEndDate, 'DD/MM/YYYY HH:mm')
          : '',
    },
    {
      title: 'Tình trạng',
      dataIndex: 'statNote',
      key: 'statNote',
    },
    {
      title: 'Hình thức bảo hành',
      dataIndex: 'formOfWarranty',
      key: 'formOfWarranty',
    },
    {
      title: 'Loại dịch vụ',
      dataIndex: 'service',
      key: 'service',
    },
    {
      title: 'Nguyên nhân',
      dataIndex: 'cause',
      key: 'cause',
    },
    {
      title: 'Cách xử lý',
      dataIndex: 'handle',
      key: 'handle',
    },
    {
      title: 'Tình trạng sau xử lý',
      dataIndex: 'beforeStatus',
      key: 'beforeStatus',
    },
  ];

  render() {
    const { loading, data, isShow, handleModalVisibleBH, warrantyRequest } = this.props;
    const { models } = loading;
    const { warrantyLineBySerial } = warrantyRequest;
    const loadingSpin =
      models.warrantyRequest !== undefined ? models.warrantyRequest || false : false;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 16 },
      },
    };

    const datatable = renderData({
      data: { items: warrantyLineBySerial, totalCount: warrantyLineBySerial.length || 0 },
      CurrentPage: 1,
      type: 'warrantyBySerial',
    });

    var lastedItem = _.last(_.sortBy(warrantyLineBySerial, 'warrantyEndDate'))

    if (_.isEmpty(data)) {
      return null;
    }

    return (
      <Fragment>
        <Modal
          destroyOnClose
          title="Bảo hành liên quan"
          visible={isShow}
          onCancel={handleModalVisibleBH}
          footer={renderFooterForm({
            handleModalVisible: handleModalVisibleBH,
          })}
          width={1100}
          maskClosable={false}
        >
          <Spin spinning={loadingSpin}>
            <Card bordered={false}>
              <div
                className={`${styles.schemaForm} ${stylesLocal.warrantyHistory}`}
                style={{ paddingLeft: '0px' }}
              >
                <Row gutter={24}>
                  <Col span={12}>
                    <FormItem label="Model" {...formItemLayout}>
                      {data.model || data.sku || data.product?.sku}
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem label="Mã thùng" {...formItemLayout}>
                      {data.mainSerialNo || data.serialNo}
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={12}>
                    <FormItem label="Hạn BH" {...formItemLayout}>
                      {data.warrantyDate
                        ? moment.formatDateTime(data.warrantyDate, 'YYYY-MM-DD')
                        : lastedItem && lastedItem.warrantyDate ? moment.formatDateTime(lastedItem.warrantyDate, 'YYYY-MM-DD') : ''}
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem label="Mã cào" {...formItemLayout}>
                      {data.hiddenSerialNo}
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={12}>
                    <FormItem label="Tên sản phẩm " {...formItemLayout}>
                      {data.name || data.product?.name || data.productName}
                    </FormItem>
                  </Col>
                </Row>
              </div>

              <StandardTable
                key={Math.random()}
                selectedRows={[]}
                loading={loadingSpin}
                data={datatable}
                columns={this.columns}
                size="small"
                rowSelectionStatus={false}
              />
            </Card>
          </Spin>
        </Modal>
      </Fragment>
    );
  }
}

export default ProductRelation;

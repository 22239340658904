import { apiGet, apiPost, apiPut } from '@/utils/request';

/**
 * Get all business logic data
 * @returns {*}
 */
export function getRefAll() {
  return apiGet(`${BASE_API_URL}/services/app/Ref/All`);
}

/**
 * phòng ban
 * @returns {*}
 */
export function getBranchs() {
  return apiGet(`${BASE_API_URL}/services/app/Branch/GetAll`);
}

/**
 * Phòng ban
 * @returns {*}
 */
export function getDepartments(query = {}) {
  return apiGet(`${BASE_API_URL}/services/app/Department/GetAll`, query);
}

/**
 * bộ phận
 * @returns {*}
 */
export function getParts(query = {}) {
  return apiGet(`${BASE_API_URL}/services/app/Part/GetAll`, query);
}

/**
 * chức vụ
 * @returns {*}
 */
export function getTitles() {
  return apiGet(`${BASE_API_URL}/services/app/Title/GetTitlesForSelect`);
}

/**
 * lấy loại nhân viên
 * @returns {*}
 */
export function getRefs() {
  return apiGet(`${BASE_API_URL}/services/app/Ref/GetUserTypesForSelect`);
}

/**
 * trạm bảo hành
 * @returns {*}
 */
export function getStations() {
  return apiGet(`${BASE_API_URL}/services/app/Station/GetAll`);
}

/**
 * giới tính
 * @returns {*}
 */
export function getGenders() {
  return apiGet(`${BASE_API_URL}/services/app/Ref/GetUserGendersForSelect`);
}

/**
 * Tỉnh thành
 * @returns {*}
 */
export function getProvinces(query) {
  return apiGet(`${BASE_API_URL}/services/app/Province/GetAll`, query);
}

/**
 * Quận huyện
 * @returns {*}
 */
export function getDistricts(query) {
  return apiGet(`${BASE_API_URL}/services/app/District/GetAll`, query);
}

/**
 * Phường xã
 * @returns {*}
 */
export function getWards(query) {
  return apiGet(`${BASE_API_URL}/services/app/Ward/GetAll`, query);
}

/**
 * Get priority types
 * @returns {*}
 */
export function getPriorities(query) {
  return apiGet(`${BASE_API_URL}/services/app/Ref/Cases/Priority`, query);
}

/**
 * Get service types
 * @returns {*}
 */
export function getServiceTypes(query) {
  return apiGet(`${BASE_API_URL}/services/app/Ref/Cases/ServiceType`, query);
}

/**
 * Get service status
 * @returns {*}
 */
export function getServiceStatus(query) {
  return apiGet(`${BASE_API_URL}/services/app/Ref/Cases/Status`, query);
}

/**
 * Get station group list
 * @returns {*}
 */
export function getStationGroup(query) {
  return apiGet(`${BASE_API_URL}/services/app/Ref/GetStationGroupsForSelect`, query);
}

/**
 * Get customer type
 * @returns {*}
 */
export function getcustomerTypes() {
  return apiGet(`${BASE_API_URL}/services/app/Ref/Customer/Type`);
}

export function getRegion() {
  return apiGet(`${BASE_API_URL}/services/app/Region/GetAll`);
}

export function getRegionForSearchBox() {
  return apiGet(`${BASE_API_URL}/services/app/Region/GetForSearchBox`);
}

export function getCurrentStep(query) {
  return apiGet(`${BASE_API_URL}/services/app/BusinessStep/GetAll`, query);
}

export function getAccessoriesOrder() {
  return apiGet(`${BASE_API_URL}/services/app/Ref/AccessoriesOrder/Status`);
}

export function getPbxManagerType(type) {
  return apiGet(`${BASE_API_URL}/services/app/Ref/PbxManager/${type}`);
}

/**
 * Get Object types
 * @param type
 * @returns {*}
 */
export function getRefCaseType(type) {
  return apiGet(`${BASE_API_URL}/services/app/Ref/Cases/${type}`);
}

export function getReturnOrderType(type) {
  return apiGet(`${BASE_API_URL}/services/app/Ref/ReturnOrder/${type}`);
}

export function getDiscountRate() {
  return apiGet(`${BASE_API_URL}/services/app/DiscountRate/GetForSelect`);
}
export function getProvincesPermission() {
  return apiGet(`${BASE_API_URL}/services/app/Ref/Address/Province`);
}
export function getRegionsPermission() {
  return apiGet(`${BASE_API_URL}/services/app/Ref/Address/Region`);
}
export async function updateCategory(body) {
  return await apiPut(`${BASE_API_URL}/services/app/Ref/Category`, { body });
}

export function getLocationByAddress(query) {
  return apiGet(`${BASE_API_URL}/services/app/Address/location`, query);
}
import _ from 'lodash';
import { notification } from 'antd';
import * as stationAPI from '../services/station';
import * as addressAPI from '../services/business';

export default {
  namespace: 'warrantyStation',

  state: {
    searchData: {},
    visibleModal: false,
    modalType: '',
    list: [],
    item: {},
    visibleModalEmployees: false,
    visibleStationModal: false,
    employeesSelected: {},
    warrantyArea: [],
    warrantyAddress: [],
    provinces: [],
    districts: [],
  },

  effects: {
    *fetch({ payload }, { call, put }) {
      const query = Object.assign({});
      _.forEach(payload, (val, key) => {
        if (val) {
          _.set(query, key, val);
        }
      });

      const response = yield call(stationAPI.getAll, query);
      if (response.success) {
        yield put({
          type: 'saveList',
          payload: {
            ...response.result,
          },
        });
      }
    },

    *refreshFetch(_, { put, select }) {
      const searchData = yield select(state => state.warrantyStation.searchData);

      yield put({
        type: 'fetch',
        payload: searchData,
      });
    },

    *get({ payload }, { call, put }) {
      const response = yield call(stationAPI.get, payload.id);
      if (response.success) {
        const {coverages} = response.result;
        _.forEach(coverages, function(value) {
          value.key = value.districtCode
        });
        yield put({
          type: 'save',
          payload: {
            ...response,
            visibleModal: true,
          },
        });

        yield put({
          type: 'address/saveDataAddress',
          payload: {
            address: response.result.stationAddress,
          },
        });

        yield put({
          type: 'saveEmployeesSelected',
          payload: {
            data: {},
          },
        });

        if (!_.isEmpty(response.result.coverages)) {
          const { provinceCode } = response.result.coverages[0].district;
          yield put({
            type: 'getDistricts',
            payload: {
              parentCode: provinceCode,
            },
          });
        }
        yield put({
          type: 'saveWarrantyAddress',
          payload: {
            data: response.result.warrantyAddress,
          },
        });
      }
    },

    *create({ payload }, { call, put }) {
      const response = yield call(stationAPI.create, payload);
      if (response.success) {
        yield put({
          type: 'save',
          payload: {
            ...response,
            visibleModal: false,
          },
        });

        yield put({
          type: 'resetStationForm',
        });

        yield notification.success({
          message: 'Thêm trạm bảo hành',
          description: 'Đã thêm trạm bảo hành mới thành công',
        });

        yield put({
          type: 'refreshFetch',
        });
      }
    },

    *update({ payload }, { call, put }) {
      const response = yield call(stationAPI.update, payload);
      if (response.success) {
        yield put({
          type: 'save',
          payload: {
            ...response,
            visibleModal: false,
          },
        });

        yield put({
          type: 'resetStationForm',
        });

        yield notification.success({
          message: 'Cập nhật trạm bảo hành',
          description: 'Đã cập nhật thông tin trạm bảo hành thành công',
        });

        yield put({
          type: 'refreshFetch',
        });
      }
    },

    *changeVisibleModal({ payload }, { call, put }) {
      yield put({
        type: 'saveVisibleModal',
        payload: {
          status: payload.status,
          modalType: payload.modalType,
        },
      });

      yield put({
        type: 'resetStationForm',
      });

      yield put({
        type: 'address/saveDataAddress',
        payload: {
          address: null,
        },
      });
    },

    *getProvinces({ payload }, { select, put }) {
      const businessData = yield select(state => state.business.businessData);
      const data = _.get(businessData, 'Province');
      if (data) {
        yield put({
          type: 'saveProvinces',
          payload: {
            items: data,
          },
        });
        yield put({
          type: 'saveDistricts',
          payload: {
            items: [],
          },
        });
      }
    },

    *getDistricts({ payload }, { call, put }) {
      const response = yield call(addressAPI.getDistricts, payload);
      if (response.success) {
        const { items } = response.result;
        const { isNotAll } = payload;
        if (!isNotAll) {
          const data = [
            {
              code: 'all',
              name: 'Tất cả',
              province: null,
              provinceCode: 'all',
            },
            ...items,
          ];
          yield put({
            type: 'saveDistricts',
            payload: {
              items: data,
            },
          });
        } else {
          yield put({
            type: 'saveDistricts',
            payload: {
              items,
            },
          });
        }

        yield put({
          type: 'saveWards',
          payload: {
            items: [],
          },
        });
      }
    },
    *getWards({ payload }, { call, put }) {
      const response = yield call(addressAPI.getWards, payload);
      if (response.success) {
        const { items } = response.result;
        yield put({
          type: 'saveWards',
          payload: {
            items,
          },
        });
      }
    },

    *updateEmployeesSelected({ payload }, { call, put }) {
      yield put({
        type: 'saveEmployeesSelected',
        payload: {
          data: payload.data,
        },
      });

      yield put({
        type: 'address/saveDataAddress',
        payload: {
          address: payload.data.userAddress,
        },
      });
    },

    *deleteStationAddress({ payload }, { call, put, select }) {
      const response = yield call(stationAPI.deleteAddress, payload);
      if (response) {
        const { id } = payload;
        const warrantyAddress = yield select(state => state.warrantyStation.warrantyAddress);
        const newData = warrantyAddress.filter(el => {
          return el.key !== _.toString(id);
        });
        yield put({
          type: 'saveWarrantyAddress',
          payload: {
            data: newData,
          },
        });
        return newData;
      }
      return []
    },
    *updateLocationStationAddress({ payload }, { call, put, select }) {
      const response = yield call(stationAPI.updateLocationAddress, payload);
      if (response) {
        const { id } = payload;
        const {result} = response;
        const warrantyAddress = yield select(state => state.warrantyStation.warrantyAddress);
        const newData = warrantyAddress.map(el=>{
          if(el.addressId === id)
          {
            el.lat = result.lat;
            el.lng = result.lng;
          }
          return el;
        })
        console.log("newData",newData,result,id,warrantyAddress)
        yield put({
          type: 'saveWarrantyAddress',
          payload: {
            data: newData,
          },
        });
        return newData;
      }
      return []
    },
    *addStationAddress({ payload }, { call, put, select }) {
      const response = yield call(stationAPI.addAddress, payload);
      if (response.success) {
        const newData = yield select(state => state.warrantyStation.warrantyAddress);
        const data = [...newData, response.result];
        yield put({
          type: 'saveWarrantyAddress',
          payload: {
            data,
          },
        });
        return data;
      }
      return [];
    },

    *updateStationAddress({ payload }, { call, put, select }) {
      const response = yield call(stationAPI.updateAddress, payload);
      if (response.success) {
        // const newData = yield select(state => state.warrantyStation.warrantyAddress);
        // const data = [...newData, response.result];
        // yield put({
        //   type: 'saveWarrantyAddress',
        //   payload: {
        //     data,
        //   },
        // });
        // return data;
      }
      return [];
    },
  },

  reducers: {
    saveVisibleModal(state, { payload }) {
      return {
        ...state,
        visibleModal: payload.status,
        modalType: payload.modalType,
      };
    },

    saveList(state, { payload }) {
      return {
        ...state,
        list: payload,
      };
    },

    save(state, { payload }) {
      const warrantyArea = payload.result.coverages;
      return {
        ...state,
        item: payload.result,
        visibleModal: payload.visibleModal,
        warrantyArea,
      };
    },

    changeVisibleEmployeesModal(state, { payload }) {
      return {
        ...state,
        visibleModalEmployees: payload.status,
      };
    },

    saveEmployeesSelected(state, { payload }) {
      return {
        ...state,
        employeesSelected: payload.data,
      };
    },

    saveWarrantyArea(state, { payload }) {
      return {
        ...state,
        warrantyArea: payload.data,
      };
    },
    saveWarrantyAddress(state, { payload }) {
      return {
        ...state,
        warrantyAddress: payload.data,
      };
    },

    resetStationForm(state, { payload }) {
      return {
        ...state,
        employeesSelected: {},
        warrantyArea: [],
        warrantyAddress: [],
      };
    },

    saveProvinces(state, { payload }) {
      return {
        ...state,
        provinces: payload.items,
      };
    },

    saveDistricts(state, { payload }) {
      return {
        ...state,
        districts: payload.items,
      };
    },
    saveWards(state, { payload }) {
      return {
        ...state,
        wards: payload.items,
      };
    },
    saveSearchData(state, { payload }) {
      return {
        ...state,
        searchData: {
          ...payload,
        },
      };
    },

    changeVisibleStationModal(state, { payload }) {
      return {
        ...state,
        visibleStationModal: payload.status,
      };
    },
  },
};

import _ from 'lodash';
import { notification } from 'antd';
import * as configLimitAPI from '@/services/configLimit';

export default {
  namespace: 'configLimit',

  state: {
    visibleModal: false,
    modalType: '',
    list: [],
    item: {},
    configLimitsSearch: [],
  },

  effects: {
    *fetch({ payload }, { call, put }) {
      const query = Object.assign({});
      _.forEach(payload, (val, key) => {
        if (val) {
          _.set(query, key, val);
        }
      });

      const response = yield call(configLimitAPI.getAll, query);
      if (response.success) {
        yield put({
          type: 'saveList',
          payload: {
            ...response.result,
          },
        });
      }
    },

    *get({ payload }, { call, put }) {
      const { item } = payload;
      // const response = yield call(configLimitAPI.get, payload.id);
      yield put({
        type: 'save',
        payload: {
          item,
          visibleModal: true,
        },
      });
    },

    *create({ payload }, { call, put }) {
      const response = yield call(configLimitAPI.create, payload);
      if (response.success) {
        yield put({
          type: 'save',
          payload: {
            ...response,
            visibleModal: false,
          },
        });

        yield notification.success({
          message: 'Thêm cấu hình hạn mức công nợ',
          description: 'Đã thêm cấu hình hạn mức công nợ thành công',
        });

        yield put({
          type: 'fetch',
        });
      }
    },

    *update({ payload }, { call, put }) {
      const response = yield call(configLimitAPI.update, payload);
      if (response.success) {
        yield put({
          type: 'save',
          payload: {
            ...response,
            visibleModal: false,
          },
        });

        yield notification.success({
          message: 'Cập nhật cấu hình hạn mức công nợ',
          description: 'Đã cập nhật cấu hình hạn mức công nợ thành công',
        });

        yield put({
          type: 'fetch',
        });
      }
    },

    *changeVisibleModal({ payload }, { call, put }) {
      yield put({
        type: 'saveVisibleModal',
        payload: {
          status: payload.status,
          modalType: payload.modalType,
        },
      });
    },

    *searchConfigLimits({ payload }, { call, put }) {
      const query = Object.assign({});
      _.forEach(payload, (val, key) => {
        if (val) {
          _.set(query, key, val);
        }
      });

      const response = yield call(configLimitAPI.search, query);

      if (response.success) {
        response.result.items.map(
          el => (el.key = `${el.id}-${el.serialNo ? el.serialNo : '0'}-${Math.random()}`)
        );
        yield put({
          type: 'saveConfigLimitsSearch',
          payload: {
            ...response.result,
          },
        });
      }
    },

    *checklimitByUser({ payload }, { call, put, select }) {
      const response = yield call(configLimitAPI.getAmountConfitLimtitByUserId, payload);
      if (response.success) {
        const { result } = response;
        const { invBalAmount, pendingOutAmount } = result;
        const visibleModal = yield select(state => state.configLimit.visibleModal);
        yield put({
          type: 'save',
          payload: {
            item: { invBalAmount, pendingOutAmount },
            visibleModal: visibleModal,
          },
        });
      } else {
        return {};
      }
    },
    *delete({ payload }, { call,put }) {
      const response = yield call(configLimitAPI.deleteConfig, payload);
      if(response)
      {
        yield notification.success({
          message: 'Xóa cấu hình hạn mức công nợ',
          description: 'Đã xóa cấu hình hạn mức công nợ thành công',
        });

        yield put({
          type: 'fetch',
        });
      }
     
    },
  },

  reducers: {
    saveVisibleModal(state, { payload }) {
      return {
        ...state,
        visibleModal: payload.status,
        modalType: payload.modalType,
        extensions: [],
      };
    },

    saveList(state, { payload }) {
      return {
        ...state,
        list: payload,
      };
    },
    save(state, { payload }) {
      return {
        ...state,
        item: payload.item,
        visibleModal: payload.visibleModal,
      };
    },
    saveConfigLimitsSearch(state, { payload }) {
      return {
        ...state,
        configLimitsSearch: payload,
      };
    },
  },
};

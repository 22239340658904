/* eslint-disable no-undef */
import { apiGet, apiPut, apiDownloadFile, apiPost } from '@/utils/request';

export function getWarrantyRequestHistory(query) {
  return apiGet(`${BASE_API_URL}/services/app/report/warranty-request-history`, query);
}

export function getTechRevenue(query) {
  return apiGet(`${BASE_API_URL}/services/app/report/tech-revenue`, query);
}

export function getReturnOrder(query) {
  return apiGet(`${BASE_API_URL}/services/app/report/return-order`, query);
}

export function getCustomer(query) {
  return apiGet(`${BASE_API_URL}/services/app/report/customer`, query);
}

export function getCustomerCare(query) {
  return apiGet(`${BASE_API_URL}/services/app/report/customer-care`, query);
}

export function getAccessoriesOrder(query) {
  return apiGet(`${BASE_API_URL}/services/app/report/accessories-order`, query);
}

export function exportWarrantyRequestHistory(query) {
  return apiGet(`${BASE_API_URL}/services/app/exportexcel/warranty-request-history`, query);
}

export function exportTechRevenue(query) {
  return apiGet(`${BASE_API_URL}/services/app/exportexcel/tech-revenue`, query);
}

export function exportCustomer(query) {
  return apiGet(`${BASE_API_URL}/services/app/exportexcel/customer`, query);
}

export function exportCustomerCare(query) {
  return apiGet(`${BASE_API_URL}/services/app/exportexcel/customer-care`, query);
}

export function warrantyRequestBusiness(query) {
  return apiGet(`${BASE_API_URL}/services/app/report/warranty-request-business-step-stat`, query);
}

export function warrantyRequestBusinessOverview(query) {
  return apiGet(
    `${BASE_API_URL}/services/app/report/warranty-request-business-step-stat-overview`,
    query
  );
}

export function warrantyRequestObject(query) {
  return apiGet(`${BASE_API_URL}/services/app/report/warranty-request-process-assignee`, query);
}

export function getUserProcessers(query) {
  return apiGet(`${BASE_API_URL}/services/app/User/processers`, query);
}

export function getInventory(query) {
  return apiGet(`${BASE_API_URL}/services/app/report/inventory`, query);
}

export function getSerialHistory(query) {
  return apiGet(`${BASE_API_URL}/services/app/report/serial-history`, query);
}
export function getProductWarrantyTimes(query) {
  return apiGet(`${BASE_API_URL}/services/app/report/product-warranty-times`, query);
}

export function getWarrantyActivation(query) {
  return apiGet(`${BASE_API_URL}/services/app/WarrantyActivation/SearchAll`, query);
}

export function getActivityVisit(query) {
  return apiGet(`${BASE_API_URL}/services/app/report/activity-visit`, query);
}
export function getWorkingDiary(query) {
  return apiGet(`${BASE_API_URL}/services/app/report/working-diary`, query);
}

export function getInvWaitingOut(query) {
  return apiGet(`${BASE_API_URL}/services/app/report/inventory-waitingout`, query);
}

export function getInventoryAccessories(query) {
  return apiGet(`${BASE_API_URL}/services/app/report/inventory-statement-by-product`, query);
}

export function getActivityTracking(query) {
  return apiGet(`${BASE_API_URL}/services/app/report/activity-tracking`, query);
}
export function getActivityImages(query) {
  return apiGet(`${BASE_API_URL}/services/app/activity/getActivityImages`, query);
}
export function getAONoReturnImages(query) {
  return apiGet(`${BASE_API_URL}/services/app/report/accessories-noreturn-getimages`, query);
}

export function getUserActivities(query) {
  return apiGet(`${BASE_API_URL}/services/app/Activity/GetUserActivities`, query);
}

export function getTimeSheetsHistories(query) {
  return apiGet(`${BASE_API_URL}/services/app/report/timesheet-histories`, query);
}

export function getAccessoriesNoReturn(query) {
  return apiGet(`${BASE_API_URL}/services/app/report/accessories-noreturn`, query);
}
export function getRevenueOfSaleAccessories(query) {
  return apiGet(`${BASE_API_URL}/services/app/report/ReportRevenueOfSaleAccessories`, query);
}
export function getInventoryInOut(query) {
  return apiGet(`${BASE_API_URL}/services/app/report/inventory-inout`, query);
}
export function getBookingAccessories(query) {
  return apiGet(`${BASE_API_URL}/services/app/report/booking-accessories`, query);
}
export function getLogs({ id }) {
  return apiGet(`${BASE_API_URL}/services/app/user/logs/${id}`);
}

export function getAccessoriesOrderProducts(query) {
  return apiGet(`${BASE_API_URL}/services/app/report/accessories-order-products`, query);
}
export function getReceivableMonthly(query) {
  return apiGet(`${BASE_API_URL}/services/app/report/receivablemonthly`, query);
}
export function getSummaryReceivableByMonth(query) {
  return apiGet(`${BASE_API_URL}/services/app/report/summary_receivable`, query);
}
export function getDetailWarrantyActivation(id, code) {
  return apiGet(`${BASE_API_URL}/services/app/WarrantyActivation/GetDetail`, {
    id: id,
    code: code,
  });
}
export function updateWarrantyActivationInfo(body) {
  return apiPut(`${BASE_API_URL}/services/app/WarrantyActivation/Update`, { body });
}
export function cancleWarrantyActivationInfo(body) {
  return apiPut(`${BASE_API_URL}/services/app/WarrantyActivation/Cancle`, { body });
}
export function getInventoryOnhandSerials({ controller, objectId, productId, query }) {
  return apiGet(
    `${BASE_API_URL}/services/app/${controller}/${objectId}/inventory/${productId}/onhand-serials`,
    query
  );
}

export function getReportStationRevenueSummary(query) {
  return apiGet(`${BASE_API_URL}/services/app/report/ReportStationRevenueSummary`, query);
}

export function getReportStationRevenueDetail(query) {
  return apiGet(`${BASE_API_URL}/services/app/report/ReportStationRevenueDetail`, query);
}
export function getStationRewardPunishment(query) {
  return apiGet(`${BASE_API_URL}/services/app/report/ReportStationRewardPunishment`, query);
}
export function getStationSummaryRewardPunishment(query) {
  return apiGet(`${BASE_API_URL}/services/app/report/ReportStationSummaryRewardPunishment`, query);
}

export function getReportTechRevenueSummary(query) {
  return apiGet(`${BASE_API_URL}/services/app/report/ReportTechRevenueSummary`, query);
}

export function getReportInventoryDv(query) {
  return apiGet(`${BASE_API_URL}/services/app/report/ReportInventoryDv`, query);
}
export function getWaitingInRecords(query) {
  return apiGet(`${BASE_API_URL}/services/app/report/waiting-in-records`, query);
}
export function getWaitingOutRecords(query) {
  return apiGet(`${BASE_API_URL}/services/app/report/waiting-out-records`, query);
}

export function exportInventoryDv(payload) {
  return apiDownloadFile(`${BASE_API_URL}/services/app/Inventory/ExportInventoryDv`, payload);
}

export function getReportSaleOrder(query) {
  return apiGet(`${BASE_API_URL}/services/app/report/ReportSaleOrder`, query);
}
export function getReportPerformServiceSummary(query) {
  return apiGet(`${BASE_API_URL}/services/app/report/ReportPerformServiceSummary`, query);
}
export function getDebtAccessoriesManagement(query) {
  return apiGet(`${BASE_API_URL}/services/app/CashFlow/accessories-debt`, query);
}
export function getDebtAccessoriesManagementDetail(query) {
  return apiGet(`${BASE_API_URL}/services/app/CashFlow/accessories-debt-detail`, query);
}
export function getAccessoriesNoTakenBack(query) {
  return apiGet(`${BASE_API_URL}/services/app/report/GetAccessoriesNoTakenBack`, query);
}

export function getAccessoriesNoTakenBackDetail(id) {
  return apiGet(`${BASE_API_URL}/services/app/report/GetAccessoriesNoTakenBackDetail/${id}`);
}

export function saveStatusAoTakenBack(body) {
  return apiPost(`${BASE_API_URL}/services/app/WarrantyRequest/UpdateReplacementStatus`, {
    body,
  });
}

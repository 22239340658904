import { routerRedux } from 'dva/router';
import { notification } from 'antd';
import _ from 'lodash';
import * as userService from '@/services/user';

export default {
  namespace: 'user',

  state: {
    currentUser: {},
    users: {},
    filteredUsers: [],
    filteredUsersNoPermission: [],
  },

  effects: {
    *fetchCurrent({ payload }, { call, put, select }) {
      const response = yield call(userService.current);
      if (response && response.success && response.result.user != null) {
        yield put({
          type: 'saveCurrentUser',
          payload: response.result.user,
        });
        yield put({
          type: 'saveDayOffConfig',
          payload: response.result.dayOffConfig,
        });
        const { menuData } = yield select(state => state.menu);
        const { location } = yield select(state => state.routing);
        if (_.isEmpty(location) || location.pathname === '/') {
          if (_.some(menuData, { path: '/call-center' })) {
            yield put(routerRedux.replace('/call-center'));
          } else {
            const item = _.head(menuData);
            const redirect = _.has(item, 'children') ? _.head(_.get(item, 'children')) : item;
            yield put(routerRedux.replace(_.get(redirect, 'path', '/')));
          }
        }
      } else {
        window.g_app._store.dispatch({
          type: 'login/logout',
        });
      }
    },

    *getForSelect({ payload }, { call, put, select }) {
      if (payload.firstTime) {
        const response = yield call(userService.getForSelect, { ...payload, isGetWithPermissionProvince: true });
        if (response && response.success) {
          yield put({
            type: 'saveUserList',
            payload: response.result.items,
          });
          yield put({
            type: 'saveFilteredUserList',
            payload: response.result.items,
          });
        }
      }
      const { users = [] } = yield select(state => state.user);
      if (_.isEmpty(payload) || _.isEmpty(payload.Type)) {
        yield put({
          type: 'saveFilteredUserList',
          payload: users,
        });
      } else {
        yield put({
          type: 'saveFilteredUserList',
          payload: _.filter(users, { type: payload.Type }),
        });
      }
    },

    *getForSelectNoPermission({ payload }, { call, put, select }) {
      const response = yield call(userService.getForSelect, { isGetWithPermissionProvince: false });
      if (response && response.success) {
        yield put({
          type: 'saveFilteredUserNoPermissionList',
          payload: response.result.items,
        });
      }
    },

    *changePassword({ payload }, { call }) {
      const response = yield call(userService.changePassword, payload);
      if (response && response.success) {
        yield notification.success({
          message: 'Đổi mật khẩu',
          description: 'Đã đổi mật khẩu thành công',
        });
      }

      return _.get(response, 'success', false);
    },
    *getKeyRequireSignature({ payload }, { call }) {
      const response = yield call(userService.getKeyRequireSignature, payload);
      return _.get(response, 'result', []);
    },
    *updateKeyRequireSignature({ payload }, { call }) {
      const response = yield call(userService.updateKeyRequireSignature, payload);
      if (response && response.success) {
        yield notification.success({
          message: 'Cập nhật thông tin',
          description: 'Lưu thành công',
        });
      }
      return _.get(response, 'success', false);
    },
  },

  reducers: {
    saveCurrentUser(state, action) {
      return {
        ...state,
        currentUser: action.payload || {},
      };
    },
    saveDayOffConfig(state, action) {
      return {
        ...state,
        dayOffConfig: action.payload || {},
      };
    },
    updateUserData(state, action) {
      return {
        ...state,
        [action.field]: action.value,
      };
    },
    saveUserList(state, action) {
      return {
        ...state,
        users: action.payload,
      };
    },
    saveFilteredUserList(state, action) {
      return {
        ...state,
        filteredUsers: action.payload,
      };
    },
    saveFilteredUserNoPermissionList(state, action) {
      return {
        ...state,
        filteredUsersNoPermission: action.payload,
      };
    },
    changeNotifyCount(state, action) {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          notifyCount: action.payload.totalCount,
          unreadCount: action.payload.unreadCount,
        },
      };
    },
    reset(state) {
      return {
        ...state,
        list: [],
        currentUser: {},
      };
    },
  },
};

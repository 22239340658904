export const DEFAULT_ADMIN_AVATAR = '/images/avatars/admin.jpg';
export const PP = 60;
export const PAGINATION_PAGE_SIZE = 20;
export const WORK_ORDER_STEP = {
  one: 'CRM_CS_BH_01',
  two: 'CRM_CS_BH_02',
  three: 'CRM_CS_BH_03',
  four: 'CRM_CS_BH_04',
  five: 'CRM_CS_BH_05',
  six: 'CRM_CS_BH_06',
};
export const WORK_ORDER_STATUS = {
  TODO: 'TODO',
  DOING: 'DOING',
};

export const WORK_ORDER_CUR_STEP = {
  one: 10,
  two: 20,
  three: 30,
  four: 40,
  five: 50,
  six: 60,
};
export const API_GG_KEY ="AIzaSyBEIr2KBfdU8ZT3Y_qqi63zVuILBDuUSPA"

export const SERVICE_REDIRECT = 'BH';
export const CALL_CENTER_RE_CHECK = 'RE-CHECK';
export const CALL_CENTER_RE_CHECKING = 'RE-CHECKING';
export const CALL_CENTER_DONE = 'DONE';
export const CALL_CENTER_NEW = 'NEW';
export const CALL_CENTER_IN_PROGRESS = 'IN-PROGRESS';
export const CALL_CENTER_CLOSED = 'CLOSED';
export const CALL_CENTER_CLOSING = 'CLOSING';
export const CALL_CENTER_INBOUND = 'inbound';
export const CALL_CENTER_OUTBOUND = 'outbound';
export const CALL_CENTER_INFO_CONFIRM = 'INFO_CONFIRM';
export const CALL_CENTER_OBJECT_INFO_CONFIRM = 'OBJECT_INFO_CONFIRM';
export const PATTERN_NUMBER = /^\d+$/;
export const USER_TYPE_DV = 'DV';
export const CALL_CENTER_DISPOSITION = {
  NOANSWER: 'Không trả lời',
  ANSWERED: 'Bắt máy',
  BUSY: 'Bận',
  FAILED: 'Lỗi kết nối',
};
export const CALL_CENTER_REFTYPE_CRM = 'CRM';
export const CALL_CENTER_REFTYPE_CC = 'CC';
export const CALL_CENTER_REFTYPE_DVGO = 'DVGO';
export const CALL_CENTER_OBJECT_TYPE = {
  CUSTOMER: 'CUSTOMER',
  EMPLOYEE: 'EMPLOYEE',
};

export const INV_OBJECT_TYPE = {
  EMPLOYEE: 'EMPLOYEE',
  STATION: 'STATION',
  CUSTOMER: 'CUSTOMER',
};

export const WAREHOUSE_TYPE = {
  INTERNAL: 'INTERNAL',
  EXTERNAL: 'EXTERNAL',
};

export const ACCESSORIES_ORDER_STATUS = {
  DA_DAT: 'DA_DAT',
  TAM: 'TAM',
  CHO_XN: 'CHO_XN',
  K_TU_CHOI: 'K_TU_CHOI',
};

export const ACCESSORIES_ORDER_TYPES = {
  BAN: 'BAN',
};

export const ACCESSORIES_CONDITION_TYPES = {
  SONG_PHI: 'SONG_PHI',
  SONG: 'SONG',
};

export const SL = 0.01;

export const objectTypes = [
  {
    code: INV_OBJECT_TYPE.EMPLOYEE,
    name: 'Nhân viên Đại Việt',
  },
  {
    code: INV_OBJECT_TYPE.CUSTOMER,
    name: 'Khách hàng',
  },
  {
    code: INV_OBJECT_TYPE.STATION,
    name: 'Trạm bảo hành',
  },
];

export const objectTypesAll = [
  {
    code: '',
    name: 'Tất cả',
  },
  {
    code: INV_OBJECT_TYPE.EMPLOYEE,
    name: 'Nhân viên Đại Việt',
  },
  {
    code: INV_OBJECT_TYPE.CUSTOMER,
    name: 'Khách hàng',
  },
  {
    code: INV_OBJECT_TYPE.STATION,
    name: 'Trạm bảo hành',
  },
];

export const warehouseTypes = [
  {
    code: WAREHOUSE_TYPE.EXTERNAL,
    name: 'Kho tổng',
  },
  {
    code: WAREHOUSE_TYPE.INTERNAL,
    name: 'Kho tỉnh',
  },
];

export const DEFAULT_DATE_TIME = 'YYYY-MM-DD HH:mm:ss';
export const DEFAULT_DATE = 'YYYY-MM-DD';
export const DEFAULT_TIME = 'HH:mm';

export const ACTIVITY_TYPE = {
  CHECKIN: 'Chấm công vào',
  CHECKOUT: 'Chấm công ra',
  CHECKPOINT: 'Khai báo vị trí',
  W_ACTION: 'Bảo Hành',
  W_CHECKIN: 'Bắt đầu ca',
  W_CHECKOUT: 'Kết thúc ca',
};

export const CASHFLOW_STATUS = [
  { code: 'REVIEWING', name: 'Đang xem xét' },
  { code: 'REVIEWED', name: 'Xem xét' },
  { code: 'APPROVED', name: 'Đã duyệt' },
];

export const ACTIVITY_ICON = {
  CHECKIN: {
    url: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png',
  },
  CHECKOUT: {
    url: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png',
  },
  CHECKPOINT: {
    url: 'http://maps.google.com/mapfiles/ms/icons/green-dot.png',
  },
  W_CHECKIN: {
    url: 'http://maps.google.com/mapfiles/ms/icons/yellow-dot.png',
  },
  W_CHECKOUT: {
    url: 'http://maps.google.com/mapfiles/ms/icons/purple-dot.png',
  },
};

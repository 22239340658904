import { Select, Radio, Spin, Divider, Tag, Input } from 'antd';
import React from 'react';
import _ from 'lodash';
import { objectTypes, objectTypesAll } from '@/configs/constants';
import styles from './utils.less';

const { Option } = Select;

export function renderSelect({
  data = [],
  inputRef = '',
  inputRefFunc = () => { },
  mode = 'default',
  placeholder = 'Chọn',
  type = 'id',
  fieldName = 'name',
  onChange = () => { },
  onSearch = () => { },
  disabled = false,
  labelInValue = false,
  placeHolderVal = '',
  loading = false,
  objectType = undefined,
  isHasObjectType = false,
  value = null,
  onObjectTypeChange = () => { },
}) {
  function handleFieldName(fieldVal, el) {
    if (fieldVal.indexOf('-') >= 0) {
      const fieldEl = fieldVal.split('-');
      return `${el[fieldEl[0]]}-${_.get(el, fieldEl[1])}`;
    }

    return _.isArray(fieldVal) ? el[fieldVal[0]][fieldVal[1]] : el[fieldVal];
  }

  const items = [];
  if (mode === 'default') {
    items.push(
      <Option value={placeHolderVal} key={0}>
        {placeholder}
      </Option>
    );
  }

  if (!_.isEmpty(data)) {
    data.map(el =>
      items.push(
        <Option value={el[type]} key={el[type]}>
          {handleFieldName(fieldName, el)}
        </Option>
      )
    );
  }

  const renderDropdowSelect = menu => {
    return (
      <div>
        <div style={{ display: 'flex-row', flexWrap: 'nowrap', padding: 8, textAlign: 'center' }}>
          {_.map(isHasObjectType ? objectTypesAll : objectTypes, item => (
            <Tag
              onClick={() => onObjectTypeChange(item.code)}
              color={item.code === objectType ? 'processing' : 'default'}
            >
              {item.name}
            </Tag>
          ))}
        </div>
        <Divider style={{ margin: '4px 0' }} />
        {menu}
      </div>
    );
  };

  return (
    <Select
      style={{ width: '100%' }}
      dropdownStyle={{ zIndex: 3000 }}
      ref={e => inputRefFunc(e) || inputRef}
      mode={mode}
      showSearch
      placeholder={placeholder}
      labelInValue={labelInValue}
      onChange={e => onChange(e)}
      onSelect={e => onChange(e)}
      disabled={disabled}
      value={value}
      loading={loading}
      showArrow={!disabled}
      optionFilterProp="children"
      onSearch={onSearch}
      notFoundContent={loading ? <Spin size="small" /> : null}
      filterOption={(input, option) => {
        if (_.isEmpty(option.props.children)) {
          return false;
        }
        return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }}
      dropdownRender={(objectType || isHasObjectType === true) && renderDropdowSelect}
    >
      {items}
    </Select>
  );
}

export function renderRadioGroup({
  data,
  type = 'id',
  onChange = () => { },
  disabled = false,
  defaultValue = '',
  defaultAttr = false,
}) {
  const items =
    !_.isEmpty(data) > 0
      ? _.map(data, el => {
        return (
          <div>
            <Radio
              value={el[type]}
              key={el[type]}
              defaultChecked={el[type] === defaultValue}
              style={{ width: '30%' }}
            >
              {el.name}
            </Radio>
          </div>
        );
      })
      : null;

  const op = [];
  data.map(i => op.push({ label: i.name, value: i[type] }));

  const options = defaultAttr ? (
    <Radio.Group
      onChange={e => onChange(e)}
      disabled={disabled}
      defaultValue={defaultValue}
      className={styles.radioGroup}
    >
      {items}
    </Radio.Group>
  ) : (
    <Radio.Group onChange={e => onChange(e)} disabled={disabled} className={styles.radioGroup}>
      {items}
    </Radio.Group>
  );
  return options;
}

import _ from 'lodash';


export const hanldeRowspanReportReceivableMonthly = data => {
  const objC = [];
  return data.map((el, idz) => {
    const check = data.filter(els => els.userCode === el.userCode).length;
    let checkAvailable = 0;
    let isMerge = -1;
    if (check > 1 ) {
      isMerge = 0;
      if (objC.indexOf(el.userCode) < 0) {
        objC.push(el.userCode);
        isMerge = 1;
      }
      checkAvailable = check;
    }

    el.checkAvailable = checkAvailable;
    el.isMerge = isMerge;
    return el;
  });
};


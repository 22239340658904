import * as exceptionAPI from '../services/exception';

export default {
  namespace: 'exception',

  state: {
    listItems: [],
    item: {},
  },

  effects: {
    *getAll({ payload }, { call, put }) {
      const response = yield call(exceptionAPI.getAll, payload);
      if (response.success) {
        yield put({
          type: 'saveItemList',
          payload: {
            items: response.result.items,
          },
        });
      }
    },

    *get({ payload }, { call, put }) {
      const response = yield call(exceptionAPI.get, payload);
      if (response.success) {
        yield put({
          type: 'saveItem',
          payload: {
            item: response.result,
          },
        });
      }
    },
  },

  reducers: {
    saveItemList(state, { payload }) {
      return {
        ...state,
        listItems: payload.items,
      };
    },
    saveItem(state, { payload }) {
      return {
        ...state,
        item: payload.item,
      };
    },
  },
};

import React from 'react';
import { connect } from 'dva';
import { ExportOutlined, SearchOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Card, Col, Input, Row, TreeSelect } from 'antd';
import _ from 'lodash';
import styles from '@/pages/List/TableList.less';
import { renderSelect } from '@/utils/business';
import MasterFilter from '@/components/MasterFilter';
import { handleParams, formatExportUrl } from '@/utils/utils';

const FormItem = Form.Item;

@connect(({ business, user }) => ({
  business,
  user,
}))
@Form.create()
class ProductWarrantyTimesFilter extends MasterFilter {
  handleSearch = e => {
    const { handleSearch, form } = this.props;
    e.preventDefault();
    form.validateFields((err, values) => {
      if (err) return;
      var categoryCodes = _.get(values, 'categoryCode', '');
      if (!_.isNil(categoryCodes)) values.categoryCodes = _.join(categoryCodes, ',');
      var brandCodes = _.get(values, 'brandCode', '');
      if (!_.isNil(brandCodes)) values.brandCodes = _.join(brandCodes, ',');
      if (handleSearch) {
        handleSearch(values);
      }
    });
  };

  export = () => {
    const { form } = this.props;
    form.validateFields((err, values) => {
      var categoryCodes = _.get(values, 'categoryCode', '');
      if (!_.isNil(categoryCodes)) values.categoryCodes = _.join(categoryCodes, ',');
      var brandCodes = _.get(values, 'brandCode', '');
      if (!_.isNil(brandCodes)) values.brandCodes = _.join(brandCodes, ',');
      const param = new URLSearchParams(handleParams(values)).toString();
      const url = `${BASE_API_URL}/services/app/exportexcel/product-warranty-times?${param}`;
      window.open(formatExportUrl(url), '_blank');
    });
  };
  getCategoryArr = () => {
    const { business } = this.props;
    const { businessData } = business;
    const { Category } = businessData;

    var data = _.map(Category, i => {
      var childrentData = _.map(_.get(i, 'children', []), k => {
        if (!_.isNil(k)) return { value: k.id, title: k.name, key: k.id };
      });
      return { value: i.id, title: i.name, children: childrentData, key: i.id };
    });
    return data;
  };

  render() {
    const {
      form: { getFieldDecorator },
      business,
    } = this.props;

    const { businessData } = business;

    return (
      <Card bordered>
        <Form onSubmit={this.handleSearch} layout="inline">
          <Row gutter={{ md: 12, lg: 24, xl: 48 }} key={1}>
            <Col md={12} sm={24}>
              <FormItem label="Ngành hàng">
                {getFieldDecorator('categoryCode')(
                  <TreeSelect
                    style={{ width: '100%' }}
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                    treeData={this.getCategoryArr()}
                    placeholder="Tên ngành hàng"
                    treeDefaultExpandAll
                    treeCheckable
                    treeNodeFilterProp="title"
                  />
                )}
              </FormItem>
            </Col>
            <Col md={12} sm={24}>
              <FormItem label="Nhãn hiệu">
                {getFieldDecorator('brandCode')(
                  renderSelect({
                    data: _.get(businessData, 'Brand'),
                    placeholder: 'Tất cả',
                    type: 'id',
                    mode: 'multiple',
                  })
                )}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={{ md: 12, lg: 24, xl: 48 }} key={2}>
            <Col md={12} sm={24}>
              <FormItem label="Từ Khóa">
                {getFieldDecorator('Keyword')(<Input placeholder="Mã cào/mã thùng/mã sản phẩm" />)}
              </FormItem>
            </Col>
            <Col md={12} sm={24}>
              <FormItem label="Số lần bảo hành">
                {getFieldDecorator('numOfWR')(
                  <Input min={0} type="number" placeholder="Số lần bảo hành >=1" />
                )}
              </FormItem>
            </Col>
            <Col md={12} sm={24}>
              <Row gutter={{ md: 24, lg: 24, xl: 48 }}>
                <Col md={4} sm={24} style={{ marginRight: '15px' }}>
                  <span className={styles.submitButtons}>
                    <Button type="primary" shape="round" htmlType="submit">
                      <SearchOutlined />
                      Tìm kiếm
                    </Button>
                  </span>
                </Col>
                <Col md={4} sm={24}>
                  <span className={styles.submitButtons}>
                    <Button type="dashed" shape="round" onClick={this.export}>
                      <ExportOutlined />
                      Xuất tệp
                    </Button>
                  </span>
                </Col>
                {this.renderCollapse(4, false, true)}
              </Row>
            </Col>
          </Row>
        </Form>
      </Card>
    );
  }
}

export default ProductWarrantyTimesFilter;

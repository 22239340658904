module.exports = {
  navTheme: "light",
  primaryColor: '#13c2c2',
  layout: "topmenu",
  contentWidth: "Fluid",
  fixedHeader: true,
  autoHideHeader: true,
  fixSiderbar: true,
  menu: {
    locale: true
  },
  title: 'CRM Đại Việt',
  pwa: false,
  // Your custom iconfont Symbol script Url
  // eg：//at.alicdn.com/t/font_1039637_btcrd5co4w.js
  // 注意：如果需要图标多色，Iconfont 图标项目里要进行批量去色处理
  // Usage: https://github.com/ant-design/ant-design-pro/pull/3517
  iconfontUrl: '',
  collapse: true,
  language: 'vi-VN',
  settingGlobal:[]
};

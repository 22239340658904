import { apiGet, apiPost, apiPut } from '@/utils/request';

export function getAll(query) {
  return apiGet(`${BASE_API_URL}/services/app/ConvertSession/GetAll`, query);
}

export function get(id) {
  return apiGet(`${BASE_API_URL}/services/app/ConvertSession/GetDetail`, {'id': id});
}

export function create(body) {
  return apiPost(`${BASE_API_URL}/services/app/ConvertSession/Create`, { body });
}

export function update(body) {
  return apiPut(`${BASE_API_URL}/services/app/ConvertSession/Update`, { body });
}

export function getReturnable({type, userId, query}) {
  return apiGet(`${BASE_API_URL}/services/app/${type}/${userId}/inventory/returnable`, query);
}

export function getReturnableSerials({type, userId, query}) {
  return apiGet(`${BASE_API_URL}/services/app/${type}/${userId}/inventory/returnable-serials`, query);
}

export function getConditionType() {
  return apiGet(`${BASE_API_URL}/services/app/ConvertSession/GetConditionTypeForSelect`);
}


import _ from 'lodash';
import * as cashflowAPI from '@/services/cashflow';
import { message } from 'antd';

export default {
  namespace: 'cashflow',

  state: {
    sessions: {},
    cashflowItem: {},
    cashflowType: [],
    item: {},
  },

  effects: {
    *getSessions({ payload }, { call, put }) {
      const { result } = yield call(cashflowAPI.getSessions, payload);

      yield put({
        type: 'setSessions',
        payload: result,
      });
    },
    *get({ payload }, { call, put, select }) {
      const { result } = yield call(cashflowAPI.get, _.get(payload, 'id', 0), payload);
      const resultCurrent = yield select(state => state.cashflow.item);
      if (_.isEmpty(resultCurrent)) {
        yield put({
          type: 'setSessionItem',
          payload: result,
        });
      } else {
        const { item } = result;
        const { sessionName, statusCode, statusName } = resultCurrent;
        yield put({
          type: 'setSessionItem',
          payload: { ...result, sessionName, statusCode, statusName },
        });
      }
    },
    *getCashFlow({ payload }, { call, put }) {
      const { result } = yield call(cashflowAPI.getCashFlow, payload);
      yield put({
        type: 'setCashflowItem',
        payload: result,
      });
    },

    *createSession({ payload }, { call }) {
      return yield call(cashflowAPI.create, payload);
    },
    *create({ payload }, { call }) {
      return yield call(cashflowAPI.createCashFlow, payload);
    },

    *update({ payload }, { call, put }) {
      const { result, success } = yield call(cashflowAPI.update, payload);
      return { result, success };
    },

    *approve({ payload }, { call, put }) {
      const { result, success } = yield call(cashflowAPI.approve, payload);
      if (success) {
        yield put({
          type: 'setSessionItem',
          payload: result,
        });
      }
      return success;
    },
    *getType({ payload }, { call, put }) {
      const { result, success } = yield call(cashflowAPI.getType, payload);
      if (success) {
        yield put({
          type: 'setCashflowType',
          payload: result,
        });
      }
      return success;
    },
    *getDebitCredits({ payload }, { call, put }) {
      const { result } = yield call(cashflowAPI.getDebitCredits, payload);
      yield put({
        type: 'setDebitCredits',
        payload: result,
      });
    },
    *getDebitCredit({ payload }, { call, put }) {
      const { result } = yield call(cashflowAPI.getDebitCredit, payload);
      yield put({
        type: 'setDebitCredit',
        payload: result,
      });
    },
    *deleteDebitCredit({ payload }, { call, put }) {
      const { result, success } = yield call(cashflowAPI.deleteDebitCredit, payload);
      if (success) {
        yield put({
          type: 'setDebitCredit',
          payload: result,
        });
      }
      return success;
    },
    *updateDebitCredit({ payload }, { call, put }) {
      const { result, success } = yield call(cashflowAPI.updateDebitCredit, payload);
      if (success) {
        yield put({
          type: 'setSessionItem',
          payload: result,
        });
      }
      return success;
    },
    *pushCashflowsSession({ payload }, { call, put }) {
      const { result } = yield call(
        cashflowAPI.pushCashflowsSession,
        _.get(payload, 'id', 0),
        payload
      );
      yield put({
        type: 'setSessionItem',
        payload: result,
      });
      if (result != null) {
        yield message.success('Đã lấy giao dịch thành công');
      }
    },
    *getReportDebit({ payload }, { call, put }) {
      const { result } = yield call(cashflowAPI.getReportDebit, payload);
      yield put({
        type: 'setReportDebit',
        payload: result,
      });
    },
    *importCashFlow({ payload }, { call }) {
      return yield call(cashflowAPI.importCashFlow, payload);
    },
    *exportCashFlow({ payload }, { call }) {
      return yield call(cashflowAPI.exportCashFlow, payload);
    },
    *updateDebitNote({ payload }, { call, put }) {
      const { success } = yield call(cashflowAPI.updateDebitNote, payload);
      return success;
    },
    *approveAccessories({ payload }, { call }) {
      const { success } = yield call(cashflowAPI.approveAccessories, payload);
      return success;
    },
    *exportDebtManagement({ payload }, { call }) {
      return yield call(cashflowAPI.exportDebtManagement, payload);
    },
    *exportDebtManagementDetail({ payload }, { call }) {
      return yield call(cashflowAPI.exportDebtManagementDetail, payload);
    },
  },

  reducers: {
    setSessions(state, { payload }) {
      return {
        ...state,
        sessions: payload,
      };
    },
    setSessionItem(state, { payload }) {
      return {
        ...state,
        item: payload,
      };
    },
    setCashflowItem(state, { payload }) {
      return {
        ...state,
        cashflowItem: payload,
      };
    },
    setCashflowType(state, { payload }) {
      return {
        ...state,
        cashflowType: payload,
      };
    },
    setDebitCredits(state, { payload }) {
      return {
        ...state,
        debitCredits: payload,
      };
    },
    setDebitCredit(state, { payload }) {
      return {
        ...state,
        debitCredit: payload,
      };
    },
    setReportDebit(state, { payload }) {
      return {
        ...state,
        reportDebit: payload,
      };
    },
  },
};

import { routerRedux } from 'dva/router';
import { stringify } from 'qs';
import { getFakeCaptcha } from '@/services/api';
import * as authService from '@/services/auth';
import { setAuthority } from '@/utils/authority';
import { getPageQuery } from '@/utils/utils';
import { reloadAuthorized } from '@/utils/Authorized';
import storage from '@/utils/storage';
import _ from 'lodash';

export default {
  namespace: 'login',

  state: {
    status: false,
  },

  effects: {
    *login({ payload }, { call, put }) {
      const { success, result } = yield call(authService.login, payload);
      if (success) {
        yield put({
          type: 'changeLoginStatus',
          payload: {
            status: success || 'error',
            currentAuthority: '',
            result,
          },
        });

        // Login successfully
        const urlParams = new URL(window.location.href);
        const params = getPageQuery();
        let { redirect } = params;
        if (redirect) {
          const redirectUrlParams = new URL(redirect);
          if (redirectUrlParams.origin === urlParams.origin) {
            if (!_.get(result, 'isResetPass', false)) {
              redirect = `/account/changePassword`;
            } else {
              redirect = redirect.substr(urlParams.origin.length);
              if (redirect.includes('changePassword')) {
                redirect = '/call-center';
              }
              if (window.routerBase !== '/') {
                redirect = redirect.replace(window.routerBase, '/');
              }
              if (redirect.match(/^\/.*#/)) {
                redirect = redirect.substr(redirect.indexOf('#') + 1);
              }
            }
          } else {
            redirect = null;
          }
        }
        yield put(routerRedux.replace(redirect || '/'));

        return;
      }
      yield put({
        type: 'changeLoginStatus',
        payload: {
          status: 'error',
        },
      });
    },

    *getCaptcha({ payload }, { call }) {
      yield call(getFakeCaptcha, payload);
    },

    *logout(_, { put }) {
      yield put({
        type: 'changeLoginStatus',
        payload: {
          status: false,
          currentAuthority: 'guest',
        },
      });
      storage.setJwtToken(null);
      reloadAuthorized();
      const { redirect } = getPageQuery();
      // redirect
      if (window.location.pathname !== '/user/login' && !redirect) {
        yield put(
          routerRedux.replace({
            pathname: '/user/login',
            search: stringify({
              redirect: window.location.href,
            }),
          })
        );
      }
    },
  },

  reducers: {
    changeLoginStatus(state, { payload }) {
      if (payload.status && payload.status !== 'error') {
        setAuthority(payload.currentAuthority);
        storage.setJwtToken(payload.result.accessToken);
        storage.setSocketToken(payload.result.encryptedAccessToken);
      }

      return {
        ...state,
        status: payload.status,
        type: 'account',
      };
    },

    saveStatus(state, { payload }) {
      return {
        ...state,
        status: payload.status,
      };
    },
  },
};

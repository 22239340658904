import { apiGet, apiPost, apiPut } from '@/utils/request';

export function getAll(query) {
  return apiGet(`${BASE_API_URL}/services/app/AccessoriesOrderGroup/GetAll`, query);
}

export function get(id) {
  return apiGet(`${BASE_API_URL}/services/app/AccessoriesOrderGroup/GetDetail`, { id });
}

export function searchAccessoriesOrderByGroup(params) {
  return apiGet(
    `${BASE_API_URL}/services/app/AccessoriesOrderGroup/SearchAccessoriesOrderByGroup`,
    params
  );
}

export function create(body) {
  return apiPost(`${BASE_API_URL}/services/app/AccessoriesOrderGroup/Create`, { body });
}

export function update(body) {
  return apiPut(`${BASE_API_URL}/services/app/AccessoriesOrderGroup/Update`, { body });
}

export function cancle(id) {
  return apiPut(`${BASE_API_URL}/services/app/AccessoriesOrderGroup/${id}/cancle`);
}
export function finsish(id) {
  return apiPut(`${BASE_API_URL}/services/app/AccessoriesOrderGroup/${id}/finish`);
}

export function getForSelect(query) {
  return apiGet(`${BASE_API_URL}/services/app/AccessoriesOrderGroup/GetForSelect`, query);
}
export async function getHistoriesOrder(id) {
  return await apiGet(`${BASE_API_URL}/services/app/AccessoriesOrderGroup/${id}/histories`);
}

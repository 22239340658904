import { notification } from 'antd';
import _ from 'lodash';
import * as orderManagementAPI from '../services/orderManagement';

export default {
  namespace: 'orderManagement',

  state: {
    data: [],
    item: {},
    accessoriesOrderByGroup: [],
    visibleModalView: false,
    historyOrder:[]
  },

  effects: {
    *getAll({ payload }, { call, put }) {
      const response = yield call(orderManagementAPI.getAll, payload);
      if (response.success) {
        yield put({
          type: 'saveOrders',
          payload: {
            items: response.result,
          },
        });
      }
    },

    *searchAccessoriesOrderByGroup({ payload }, { call, put }) {
      const {
        success,
        result: { items },
      } = yield call(orderManagementAPI.searchAccessoriesOrderByGroup, payload.params);
      if (success) {
        yield put({
          type: 'saveAccessoriesOrderByGroup',
          payload: {
            items,
          },
        });
        return items;
      }
      return null;
    },

    *getHistoriesOrder({ payload }, { call, put }) {
      const response = yield call(orderManagementAPI.getHistoriesOrder, payload.id);
      if (response.success) {
        yield put({
          type: 'saveHistoriesOrder',
          payload: {
            data: response.result.items,
          },
        });
      }
    },

    *get({ payload }, { call, put }) {
      const response = yield call(orderManagementAPI.get, payload.id);
      if (response.success) {
        const { result } = response;
        const { orders = [], object } = result;
        _.forEach(orders, i => {
          i.objectKey = `${object.code} - ${object.name}`
        })

        yield put({
          type: 'saveItem',
          payload: {
            item: result,
          },
        });

        return result;
      }

      return null;
    },

    *create({ payload }, { call, put }) {
      const response = yield call(orderManagementAPI.create, payload);
      if (response.success) {
        yield put({
          type: 'saveVisibleModalView',
          payload: {
            status: false,
          },
        });

        yield notification.success({
          message: 'Tạo đơn hàng',
          description: 'Đã tạo đơn hàng thành công',
        });
        yield put({
          type: 'saveItem',
          payload: {
            item: response.result,
          },
        });
      }
      return Promise.resolve({success:response.success,result:response.result});
    },

    *update({ payload }, { call, put }) {
      const response = yield call(orderManagementAPI.update, payload);
      if (response.success) {
        yield put({
          type: 'saveVisibleModalView',
          payload: {
            status: false,
          },
        });

        yield put({
          type: 'saveItem',
          payload: {
            item: response.result,
          },
        });

        yield notification.success({
          message: 'Cập nhật đơn hàng',
          description: 'Đã cập nhật đơn hàng thành công',
        });
      }
      return Promise.resolve(response.success);
    },
    *cancle({ payload }, { call, put }) {
      const response = yield call(orderManagementAPI.cancle, payload.id);
      if (response.success) {
        yield put({
          type: 'saveVisibleModalView',
          payload: {
            status: false,
          },
        });
        yield notification.success({
          message: 'Hủy đơn hàng',
          description: 'Hủy đơn hàng thành công',
        });
      }
      return Promise.resolve(response.success);
    },
    *finish({ payload }, { call, put }) {
      const response = yield call(orderManagementAPI.finsish, payload.id);
      if (response.success) {
        yield put({
          type: 'saveVisibleModalView',
          payload: {
            status: false,
          },
        });
        yield notification.success({
          message: 'Hoàn thành đơn hàng',
          description: 'Hoàn thành đơn hàng thành công',
        });
      }
      return Promise.resolve(response.success);
    },

    *changeVisibleModalView({ payload }, { put }) {
      yield put({
        type: 'saveVisibleModalView',
        payload,
      });
    },
  },

  reducers: {
    saveSearchData(state, { payload }) {
      return {
        ...state,
        searchData: {
          ...payload,
        },
      };
    },
    saveHistoriesOrder(state, { payload }) {
      const { data } = payload;
      return {
        ...state,
        historyOrder: data,
      };
    },
    saveOrders(state, { payload }) {
      return {
        ...state,
        data: payload.items,
      };
    },
    saveAccessoriesOrderByGroup(state, { payload }) {
      return {
        ...state,
        accessoriesOrderByGroup: payload.items,
      };
    },
    saveVisibleModalView(state, { payload }) {
      return {
        ...state,
        visibleModalView: payload.status,
      };
    },
    saveItem(state, { payload }) {
      const { item } = payload;
      return {
        ...state,
        item,
      };
    },
  },
};

import _ from 'lodash';
import { notification } from 'antd';
import * as returnOrderAPI from '@/services/returnOrder';
import * as businessAPI from '@/services/business';

export default {
  namespace: 'returnOrder',

  state: {
    searchData: {},
    visibleModal: false,
    modalType: '',
    list: [],
    item: {},
    statusList: [],
    inventoryLines: [],
    conditionTypes: [],
  },

  effects: {
    *fetch({ payload }, { call, put }) {
      const query = Object.assign({});
      _.forEach(payload, (val, key) => {
        if (val) {
          _.set(query, key, val);
        }
      });

      const response = yield call(returnOrderAPI.getAll, query);
      if (response.success) {
        yield put({
          type: 'saveList',
          payload: {
            ...response.result,
          },
        });
      }
    },

    *refreshFetch(_, { put, select }) {
      const searchData = yield select(state => state.returnOrder.searchData);

      yield put({
        type: 'fetch',
        payload: searchData,
      });
    },

    *get({ payload }, { call, put }) {
      const response = yield call(returnOrderAPI.get, payload.id);
      if (response.success) {
        yield put({
          type: 'save',
          payload: {
            ...response,
            visibleModal: true,
          },
        });
      }
    },

    *create({ payload }, { call, put }) {
      const response = yield call(returnOrderAPI.create, payload);
      if (response.success) {
        yield notification.success({
          message: 'Tạo phiếu trả hàng',
          description: 'Đã tạo phiếu trả hàng thành công',
        });

        yield put({
          type: 'refreshFetch',
        });
        return response.success;
      }
    },

    *update({ payload }, { call, put }) {
      const response = yield call(returnOrderAPI.update, payload);
      if (response.success) {
        yield put({
          type: 'save',
          payload: {
            ...response,
            visibleModal: false,
          },
        });

        yield notification.success({
          message: 'Cập nhật phiếu trả hàng',
          description: 'Đã cập nhật phiếu trả hàng thành công',
        });

        yield put({
          type: 'refreshFetch',
        });
      }
    },

    *confirm({ payload }, { call, put }) {
      const response = yield call(returnOrderAPI.confirm, payload);
      if (response.success) {
        yield notification.success({
          message: 'Trả hàng',
          description: 'Đã xác nhận thành công',
        });

        yield put({
          type: 'reload',
          payload: {
            status: false,
            modelType: 'view',
          },
        });
      }
    },

    *reject({ payload }, { call, put }) {
      const response = yield call(returnOrderAPI.reject, payload);
      if (response.success) {
        yield notification.success({
          message: 'Trả hàng',
          description: 'Không xác nhận thành công',
        });

        yield put({
          type: 'reload',
          payload: {
            status: false,
            modelType: 'view',
          },
        });
      }
    },

    *cancel({ payload }, { call, put }) {
      const response = yield call(returnOrderAPI.cancel, payload);
      if (response.success) {
        yield notification.success({
          message: 'Trả hàng',
          description: 'Hủy trả hàng thành công',
        });

        yield put({
          type: 'reload',
          payload: {
            status: false,
            modelType: 'view',
          },
        });
      }
    },

    *received({ payload }, { call, put }) {
      const response = yield call(returnOrderAPI.received, payload);
      if (response.success) {
        yield notification.success({
          message: 'Trả hàng',
          description: 'Đã gửi hàng thành công',
        });

        yield put({
          type: 'reload',
          payload: {
            status: false,
            modelType: 'view',
          },
        });
      }
    },

    *reload({ payload }, { put }) {
      yield put({
        type: 'changeVisibleModal',
        payload: {
          status: payload.status,
          modelType: payload.modelType,
        },
      });

      yield put({
        type: 'refreshFetch',
      });
    },

    *changeVisibleModal({ payload }, { put }) {
      yield put({
        type: 'saveVisibleModal',
        payload: {
          status: payload.status,
          modalType: payload.modalType,
          inventoryLines: [],
        },
      });
    },

    *getStatus({ payload }, { put, select }) {
      const businessData = yield select(state => state.business.businessData);
      const data = _.get(businessData, 'ReturnOrder.Status');
      if (data) {
        yield put({
          type: 'saveStatusList',
          payload: data,
        });
      }
    },

    *getReturnable({ payload }, { call, put }) {
      const response = yield call(returnOrderAPI.getReturnable, payload);
      if (response.success) {
        yield put({
          type: 'saveInventoryLines',
          payload: response.result.items,
        });
      }
    },

    *getInventoryProducts({ payload }, { call, put }) {
      const response = yield call(returnOrderAPI.getInventoryProducts, payload);
      if (response.success) {
        yield put({
          type: 'saveInventoryLines',
          payload: response.result.items,
        });
      }
    },

    *getReturnableSerials({ payload }, { call, put }) {
      const response = yield call(returnOrderAPI.getReturnableSerials, payload);
      if (response.success) {
        return response.result.items;
      }

      return [];
    },

    *getConditionType({ payload }, { call, put }) {
      const response = yield call(returnOrderAPI.getConditionType, payload);
      if (response.success) {
        yield put({
          type: 'saveConditionTypes',
          payload: response.result.items,
        });
      }
    },
  },

  reducers: {
    saveSearchData(state, { payload }) {
      return {
        ...state,
        searchData: {
          ...payload,
        },
      };
    },
    saveVisibleModal(state, { payload }) {
      return {
        ...state,
        visibleModal: payload.status,
        modalType: payload.modalType,
      };
    },

    saveList(state, { payload }) {
      return {
        ...state,
        list: payload,
      };
    },

    save(state, { payload }) {
      const warrantyArea = payload.result.coverages;
      return {
        ...state,
        item: payload.result,
        visibleModal: payload.visibleModal,
        warrantyArea,
      };
    },

    saveStatusList(state, { payload }) {
      return {
        ...state,
        statusList: payload,
      };
    },

    saveInventoryLines(state, { payload }) {
      return {
        ...state,
        inventoryLines: payload,
      };
    },

    saveConditionTypes(state, { payload }) {
      return {
        ...state,
        conditionTypes: payload,
      };
    },
  },
};

import { notification } from 'antd';
import * as paymentAPI from '../services/payment';

export default {
  namespace: 'payment',

  state: {
    items: [],
    terms: [],
    shippingInfo: {},
    defaultShippingInfo: {},
    objects: {},
    itemsShippingInfo: [],
  },

  effects: {
    *getAll({ payload }, { call, put }) {
      const response = yield call(paymentAPI.getAll, payload);
      if (response.success) {
        const { items } = response.result;
        yield put({
          type: 'saveAll',
          payload: {
            items,
          },
        });
      }
    },

    *getPaymentTerms({ payload }, { call, put }) {
      const response = yield call(paymentAPI.getPaymentTerms, payload);
      if (response.success) {
        const { items } = response.result;
        yield put({
          type: 'savePaymentTerms',
          payload: {
            items,
          },
        });
      }
    },

    *getDefaultShippingInfo({ payload }, { call, put }) {
      const { id, type } = payload;
      const query = {
        objectId: id,
        objectType: type,
      };
      const response = yield call(paymentAPI.getDefaultShippingInfo, query);
      if (response.success) {
        const data = response.result;
        data.object = payload;
        yield put({
          type: 'saveDefaultShippingInfo',
          payload: {
            data: response.result,
          },
        });
        return response.result;
      }
      return {}
    },

    *updateDefaultShippingInfo({ payload }, { call, put }) {
      const response = yield call(paymentAPI.updateDefaultShippingInfo, payload);
      if (response.success) {
        yield put({
          type: 'saveDefaultShippingInfo',
          payload: {
            data: { id: payload.shippingInfoId },
          },
        });
        yield notification.success({
          message: 'Cập nhật địa chỉ giao hàng mặc định',
          description: 'Cập nhật địa chỉ giao hàng mặc định thành công',
        });
      } else {
        yield notification.error({
          message: 'Cập nhật địa chỉ giao hàng mặc định',
          description: 'Cập nhật địa chỉ giao hàng mặc định thất bại',
        });
      }
    },

    *getAllObjects({ payload }, { call, put }) {
      const response = yield call(paymentAPI.getAllObjects, payload);
      if (response.success) {
        yield put({
          type: 'saveAllObjects',
          payload: {
            data: response.result,
          },
        });
      }
    },

    *createShippingInfo({ payload }, { call, put }) {
      const response = yield call(paymentAPI.createShippingInfo, payload);
      if (response.success) {
        yield notification.success({
          message: 'Tạo thông tin giao hàng',
          description: 'Đã tạo thông tin giao hàng thành công',
        });

        return true;
      }

      return false;
    },

    *getShippingInfoes({ payload }, { call, put }) {
      payload.MaxResultCount = 100;
      payload.CurrentPage = 1;
      const response = yield call(paymentAPI.getShippingInfoes, payload);
      if (response.success) {
        const { items } = response.result;
        yield put({
          type: 'saveShippingInfoes',
          payload: {
            items,
          },
        });
        const defaultShippingInfo = _.find(items, 'isDefault');
        yield put({
          type: 'saveDefaultShippingInfo',
          payload: {
            data: { id: _.get(defaultShippingInfo, 'id') },
          },
        });
      }
    },
  },

  reducers: {
    saveAll(state, { payload }) {
      return {
        ...state,
        items: payload.items,
      };
    },
    savePaymentTerms(state, { payload }) {
      return {
        ...state,
        terms: payload.items,
      };
    },
    saveShippingInfo(state, { payload }) {
      return {
        ...state,
        shippingInfo: payload.data,
      };
    },
    saveDefaultShippingInfo(state, { payload }) {
      return {
        ...state,
        defaultShippingInfo: payload.data,
      };
    },
    saveAllObjects(state, { payload }) {
      return {
        ...state,
        objects: payload.data,
      };
    },
    saveShippingInfoes(state, { payload }) {
      return {
        ...state,
        itemsShippingInfo: payload.items,
      };
    },
  },
};

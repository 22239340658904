import React, { Fragment } from 'react';
import { connect } from 'dva';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Tooltip, Tag } from 'antd';
import moment from '@/utils/moment';
import { formatMessage } from 'umi-plugin-react/locale';
import ManageTable from '@/components/ManageTable';
import SerialHistoryFilter from './filter';
import { renderData } from '@/utils/table';
import ListPage from '@/components/ListPage';
import SerialModal from './SerialModal';
import ProductRelation from '../../CallCenter/Form/WarrantyHistory/ProductRelation';

@connect(({ report, loading, business }) => ({
  report,
  loading,
  business,
}))
@Form.create()
class ProductWarrantyTimes extends ListPage {
  constructor(props) {
    super(props);

    this.columns = [
      {
        title: 'Mã cào sản phẩm',
        dataIndex: 'hiddenSerialNo',
        key: 'hiddenSerialNo',
        className: 'list-header',
        width: '10%',
      },
      {
        title: 'Mã thùng',
        dataIndex: 'serialNo',
        key: 'serialNo',
        className: 'list-header',
        width: '70px',
      },
      {
        title: 'Mã sản phẩm',
        dataIndex: 'sku',
        key: 'sku',
        className: 'list-header',
        width: '10%',
      },
      {
        title: 'Tên sản phẩm',
        dataIndex: 'productName',
        key: 'productName',
        className: 'list-header',
        width: '10%',
      },
      {
        title: 'Ngành hàng',
        dataIndex: 'categoryName',
        key: 'categoryName',
        className: 'list-header',
        width: '70px',
      },
      {
        title: 'Nhãn hiệu',
        dataIndex: 'brandName',
        key: 'brandName',
        className: 'list-header',
        width: '8%',
      },
      {
        title: 'Số lần bảo hành',
        dataIndex: 'numOfWR',
        key: 'numOfWR',
        className: 'list-header',
        width: '100px',
        render: (text, record) => {
          return record.numOfWR > 0 ? (
            <Tooltip title="Xem chi tiết thông tin bảo hành" placement="top" arrowPointAtCenter>
              <Tag
                color="blue"
                onClick={() => this.handleModalVisibleBH(record, true)}
                style={{ cursor: 'pointer' }}
              >
                {record.numOfWR}
              </Tag>
            </Tooltip>
          ) : (
            record.numOfWR
          );
        },
      },
    ];

    this.state = {
      ...this.state,
      visibleSerialModal: false,
      data: [],
    };
  }

  handleTableChange = params => {
    this.tableChange({
      params,
      type: 'report/getProductWarrantyTimes',
    });
  };

  handleSearch = params => {
    this.tableSearch({
      params,
      type: 'report/getProductWarrantyTimes',
    });
  };

  handleModalVisibleBH = (itemCurr, open) => {
    const { dispatch } = this.props;
    if (open) {
      dispatch({
        type: 'warrantyRequest/searchWarrantyLineBySerial',
        payload: {
          SerialNo: _.get(itemCurr, 'serialNo', null),
          FilterWithHiddenSerialNo: true,
          HiddenSerialNo: _.get(itemCurr, 'hiddenSerialNo', null),
        },
      });
    }
    this.setState({
      isShowBH: open,
      item: itemCurr,
    });
  };

  render() {
    const { loading, form, report } = this.props;
    const { productWarrantyTimes } = report;
    const loadingSpin = loading.effects['report/getProductWarrantyTimes'];
    const {
      paramsSearch,
      productName,
      productSku,
      visibleSerialModal,
      item,
      isShowBH,
    } = this.state;
    const { CurrentPage, MaxResultCount } = paramsSearch;
    const serialRecord = {
      serials: [],
      name: productName,
      sku: productSku,
    };

    const datatable = renderData({
      data: report.productWarrantyTimes,
      CurrentPage,
      MaxResultCount: MaxResultCount,
    });

    const openSerialModal = async (visible, record) => {
      if (!_.isEmpty(record)) {
        const {
          productId,
          conditionTypeCode,
          productName,
          productSku,
          invObjectType,
          invObjectId,
        } = record;
        const { dispatch } = this.props;
        await dispatch({
          type: 'report/getInvOnhandSerials',
          payload: {
            objectId: invObjectId,
            controller: invObjectType === 'EMPLOYEE' ? 'USER' : invObjectType,
            productId,
            query: {
              conditionTypeCode,
            },
          },
        }).then(() => {
          this.setState({
            visibleSerialModal: visible,
            productName,
            sku,
          });
        });
      }
      console.log('record', record);

      this.setState({
        visibleSerialModal: visible,
      });
    };

    return (
      <Fragment>
        <ManageTable
          rowKey="key"
          title={formatMessage({ id: 'menu.report.product_warranty_times' })}
          columns={this.columns}
          data={datatable}
          loading={loadingSpin}
          renderFilterForm={<SerialHistoryFilter form={form} handleSearch={this.handleSearch} />}
          tableChange={this.handleTableChange}
          tableWidth={1500}
          bordered
          buttonAddNew={null}
        />
        <SerialModal
          visible={visibleSerialModal}
          serialRecord={serialRecord}
          handleVisibleModal={openSerialModal}
        />
        <ProductRelation
          data={item}
          isShow={isShowBH}
          handleModalVisibleBH={this.handleModalVisibleBH}
        />
      </Fragment>
    );
  }
}

export default ProductWarrantyTimes;

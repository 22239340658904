import { notification } from 'antd';
import * as callCenterAPI from '@/services/callCenter';

export default {
  namespace: 'warrantyHistory',

  state: {
    visibleModal: false,
  },

  effects: {
    *update({ payload }, { call, put, select }) {
      const response = yield call(callCenterAPI.updateExternalWarranty, payload);
      if (response.success) {
        yield notification.success({
          message: 'Cập nhật bảo hành',
          description: 'Đã cập nhật bảo hành thành công',
        });
      }

      yield put({
        type: 'saveVisibleModal',
        payload: {
          status: false,
        },
      });
    },

    *changeVisibleModal({ payload }, { call, put }) {
      yield put({
        type: 'saveVisibleModal',
        payload,
      });

      yield put({
        type: 'address/saveDataAddress',
        payload: {
          address: null,
        },
      });
    },
  },

  reducers: {
    saveVisibleModal(state, { payload }) {
      return {
        ...state,
        visibleModal: payload.status,
      };
    },
  },
};

export default {
  'permission.Pages': 'Nhóm quyền',
  'permission.Pages.CallCenters': 'Quản lý tiếp nhận',
  'permission.Pages.CallCenters.View': 'Danh sách tiếp nhận',
  'permission.Pages.CallCenters.Create': 'Thêm mới',
  'permission.Pages.CallCenters.Edit': 'sửa phiếu tiếp nhận',
  'permission.Pages.CallCenters.Close': 'Đóng phiếu tiếp nhận',
  'permission.Pages.CallCenters.Confirm': 'Xác nhận thông tin tiếp nhận',
  'permission.Pages.CallCenters.ObjectConfirm': 'Chuyển đối tượng xác nhận',
  'permission.Pages.CallCenters.InfoConfirm': 'Cần xác nhận thông tin phiếu tiếp nhận',

  'permission.Pages.CallActivities': 'Quản lý cuộc gọi',

  'permission.Pages.Users': 'Quản lý nhân viên',
  'permission.Pages.Users.View': 'Danh sách nhân viên',
  'permission.Pages.Users.Create': 'Thêm nhân viên',
  'permission.Pages.Users.Edit': 'Sửa nhân viên',

  'permission.Pages.Customers': 'Quản lý khách hàng',
  'permission.Pages.Customers.View': 'Danh sách khách hàng',
  'permission.Pages.Customers.Create': 'Thêm khách hàng',
  'permission.Pages.Customers.Edit': 'Sửa khách hàng',
  'permission.Pages.Customers.EditCode': 'Sửa Mã khách hàng',
  'permission.Pages.Customers.EditMainPhone': 'Sửa SDT khách hàng',

  'permission.Pages.Stations': 'Quản lý trạm bảo hành',
  'permission.Pages.Stations.View': 'Danh sách trạm bảo hành',
  'permission.Pages.Stations.Create': 'Thêm trạm bảo hành',
  'permission.Pages.Stations.Edit': 'Sửa trạm bảo hành',

  'permission.Pages.WarrantyRequests': 'Quản lý ca',
  'permission.Pages.WarrantyRequests.View': 'Danh sách ca',
  'permission.Pages.WarrantyRequests.Create': 'Tạo ca',
  'permission.Pages.WarrantyRequests.Edit': 'Tiếp nhận',
  'permission.Pages.WarrantyRequests.Analytic': 'Phân tích lỗi',
  'permission.Pages.WarrantyRequests.Plan': 'Phân lịch',
  'permission.Pages.WarrantyRequests.Order': 'Đặt linh kiện',
  'permission.Pages.WarrantyRequests.Process': 'Thực hiện',
  'permission.Pages.WarrantyRequests.Process.View': 'Thực hiện dịch vụ',
  'permission.Pages.WarrantyRequests.Process.WebCheckout': 'Thực hiện thay kỹ thuật',
  'permission.Pages.WarrantyRequests.ReCheck': 'Chăm sóc lại',
  'permission.Pages.WarrantyRequests.WarrantyCheck': 'Thông tin bảo hành',
  'permission.Pages.WarrantyRequests.Revert': 'Chuyển bước',
  'permission.Pages.WarrantyRequests.Close': 'Đóng ca',
  'permission.Pages.WarrantyRequests.CloseApproval': 'Xác nhận đóng ca',
  'permission.Pages.WarrantyRequests.Comments': 'Ghi chú xử lý',
  'permission.Pages.WarrantyRequests.Comments_Create': 'Thêm ghi chú xử lý',
  'permission.Pages.WarrantyRequests.Comments_Edit': 'Sửa ghi chú xử lý',
  'permission.Pages.WarrantyRequests.Comments_Delete': 'Xóa ghi chú xử lý',
  'permission.Pages.WarrantyRequests.UpdateReNew': 'Cập nhật ngày trễ ca BH',
  'permission.Pages.WarrantyRequests.RevertProduct': 'Thu hồi linh kiện',

  'permission.Pages.ReturnOrders': 'Quản lý trả hàng',
  'permission.Pages.ReturnOrders.View': 'Danh sách trả hàng',
  'permission.Pages.ReturnOrders.Create': 'thêm trả hàng',
  'permission.Pages.ReturnOrders.Edit': 'sửa trả hàng',
  'permission.Pages.ReturnOrders.Approval': 'Xét duyệt trả hàng',
  'permission.Pages.ReturnOrders.Received': 'Gửi hàng',

  'permission.Pages.AccessoriesOrders': 'Quản lý phiếu linh kiện',
  'permission.Pages.AccessoriesOrders.View': 'Danh sách phiếu linh kiện',
  'permission.Pages.AccessoriesOrders.Create': 'Thêm phiếu linh kiện',
  'permission.Pages.AccessoriesOrders.Edit': 'Sửa phiếu linh kiện',
  'permission.Pages.AccessoriesOrders.Approval': 'Xét duyệt phiếu linh kiện',
  'permission.Pages.AccessoriesOrders.Revert_To_Receiving': 'Chuyển trạng thái sang đang nhận',
  'permission.Pages.AccessoriesOrders.Cancel': 'Hủy phiếu linh kiện',
  'permission.Pages.AccessoriesOrders.Receive': 'Nhận hàng',
  'permission.Pages.AccessoriesOrders.ReceiveCs': 'Nhận dùm linh kiện',

  'permission.Pages.TransferOrders': 'Quản lý phiếu mượn linh kiện',
  'permission.Pages.TransferOrders.View': 'DS phiếu mượn linh kiện',
  'permission.Pages.TransferOrders.Create': 'Mượn linh kiện',
  'permission.Pages.TransferOrders.Confirm': 'Xác nhận mượn linh kiện',

  'permission.Pages.AccessoriesOrderGroups': 'Quản lý đơn hàng',
  'permission.Pages.AccessoriesOrderGroups.Create': 'Thêm đơn hàng',
  'permission.Pages.AccessoriesOrderGroups.Edit': 'Sửa đơn hàng',
  'permission.Pages.AccessoriesOrderGroups.View': 'Danh sách đơn hàng',

  'permission.Pages.CallManagements': 'Quản lý cuộc gọi',

  'permission.Pages.Products': 'Quản lý sản phẩm',
  'permission.Pages.Products.Create': 'Thêm sản phẩm',
  'permission.Pages.Products.Edit': 'Sửa sản phẩm',
  'permission.Pages.Products.View': 'Danh sách sản phẩm',

  'permission.Pages.Reports': 'Báo cáo',
  'permission.Pages.Reports.View': 'Báo cáo',
  'permission.Pages.Reports.Accessories_Order': 'Đặt linh kiện',
  'permission.Pages.Reports.Customer': 'Danh sách khách hàng',
  'permission.Pages.Reports.Customer_Care': 'Tổng xử lý lịch',
  'permission.Pages.Reports.PerformServiceSummary': 'Tổng hợp thực hiện dịch vụ',
  'permission.Pages.Reports.Inventories': 'Báo cáo tồn kho',
  'permission.Pages.Reports.Inventory_Statement_By_Product': 'Sao kê tồn kho linh kiện',
  'permission.Pages.Reports.Serial_History': 'Lịch sử serial',
  'permission.Pages.Reports.Tech_Revenue': 'Doanh số kỹ thuật',
  'permission.Pages.Reports.Warranty_Business_Step_Stats': 'Thống kê xử lý CA',
  'permission.Pages.Reports.WarrantyRequest_History': 'Tiếp nhận CA',
  'permission.Pages.Reports.Warranty_Process_Assignee': 'Ca bảo hành theo đối tượng',
  'permission.Pages.Reports.Inventory_WaitingOut': 'Linh kiện chờ xuất',
  'permission.Pages.Reports.Accessories_Order_Products': 'LK trong phiếu đặt LK',
  'permission.Pages.Reports.Accessories_NoReturn': 'LK Không Thu Hồi',
  'permission.Pages.Reports.RevenueOfSaleAccessories': 'Thông tin tính doanh số linh kiện',
  'permission.Pages.Reports.Booking_Accessoriess': 'Đặt Linh Kiện',
  'permission.Pages.Reports.Working_Diary': 'Nhật Ký Công Trình',
  'permission.Pages.Reports.Inventory_Inbound_OutBound': 'Xuất Nhập Tồn',
  'permission.Pages.Reports.Inventories_DV': 'Tồn kho Đại Việt',
  'permission.Pages.Reports.Activity_Tracking': 'Giám sát',
  'permission.Pages.Reports.Activity_Visit': 'Nhật ký công việc',
  'permission.Pages.Reports.TimeSheet_Histories': 'Lịch sử chấm công',
  'permission.Pages.Reports.SummaryReceivable': 'Tổng hợp phải thu theo tháng',
  'permission.Pages.Reports.ReceivableMonthly': 'Công nợ theo KT trong tháng',
  'permission.Pages.Reports.Debit': 'Công nợ - Phải chi',
  'permission.Pages.Reports.ReportStationRevenueDetail': 'Chi tiết doanh số TBH',
  'permission.Pages.Reports.ReportStationRewardPunishment': 'Chi tiết thưởng phạt TBH',
  'permission.Pages.Reports.ReportStationSummaryRewardPunishment': 'Tổng hợp thưởng phạt TBH',
  'permission.Pages.Reports.ProductWarrantyTimes': 'Thống kê số lần bảo hành của sản phẩm',
  'permission.Pages.Reports.Return_Order': 'Trả hàng',


  'permission.Pages.Roles': 'Quản lý nhóm quyền',
  'permission.Pages.Roles.View': 'Danh sách nhóm quyền',
  'permission.Pages.Roles.Create': 'Thêm nhóm quyền',
  'permission.Pages.Roles.Edit': 'Sửa nhóm quyền',
  'permission.Pages.Roles.Delete': 'Xóa nhóm quyền',
  'permission.Pages.Warranty_Activation': 'Tra Cứu Bảo Hành',

  'permission.Pages.EmployeeProvince': 'Quản lý cấu hình Nhân viên - Tỉnh thành',
  'permission.Pages.EmployeeProvince.View': 'Danh sách cấu hình Nhân viên - Tỉnh thành',
  'permission.Pages.EmployeeProvince.Create': 'Thêm cấu hình Nhân viên - Tỉnh thành',
  'permission.Pages.EmployeeProvince.Edit': 'Sửa cấu hình Nhân viên - Tỉnh thành',
  'permission.Pages.EmployeeProvince.Delete': 'Xóa cấu hình Nhân viên - Tỉnh thành',

  'permission.Pages.Configurations': 'Hệ thống',
  'permission.Pages.Configurations.DayOffConfiguration': 'Lịch làm việc',
  'permission.Pages.Configurations.DayOffConfiguration.View': 'Danh sách lịch làm việc',
  'permission.Pages.Configurations.DayOffConfiguration.Create': 'Thêm mới lịch làm việc',

  'permission.Pages.Logs': 'Lịch sử (IT Admin only)',

  'permission.Pages.DiscountRate': 'Chiết khấu',

  'permission.Pages.Supviser': 'Giám Sát',
  'permission.Pages.TechAccessoriesReturn': 'ĐHLK Kỹ Thuật',

  'permission.Pages.CashFlows.DebitCredit': 'Chứng từ',
  'permission.Pages.CashFlows.DebitCredit.Edit': 'Sửa chứng từ',
  'permission.Pages.CashFlows.DebitCredit.Search': 'Tìm kiếm chứng từ',
  'permission.Pages.CashFlows.DebitCredit.View': 'Xem chứng từ',
  'permission.Pages.CashFlows.DebitCredit.Add': 'Thêm chứng từ',
  'permission.Pages.CashFlows.DebitCredit.Delete': 'Xóa chứng từ',
  'permission.Pages.CashFlows':'Thu/Chi',
  'permission.Pages.CashFlows.CreateSession':'Tạo đợt duệt',
  'permission.Pages.CashFlows.View':'Xem Thu/Chi',
  'permission.Pages.CashFlows.UpdateSession':'Cập nhật đợt duyệt',
  'permission.Pages.CashFlows.ApproveSession':'Duyệt',
  'permission.Pages.CashFlows.AccessoriesDebt':' Quản lý công nợ linh kiện  ',
  'permission.Pages.CashFlows.AccessoriesDebtDetail':'Quản lý chi tiết công nợ linh kiện',
  'permission.Pages.CashFlows.AccessoriesDebtDetail_Update':' Cập nhật chi tiết công nợ linh kiện',
  'permission.Pages.Saleorders': 'Đơn hàng TT bán',
  'permission.Pages.Reports.ReportStationRevenueSummary': 'Tổng hợp doanh số TBH',
  'permission.Pages.Reports.ReportTechRevenueSummary': 'Tổng hợp doanh số kỹ thuật',
  'permission.Pages.ConfigLimits': 'Quản lý cấu hình hạn mức công nợ',
  'permission.Pages.RequireSignature': 'Cấu hình',
  'permission.Pages.ConfigLimits.Create': 'Thêm cấu hình hạn mức công nợ',
  'permission.Pages.ConfigLimits.Edit': 'Sửa cấu hình hạn mức công nợ',
  'permission.Pages.ConfigLimits.View': 'Quản lý cấu hình hạn mức công nợ',
  'permission.Pages.ConfigLimits.Delete': 'Xóa cấu hình hạn mức công nợ',

  'permission.Pages.ConvertSessions': 'Quản lý chuyển đổi linh kiện',
  'permission.Pages.ConvertSessions.View': 'Quản lý chuyển đổi linh kiện',
  'permission.Pages.ConvertSessions.Create': 'Thêm chuyển đổi linh kiện',

  'permission.Pages.DiscountRates': 'Quản lý chiết khấu',
  'permission.Pages.DiscountRates.View': 'Quản lý chiết khấu',
  'permission.Pages.DiscountRates.Create': 'Thêm chiết khấu',
  'permission.Pages.WarrantyRequests.Process.UpdateAction':'Cập nhật thực hiện',

  'permission.Pages.AccessoriesOrders.AO_TakenBack':'Linh kiện không thu hồi xác',
  'permission.Pages.AccessoriesOrders.AO_TakenBack.Confirm_AO_TakenBack':'Xác nhận Linh kiện không thu hồi xác',
  'permission.Pages.AccessoriesOrders.AO_TakenBack.View':'Xem Linh kiện không thu hồi xác',
  'permission.Pages.AccessoriesOrders.AO_TakenBack.Approve_AO_TakenBack':'Duyệt Linh kiện không thu hồi xác',

  'permission.Pages.Mobile':'Phân quyền Mobile',
  'permission.Pages.Mobile.Reports_Inventory_Statement_By_Product':'Sao kê tồn kho linh kiện',
  'permission.Pages.Mobile.Reports_Inventories':'Báo cáo tồn kho',
  'permission.Pages.Mobile.WarrantyRequests':'Quản lý ca',
  'permission.Pages.Mobile.WarrantyRequests.Process':'Thực hiện',
  'permission.Pages.Mobile.WarrantyRequests.WarrantyCheck':'Thông tin bảo hành',
  'permission.Pages.Mobile.WarrantyRequests.Process_UpdateAction':'Cập nhật thực hiện',
  'permission.Pages.Mobile.AccessoriesOrders':'Quản lý phiếu linh kiện',
  'permission.Pages.Mobile.AccessoriesOrders.Receive':'Nhận hàng',
  'permission.Pages.Mobile.ReturnOrders':'Quản lý trả hàng',
  'permission.Pages.Mobile.Warranty_Activation':'Tra Cứu Bảo Hành',
  'permission.Pages.Mobile.ReturnOrders.Create':'Thêm trả hàng',
  'permission.Pages.Mobile.ReturnOrders.Approval':'Xét duyệt trả hàng',
  'permission.Pages.Mobile.ReturnOrders.Received':'Gửi hàng',
  'permission.Pages.Mobile.ProductsPricing':'Giá linh kiện',
};

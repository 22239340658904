import moment from 'moment';

const momentLocal = {
  formatDateTime(dateTime, format) {
    return moment
      .utc(dateTime)
      .local()
      .format(format);
  },

  currentDate(format) {
    return moment
      .utc()
      .local()
      .format(format);
  },

  moment(dateTime, format) {
    return moment.utc(dateTime, format).local();
  },

  substract({ number, type }, format = null) {
    const momentObj = this.moment().subtract(number, type);

    return format ? momentObj.format(format) : momentObj;
  },
  instance: moment
};
export default momentLocal;

import React from 'react';
import {
  Router as DefaultRouter,
  Route,
  Switch,
  StaticRouter,
} from 'react-router-dom';
import dynamic from 'umi/dynamic';
import renderRoutes from 'umi/lib/renderRoutes';
import history from '@@/history';
import RendererWrapper0 from '/Users/angelit/Work/DaiViet/CRM/Web/src/pages/.umi-production/LocaleWrapper.jsx';
import _dvaDynamic from 'dva/dynamic';

const Router = require('dva/router').routerRedux.ConnectedRouter;

const routes = [
  {
    path: '/user',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "layouts__UserLayout" */ '../../layouts/UserLayout'),
          LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/UserLayout').default,
    routes: [
      {
        path: '/user',
        redirect: '/user/login',
        exact: true,
      },
      {
        path: '/user/login',
        name: 'login',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__User__models__register.js' */ '/Users/angelit/Work/DaiViet/CRM/Web/src/pages/User/models/register.js').then(
                  m => {
                    return { namespace: 'register', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__User__Login" */ '../User/Login'),
              LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                .default,
            })
          : require('../User/Login').default,
        exact: true,
      },
      {
        path: '/user/register',
        name: 'register',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__User__models__register.js' */ '/Users/angelit/Work/DaiViet/CRM/Web/src/pages/User/models/register.js').then(
                  m => {
                    return { namespace: 'register', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__User__Register" */ '../User/Register'),
              LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                .default,
            })
          : require('../User/Register').default,
        exact: true,
      },
      {
        path: '/user/register-result',
        name: 'register.result',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__User__models__register.js' */ '/Users/angelit/Work/DaiViet/CRM/Web/src/pages/User/models/register.js').then(
                  m => {
                    return { namespace: 'register', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__User__RegisterResult" */ '../User/RegisterResult'),
              LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                .default,
            })
          : require('../User/RegisterResult').default,
        exact: true,
      },
      {
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__404" */ '../404'),
              LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                .default,
            })
          : require('../404').default,
        exact: true,
      },
    ],
  },
  {
    path: '/',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "layouts__BasicLayout" */ '../../layouts/BasicLayout'),
          LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/BasicLayout').default,
    Routes: [require('../Authorized').default],
    routes: [
      {
        path: '/category',
        name: 'category',
        icon: 'menu',
        authority: [
          'Pages.Users',
          'Pages.EmployeeProvince',
          'Pages.Customers',
          'Pages.Stations',
          'Pages.Roles',
          'Pages.Products',
        ],
        routes: [
          {
            name: 'employees',
            icon: 'user',
            path: '/category/employees',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Employees__EmployeesList" */ '../Employees/EmployeesList'),
                  LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                    .default,
                })
              : require('../Employees/EmployeesList').default,
            authority: ['Pages.Users'],
            exact: true,
          },
          {
            name: 'employeeProvince',
            icon: 'team',
            path: '/category/employeeProvince',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__EmployeeProvince__models__customerRelation.js' */ '/Users/angelit/Work/DaiViet/CRM/Web/src/pages/EmployeeProvince/models/customerRelation.js').then(
                      m => {
                        return { namespace: 'customerRelation', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__EmployeeProvince__List" */ '../EmployeeProvince/List'),
                  LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                    .default,
                })
              : require('../EmployeeProvince/List').default,
            authority: ['Pages.EmployeeProvince'],
            exact: true,
          },
          {
            name: 'customer',
            icon: 'team',
            path: '/category/customer',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__Customer__models__customerRelation.js' */ '/Users/angelit/Work/DaiViet/CRM/Web/src/pages/Customer/models/customerRelation.js').then(
                      m => {
                        return { namespace: 'customerRelation', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__Customer__CustomerList" */ '../Customer/CustomerList'),
                  LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                    .default,
                })
              : require('../Customer/CustomerList').default,
            authority: ['Pages.Customers'],
            exact: true,
          },
          {
            name: 'warranty_station',
            icon: 'home',
            path: '/category/warranty-station',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__WarrantyStation__models__clientRelation.js' */ '/Users/angelit/Work/DaiViet/CRM/Web/src/pages/WarrantyStation/models/clientRelation.js').then(
                      m => {
                        return { namespace: 'clientRelation', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__WarrantyStation__WarrantyStationList" */ '../WarrantyStation/WarrantyStationList'),
                  LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                    .default,
                })
              : require('../WarrantyStation/WarrantyStationList').default,
            authority: ['Pages.Stations'],
            exact: true,
          },
          {
            name: 'product',
            icon: 'skin',
            path: '/category/product',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Product__ProductList" */ '../Product/ProductList'),
                  LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                    .default,
                })
              : require('../Product/ProductList').default,
            authority: ['Pages.Products'],
            exact: true,
          },
          {
            name: 'role',
            icon: 'safety-certificate',
            path: '/category/role',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Role__RoleList" */ '../Role/RoleList'),
                  LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                    .default,
                })
              : require('../Role/RoleList').default,
            authority: ['Pages.Roles'],
            exact: true,
          },
          {
            name: 'discount-rate',
            icon: 'percentage',
            path: '/category/discount-rate',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DiscountRate__DiscountRateList" */ '../DiscountRate/DiscountRateList'),
                  LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                    .default,
                })
              : require('../DiscountRate/DiscountRateList').default,
            authority: ['Pages.DiscountRates'],
            exact: true,
          },
          {
            name: 'dayoff-config',
            icon: 'calendar',
            path: '/category/dayoff-config',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DayOff__Configuration" */ '../DayOff/Configuration'),
                  LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                    .default,
                })
              : require('../DayOff/Configuration').default,
            authority: ['Pages.Configurations.DayOffConfiguration'],
            exact: true,
          },
          {
            name: 'config-signature',
            icon: 'shopping',
            path: '/category/config-signature',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__ConfigSignature__ConfigSignature" */ '../ConfigSignature/ConfigSignature'),
                  LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                    .default,
                })
              : require('../ConfigSignature/ConfigSignature').default,
            authority: ['Pages.RequireSignature'],
            exact: true,
          },
        ],
      },
      {
        name: 'call',
        icon: 'customer-service',
        path: '/call-center',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__CallCenter__CallCenterList" */ '../CallCenter/CallCenterList'),
              LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                .default,
            })
          : require('../CallCenter/CallCenterList').default,
        authority: ['Pages.CallCenters'],
        exact: true,
      },
      {
        name: 'warranty_request',
        icon: 'diff',
        path: '/warranty-request',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__WarrantyRequest__WarrantyRequestList" */ '../WarrantyRequest/WarrantyRequestList'),
              LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                .default,
            })
          : require('../WarrantyRequest/WarrantyRequestList').default,
        authority: ['Pages.WarrantyRequests'],
        exact: true,
      },
      {
        name: 'warranty_request',
        icon: 'diff',
        path: '/warranty-request/:warrantyRqId',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__WarrantyRequest__WarrantyRequestList" */ '../WarrantyRequest/WarrantyRequestList'),
              LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                .default,
            })
          : require('../WarrantyRequest/WarrantyRequestList').default,
        authority: ['Pages.WarrantyRequests'],
        hideInMenu: true,
        exact: true,
      },
      {
        name: 'call_management',
        icon: 'phone',
        path: '/call-management',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__CallManagement__CallManagementList" */ '../CallManagement/CallManagementList'),
              LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                .default,
            })
          : require('../CallManagement/CallManagementList').default,
        authority: ['Pages.CallActivities'],
        exact: true,
      },
      {
        name: 'order_management',
        icon: 'shopping-cart',
        path: '/order-management',
        authority: [
          'Pages.AccessoriesOrders',
          'Pages.AccessoriesOrderGroups',
          'Pages.TechAccessoriesReturn',
        ],
        routes: [
          {
            name: 'accessories_order',
            icon: 'shopping-cart',
            path: '/order-management/accessories-order',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__OrderAccessories__OrderAccessoriesList" */ '../OrderAccessories/OrderAccessoriesList'),
                  LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                    .default,
                })
              : require('../OrderAccessories/OrderAccessoriesList').default,
            authority: ['Pages.AccessoriesOrders'],
            exact: true,
          },
          {
            name: 'orders',
            icon: 'shopping-cart',
            path: '/order-management/orders',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__OrderManagement__OrderManagementList" */ '../OrderManagement/OrderManagementList'),
                  LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                    .default,
                })
              : require('../OrderManagement/OrderManagementList').default,
            authority: ['Pages.AccessoriesOrderGroups'],
            exact: true,
          },
          {
            name: 'return_order',
            icon: 'shopping',
            path: '/order-management/return-order',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__ReturnOrder__ReturnOrderList" */ '../ReturnOrder/ReturnOrderList'),
                  LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                    .default,
                })
              : require('../ReturnOrder/ReturnOrderList').default,
            authority: ['Pages.ReturnOrders'],
            exact: true,
          },
          {
            name: 'conver_session',
            icon: 'shopping',
            path: '/order-management/conver-session',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__ConverSession__ConverSessionList" */ '../ConverSession/ConverSessionList'),
                  LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                    .default,
                })
              : require('../ConverSession/ConverSessionList').default,
            authority: ['Pages.ConvertSessions'],
            exact: true,
          },
          {
            name: 'configlimit',
            icon: 'shopping',
            path: '/order-management/config-limit',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__ConfigLimit__ConfigLimitList" */ '../ConfigLimit/ConfigLimitList'),
                  LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                    .default,
                })
              : require('../ConfigLimit/ConfigLimitList').default,
            authority: ['Pages.ConfigLimits'],
            exact: true,
          },
          {
            name: 'sa_orders',
            icon: 'shopping-cart',
            path: '/order-management/sa-orders',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__SAOrders" */ '../SAOrders'),
                  LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                    .default,
                })
              : require('../SAOrders').default,
            authority: ['Pages.TransferOrders'],
            exact: true,
          },
          {
            name: 'transfer_orders',
            icon: 'shopping-cart',
            path: '/order-management/transfer-orders',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__TransferOrders" */ '../TransferOrders'),
                  LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                    .default,
                })
              : require('../TransferOrders').default,
            authority: ['Pages.TransferOrders'],
            exact: true,
          },
          {
            name: 'saleorders',
            icon: 'shopping-cart',
            path: '/order-management/saleorders',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__SaleOrder__List" */ '../SaleOrder/List'),
                  LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                    .default,
                })
              : require('../SaleOrder/List').default,
            authority: ['Pages.Saleorders'],
            exact: true,
          },
          {
            path: '/order-management/techaccessoriesreturn',
            name: 'techaccessoriesreturn',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Report__TechAccessoriesReturn" */ '../Report/TechAccessoriesReturn'),
                  LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                    .default,
                })
              : require('../Report/TechAccessoriesReturn').default,
            authority: ['Pages.TechAccessoriesReturn'],
            exact: true,
          },
          {
            name: 'accessories-notakenpack',
            icon: 'shopping-cart',
            path: '/order-management/accessories-notakenpack',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__AccessoriesNoTakenBack__accessories_notakenback" */ '../AccessoriesNoTakenBack/accessories_notakenback'),
                  LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                    .default,
                })
              : require('../AccessoriesNoTakenBack/accessories_notakenback')
                  .default,
            authority: ['Pages.AccessoriesOrders.AO_TakenBack'],
            exact: true,
          },
        ],
      },
      {
        name: 'report',
        icon: 'file',
        path: '/report',
        authority: ['Pages.Reports'],
        routes: [
          {
            name: 'report_warranty',
            path: '/report/warranty',
            authority: [
              'Pages.Reports.WarrantyRequest_History',
              'Pages.Reports.Tech_Revenue',
              'Pages.Reports.Warranty_Process_Assignee',
              'Pages.Reports.Warranty_Business_Step_Stats',
              'Pages.Reports.Customer_Care',
              'Pages.Reports.PerformServiceSummary',
            ],
            routes: [
              {
                path: '/report/warranty/warranty-request-history',
                name: 'report_warranty_request_history',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Report/WarrantyRequestHistory/WarrantyRequestHistoryList'),
                      LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Report/WarrantyRequestHistory/WarrantyRequestHistoryList')
                      .default,
                authority: ['Pages.Reports.WarrantyRequest_History'],
                exact: true,
              },
              {
                path: '/report/warranty/tech-revenue',
                name: 'report_tech_revenue',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Report/TechRevenue/TechRevenueList'),
                      LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Report/TechRevenue/TechRevenueList').default,
                authority: ['Pages.Reports.Tech_Revenue'],
                exact: true,
              },
              {
                path: '/report/warranty/warranty-request-object',
                name: 'report_warranty_request_object',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Report/WarrantyRequestObject'),
                      LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Report/WarrantyRequestObject').default,
                authority: ['Pages.Reports.Warranty_Process_Assignee'],
                exact: true,
              },
              {
                path: '/report/warranty/statistic-case',
                name: 'statisticCase',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Report/StatisticCase'),
                      LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Report/StatisticCase').default,
                authority: ['Pages.Reports.Warranty_Business_Step_Stats'],
                exact: true,
              },
              {
                path: '/report/warranty/customer-care',
                name: 'report_customer_care',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Report/CustomerCare/ReportCustomerCareList'),
                      LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Report/CustomerCare/ReportCustomerCareList')
                      .default,
                authority: ['Pages.Reports.Customer_Care'],
                exact: true,
              },
              {
                path: '/report/warranty/performance-service',
                name: 'performance_service',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Report/PerformanceServiceSummary/ReportPerformanceServiceList'),
                      LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Report/PerformanceServiceSummary/ReportPerformanceServiceList')
                      .default,
                authority: ['Pages.Reports.PerformServiceSummary'],
                exact: true,
              },
            ],
          },
          {
            path: '/report/customer',
            name: 'report_customer',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Report__Customer__ReportCustomerList" */ '../Report/Customer/ReportCustomerList'),
                  LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                    .default,
                })
              : require('../Report/Customer/ReportCustomerList').default,
            authority: ['Pages.Reports.Customer'],
            exact: true,
          },
          {
            path: '/report/accessories',
            name: 'report_accessories',
            authority: [
              'Pages.Reports.Booking_Accessoriess',
              'Pages.Reports.Accessories_NoReturn',
              'Pages.Reports.Accessories_Order_Products',
              'Pages.RevenueOfSaleAccessories',
            ],
            routes: [
              {
                path: '/report/accessories/booking-accessories',
                name: 'report_booking_accessories',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Report/BookingAccessories/BookingAccessoriesList'),
                      LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Report/BookingAccessories/BookingAccessoriesList')
                      .default,
                authority: ['Pages.Reports.Booking_Accessoriess'],
                exact: true,
              },
              {
                path: '/report/accessories/accessories-noreturn',
                name: 'report_accessories_noreturn',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Report/AccessoriesOrderNoReturn/ReportAccessoriesOrderNoReturn'),
                      LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Report/AccessoriesOrderNoReturn/ReportAccessoriesOrderNoReturn')
                      .default,
                authority: ['Pages.Reports.Accessories_NoReturn'],
                exact: true,
              },
              {
                path: '/report/accessories/accessorise-order-products',
                name: 'accessories_order_prducts',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Report/AccessoriesOrderProducts'),
                      LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Report/AccessoriesOrderProducts').default,
                authority: ['Pages.Reports.Accessories_Order_Products'],
                exact: true,
              },
              {
                path: '/report/accessories/accessories-sale',
                name: 'report_revenue_of_sale_accessories',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Report/AccessoriesOrderSaler/ReportAccessoriesOrderSaler'),
                      LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Report/AccessoriesOrderSaler/ReportAccessoriesOrderSaler')
                      .default,
                authority: ['Pages.Reports.RevenueOfSaleAccessories'],
                exact: true,
              },
              {
                path: '/report/accessories/return-order',
                name: 'report_return_order',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Report/ReturnOrder/ReturnOrderList'),
                      LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Report/ReturnOrder/ReturnOrderList').default,
                authority: ['Pages.Reports.Return_Order'],
                exact: true,
              },
            ],
          },
          {
            path: '/report/inventories',
            name: 'inventories',
            authority: ['Pages.Reports.Inventories'],
            routes: [
              {
                path: '/report/inventories/inventory',
                name: 'inventory',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Report/Inventory'),
                      LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Report/Inventory').default,
                authority: ['Pages.Reports.Inventories'],
                exact: true,
              },
              {
                path: '/report/inventories/inventory-inbound-outbound',
                name: 'inventory_inbound_outbound',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Report/InvIn-Out/ReportInventoryInOut'),
                      LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Report/InvIn-Out/ReportInventoryInOut').default,
                authority: ['Pages.Reports.Inventory_Inbound_OutBound'],
                exact: true,
              },
              {
                path: '/report/inventories/inventory-dv',
                name: 'inventory_dv',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Report/Inv-Dv/ReportInventoryDv'),
                      LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Report/Inv-Dv/ReportInventoryDv').default,
                authority: ['Pages.Reports.Inventories_DV'],
                exact: true,
              },
              {
                path: '/report/inventories/inventory-acessories',
                name: 'inventory_accessories',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Report/InvAccessories'),
                      LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Report/InvAccessories').default,
                authority: ['Pages.Reports.Inventory_Statement_By_Product'],
                exact: true,
              },
              {
                path: '/report/inventories/serial-history',
                name: 'serial_history',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Report/SerialHistory'),
                      LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Report/SerialHistory').default,
                authority: ['Pages.Reports.Serial_History'],
                exact: true,
              },
              {
                path: '/report/inventories/inventory-waitingout',
                name: 'inv_waitingout',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Report/InvWaitingOut'),
                      LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Report/InvWaitingOut').default,
                authority: ['Pages.Reports.Inventory_WaitingOut'],
                exact: true,
              },
            ],
          },
          {
            path: '/report/activities',
            name: 'activities',
            authority: [
              'Pages.Reports.Activity_Visit',
              'Pages.Reports.Working_Diary',
              'Pages.Reports.TimeSheet_Histories',
            ],
            routes: [
              {
                path: '/report/activities/activity-visit',
                name: 'activity_visit',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Report/ActivityVisit'),
                      LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Report/ActivityVisit').default,
                authority: ['Pages.Reports.Activity_Visit'],
                exact: true,
              },
              {
                path: '/report/activities/working_diary',
                name: 'report_working_diary',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Report/WorkingDiary/WorkingDiaryList'),
                      LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Report/WorkingDiary/WorkingDiaryList').default,
                authority: ['Pages.Reports.Working_Diary'],
                exact: true,
              },
              {
                path: '/report/activities/timesheets-histories',
                name: 'timesheets_histories',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Report/TimeSheetsHistories'),
                      LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Report/TimeSheetsHistories').default,
                authority: ['Pages.Reports.TimeSheet_Histories'],
                exact: true,
              },
            ],
          },
          {
            path: '/report/receivablemonthly',
            name: 'receivablemonthly',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Report__ReceivableMonthly" */ '../Report/ReceivableMonthly'),
                  LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                    .default,
                })
              : require('../Report/ReceivableMonthly').default,
            authority: ['Pages.Reports.ReceivableMonthly'],
            exact: true,
          },
          {
            path: '/report/summaryreceivablebymonth',
            name: 'summaryreceivablebymonth',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Report__SummaryReceivableByMonth" */ '../Report/SummaryReceivableByMonth'),
                  LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                    .default,
                })
              : require('../Report/SummaryReceivableByMonth').default,
            authority: ['Pages.Reports.SummaryReceivable'],
            exact: true,
          },
          {
            path: '/report/logs',
            name: 'logs',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Report__Logs" */ '../Report/Logs'),
                  LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                    .default,
                })
              : require('../Report/Logs').default,
            hideInMenu: true,
            exact: true,
          },
          {
            path: '/report/debit',
            name: 'debit',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Report__Debit__debit" */ '../Report/Debit/debit'),
                  LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                    .default,
                })
              : require('../Report/Debit/debit').default,
            authority: ['Pages.Reports.Debit'],
            exact: true,
          },
          {
            path: '/report/tech_revenue_summary',
            name: 'tech_revenue_summary',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Report__TechRevenueSummary__List" */ '../Report/TechRevenueSummary/List'),
                  LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                    .default,
                })
              : require('../Report/TechRevenueSummary/List').default,
            authority: ['Pages.Reports.ReportTechRevenueSummary'],
            exact: true,
          },
          {
            path: '/report/station_revenue_summary',
            name: 'station_revenue_summary',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Report__StationRevenueSummary__List" */ '../Report/StationRevenueSummary/List'),
                  LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                    .default,
                })
              : require('../Report/StationRevenueSummary/List').default,
            authority: ['Pages.Reports.ReportStationRevenueSummary'],
            exact: true,
          },
          {
            path: '/report/station_revenue_detail',
            name: 'station_revenue_detail',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Report__StationRevenueDetail__List" */ '../Report/StationRevenueDetail/List'),
                  LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                    .default,
                })
              : require('../Report/StationRevenueDetail/List').default,
            authority: ['Pages.Reports.ReportStationRevenueDetail'],
            exact: true,
          },
          {
            path: '/report/station_reward_punishment',
            name: 'station_reward_punishment',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Report__StationRewardPunishment__List" */ '../Report/StationRewardPunishment/List'),
                  LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                    .default,
                })
              : require('../Report/StationRewardPunishment/List').default,
            authority: ['Pages.Reports.ReportStationRewardPunishment'],
            exact: true,
          },
          {
            path: '/report/station_summary_reward_punishment',
            name: 'station_summary_reward_punishment',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Report__StationSummaryRewardPunishment__List" */ '../Report/StationSummaryRewardPunishment/List'),
                  LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                    .default,
                })
              : require('../Report/StationSummaryRewardPunishment/List')
                  .default,
            authority: ['Pages.Reports.ReportStationSummaryRewardPunishment'],
            exact: true,
          },
          {
            path: '/report/product_warranty_times',
            name: 'product_warranty_times',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Report__ProductWarrantyTimes__index" */ '../Report/ProductWarrantyTimes/index'),
                  LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                    .default,
                })
              : require('../Report/ProductWarrantyTimes/index').default,
            authority: ['Pages.Reports.ProductWarrantyTimes'],
            exact: true,
          },
        ],
      },
      {
        name: 'exception',
        icon: 'warning',
        path: '/exception',
        hideInMenu: true,
        routes: [
          {
            path: '/exception/403',
            name: 'not-permission',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__Exception__models__error.js' */ '/Users/angelit/Work/DaiViet/CRM/Web/src/pages/Exception/models/error.js').then(
                      m => {
                        return { namespace: 'error', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__Exception__403" */ '../Exception/403'),
                  LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                    .default,
                })
              : require('../Exception/403').default,
            exact: true,
          },
          {
            path: '/exception/404',
            name: 'not-find',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__Exception__models__error.js' */ '/Users/angelit/Work/DaiViet/CRM/Web/src/pages/Exception/models/error.js').then(
                      m => {
                        return { namespace: 'error', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__Exception__404" */ '../Exception/404'),
                  LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                    .default,
                })
              : require('../Exception/404').default,
            exact: true,
          },
          {
            path: '/exception/500',
            name: 'server-error',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__Exception__models__error.js' */ '/Users/angelit/Work/DaiViet/CRM/Web/src/pages/Exception/models/error.js').then(
                      m => {
                        return { namespace: 'error', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__Exception__500" */ '../Exception/500'),
                  LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                    .default,
                })
              : require('../Exception/500').default,
            exact: true,
          },
          {
            path: '/exception/trigger',
            name: 'trigger',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__Exception__models__error.js' */ '/Users/angelit/Work/DaiViet/CRM/Web/src/pages/Exception/models/error.js').then(
                      m => {
                        return { namespace: 'error', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__Exception__TriggerException" */ '../Exception/TriggerException'),
                  LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                    .default,
                })
              : require('../Exception/TriggerException').default,
            exact: true,
          },
          {
            path: '/exception/viewlog',
            name: 'trigger',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__Exception__models__error.js' */ '/Users/angelit/Work/DaiViet/CRM/Web/src/pages/Exception/models/error.js').then(
                      m => {
                        return { namespace: 'error', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__Exception__ViewLog" */ '../Exception/ViewLog'),
                  LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                    .default,
                })
              : require('../Exception/ViewLog').default,
            exact: true,
          },
        ],
      },
      {
        name: 'account',
        icon: 'user',
        path: '/account',
        hideInMenu: true,
        routes: [
          {
            path: '/account/changePassword',
            name: 'changePassword',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Account__ChangePassword" */ '../Account/ChangePassword'),
                  LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                    .default,
                })
              : require('../Account/ChangePassword').default,
            exact: true,
          },
        ],
      },
      {
        path: '/warranty-activation',
        name: 'warranty_activation',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__Report__WarrantyActivation" */ '../Report/WarrantyActivation'),
              LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                .default,
            })
          : require('../Report/WarrantyActivation').default,
        authority: ['Pages.Warranty_Activation'],
        exact: true,
      },
      {
        path: '/supviser',
        name: 'supviser',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__Report__Maps" */ '../Report/Maps'),
              LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                .default,
            })
          : require('../Report/Maps').default,
        authority: ['Pages.Supviser'],
        exact: true,
      },
      {
        path: '/cashflows',
        name: 'cashflows',
        authority: ['Pages.CashFlows.DebitCredit.Search'],
        routes: [
          {
            path: '/cashflows/sessions',
            name: 'cashflow_sessions',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Cashflows__confirm__sessions" */ '../Cashflows/confirm/sessions'),
                  LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                    .default,
                })
              : require('../Cashflows/confirm/sessions').default,
            exact: true,
          },
          {
            path: '/cashflows/sessions/:id',
            name: 'cashflow_sessions_detail',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Cashflows__confirm__session_detail" */ '../Cashflows/confirm/session_detail'),
                  LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                    .default,
                })
              : require('../Cashflows/confirm/session_detail').default,
            hideInMenu: true,
            exact: true,
          },
          {
            path: '/cashflows/sessions/:id(\\d+)/:objectId/:keyword',
            name: 'cashflow_sessions_detail',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Cashflows__confirm__session_detail" */ '../Cashflows/confirm/session_detail'),
                  LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                    .default,
                })
              : require('../Cashflows/confirm/session_detail').default,
            hideInMenu: true,
            exact: true,
          },
          {
            path: '/cashflows/sessions/:id(\\d+)/:objectId',
            name: 'cashflow_sessions_detail',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Cashflows__confirm__session_detail" */ '../Cashflows/confirm/session_detail'),
                  LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                    .default,
                })
              : require('../Cashflows/confirm/session_detail').default,
            hideInMenu: true,
            exact: true,
          },
          {
            path: '/cashflows/debitcredit',
            name: 'debitcredit',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Cashflows__debitcredit__debitcredit" */ '../Cashflows/debitcredit/debitcredit'),
                  LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                    .default,
                })
              : require('../Cashflows/debitcredit/debitcredit').default,
            authority: ['Pages.CashFlows.DebitCredit.Search'],
            exact: true,
          },
          {
            path: '/cashflows/debitcredit/:id',
            name: 'cashflow_debitcredit_detail',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Cashflows__debitcredit__debitcredit_detail" */ '../Cashflows/debitcredit/debitcredit_detail'),
                  LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                    .default,
                })
              : require('../Cashflows/debitcredit/debitcredit_detail').default,
            hideInMenu: true,
            exact: true,
          },
          {
            path: '/cashflows/debt_accessories_management',
            name: 'debt_accessories_management',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Report__DebtManagement__debit" */ '../Report/DebtManagement/debit'),
                  LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                    .default,
                })
              : require('../Report/DebtManagement/debit').default,
            authority: ['Pages.CashFlows.AccessoriesDebt'],
            exact: true,
          },
          {
            path: '/cashflows/debt_accessories_management_detail',
            name: 'debt_accessories_management_detail',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Report__DebtManagementDetail__debit" */ '../Report/DebtManagementDetail/debit'),
                  LoadingComponent: require('/Users/angelit/Work/DaiViet/CRM/Web/src/components/PageLoading/index')
                    .default,
                })
              : require('../Report/DebtManagementDetail/debit').default,
            authority: ['Pages.CashFlows.AccessoriesDebtDetail'],
            exact: true,
          },
        ],
      },
    ],
  },
];
window.g_routes = routes;
const plugins = require('umi/_runtimePlugin');
plugins.applyForEach('patchRoutes', { initialValue: routes });

export { routes };

export default class RouterWrapper extends React.Component {
  unListen() {}

  constructor(props) {
    super(props);

    // route change handler
    function routeChangeHandler(location, action) {
      plugins.applyForEach('onRouteChange', {
        initialValue: {
          routes,
          location,
          action,
        },
      });
    }
    this.unListen = history.listen(routeChangeHandler);
    // dva 中 history.listen 会初始执行一次
    // 这里排除掉 dva 的场景，可以避免 onRouteChange 在启用 dva 后的初始加载时被多执行一次
    const isDva =
      history.listen
        .toString()
        .indexOf('callback(history.location, history.action)') > -1;
    if (!isDva) {
      routeChangeHandler(history.location);
    }
  }

  componentWillUnmount() {
    this.unListen();
  }

  render() {
    const props = this.props || {};
    return (
      <RendererWrapper0>
        <Router history={history}>{renderRoutes(routes, props)}</Router>
      </RendererWrapper0>
    );
  }
}

import _ from 'lodash';
import { notification } from 'antd';
import * as employeesAPI from '../services/employees';

export default {
  namespace: 'employees',

  state: {
    searchData: {},
    visibleModal: false,
    visibleEmployeesModal: false,
    modalType: '',
    list: [],
    item: {},
    extensions: [],
    employeesSelected: {},
  },

  effects: {
    *fetch({ payload }, { call, put }) {
      const query = Object.assign({});
      _.forEach(payload, (val, key) => {
        if (val) {
          _.set(query, key, val);
        }
      });

      const response = yield call(employeesAPI.getAll, query);
      if (response.success) {
        yield put({
          type: 'saveList',
          payload: {
            ...response.result,
          },
        });
      }
    },

    *fetchForSelect({ payload }, { call, put }) {
      const query = Object.assign({});
      _.forEach(payload, (val, key) => {
        if (val) {
          _.set(query, key, val);
        }
      });

      const response = yield call(employeesAPI.getForSelect, query);
      if (response.success) {
        yield put({
          type: 'saveSelectList',
          payload: {
            ...response.result,
          },
        });
      }
    },

    *fetchSAForSelect({ payload }, { call, put }) {
      const response = yield call(employeesAPI.getForSelect, { TitleCode: 'SA' });
      if (response.success) {
        yield put({
          type: 'saveSASelectList',
          payload: {
            ...response.result,
          },
        });
      }
    },

    *refreshFetch(_, { put, select }) {
      const searchData = yield select(state => state.employees.searchData);

      yield put({
        type: 'fetch',
        payload: searchData,
      });
    },

    *get({ payload }, { call, put }) {
      const response = yield call(employeesAPI.get, payload.id);
      if (response.success) {
        yield put({
          type: 'save',
          payload: {
            ...response,
            visibleModal: true,
          },
        });

        yield put({
          type: 'address/saveDataAddress',
          payload: {
            address: response.result.userAddress,
          },
        });

        yield put({
          type: 'saveExtensions',
          payload: {
            extensions: response.result.extensions,
          },
        });
      }
    },

    *create({ payload }, { call, put }) {
      const response = yield call(employeesAPI.create, payload);
      if (response.success) {
        yield put({
          type: 'save',
          payload: {
            ...response,
            visibleModal: false,
          },
        });

        yield notification.success({
          message: 'Thêm nhân viên',
          description: 'Đã thêm nhân viên mới thành công',
        });

        yield put({
          type: 'refreshFetch',
        });
      }
    },

    *update({ payload }, { call, put }) {
      const response = yield call(employeesAPI.update, payload);
      if (response.success) {
        yield put({
          type: 'save',
          payload: {
            ...response,
            visibleModal: false,
          },
        });

        yield notification.success({
          message: 'Cập nhật nhân viên',
          description: 'Đã cập nhật thông tin nhân viên thành công',
        });

        yield put({
          type: 'refreshFetch',
        });
      }
    },

    *changeVisibleModal({ payload }, { put }) {
      yield put({
        type: 'saveVisibleModal',
        payload: {
          status: payload.status,
          modalType: payload.modalType,
        },
      });

      yield put({
        type: 'address/saveDataAddress',
        payload: {
          address: null,
        },
      });
    },
  },

  reducers: {
    saveVisibleModal(state, { payload }) {
      return {
        ...state,
        visibleModal: payload.status,
        modalType: payload.modalType,
        extensions: [],
      };
    },

    saveList(state, { payload }) {
      return {
        ...state,
        list: payload,
      };
    },

    saveSelectList(state, { payload }) {
      return {
        ...state,
        listForSelect: payload,
      };
    },
    saveSASelectList(state, { payload }) {
      return {
        ...state,
        listSAForSelect: payload.items,
      };
    },

    saveExtensions(state, { payload }) {
      return {
        ...state,
        extensions: payload.extensions,
      };
    },

    save(state, { payload }) {
      return {
        ...state,
        item: payload.result,
        visibleModal: payload.visibleModal,
      };
    },
    saveSearchData(state, { payload }) {
      return {
        ...state,
        searchData: {
          ...payload,
        },
      };
    },

    changeVisibleEmployeesModal(state, { payload }) {
      return {
        ...state,
        visibleEmployeesModal: payload.status,
      };
    },

    saveEmployeesSelected(state, { payload }) {
      return {
        ...state,
        employeesSelected: payload,
      };
    },
  },
};

import {notification} from "antd";
import * as warrantyRequestAPI from "@/services/warrantyRequest";

export default {
  namespace: 'warrantyRequestAccessoriesOrder',

  state: {
    accessories: [],
  },

  effects: {
    *start({ payload }, { call, put }) {
      const response = yield call(warrantyRequestAPI.orderStart, payload);
      if (response.success) {

        yield put({
          type: 'warrantyRequest/changeCurrentStep',
          payload: {
            item: response.result
          },
        });

        yield notification.success({
          message: 'Đặt linh kiện',
          description: 'Bắt đầu tiến hành đặt linh kiện',
        });
      }
    },

    *finish({ payload }, { call, put }) {
      const response = yield call(warrantyRequestAPI.orderFinish, payload);
      if (response.success) {

        yield put({
          type: 'warrantyRequest/changeCurrentStep',
          payload: {
            item: response.result
          },
        });

        yield notification.success({
          message: 'Đặt linh kiện',
          description: 'Đã cập nhật đặt linh kiện thành công',
        });
      }
    },
  },

  *start({ payload }, { call, put }) {
    const response = yield call(warrantyRequestAPI.orderStart, payload);
    if (response.success) {

      yield put({
        type: 'warrantyRequest/changeCurrentStep',
        payload: {
          item: response.result
        },
      });

      yield notification.success({
        message: 'Đặt linh kiện',
        description: 'Bắt đầu tiến hành đặt linh kiện',
      });
    }
  },

  reducers: {

  },
};

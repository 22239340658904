import _ from 'lodash';
import { notification } from 'antd';
import { handleParams } from '@/utils/utils';
import * as warrantyRequestAPI from '../services/warrantyRequest';

function handelAccessories(lines, accessories) {
  return accessories.map(el => {
    el.accessories = el.product;
    el.product = lines.filter(el2 => el2.lineNumber === el.warrantyReqLineNumber)[0].product;
    return el;
  });
}

export const WARRANTY_TYPE = { NOMARL: 'normal', CALLCENTER: 'callcenter', HISTORY: 'history' };
export const WARRANTY_FROM = { WR: 'WR', CC: 'CC', OTHER: 'OTHER' };

export default {
  namespace: 'warrantyRequest',

  state: {
    searchData: {},
    visibleModal: false,
    modalType: '',
    list: [],
    item: {},
    process: {},
    workorder: {},
    id: 0,
    currentStepCode: null,
    historiesCase: [],
    revertArr: [],
    current: 0,
    warrantyReqStatus: [],
    assigneesPlan: [],
    warrantyType: '',
    records: [],
    recordsEmployee: [],
    recordsSummary: [],
    warrantyLineBySerial: [],
    delayInfo: {},
    warrantyRequestHistories: [],
    firstNumOfDaysLateCurrent: null,
  },

  effects: {
    *fetch({ payload }, { call, put }) {
      const query = Object.assign({});
      _.forEach(payload, (val, key) => {
        if (val) {
          _.set(query, key, val);
        }
      });
      const dateFrom = _.get(query, 'FromDate', '');
      const dateTo = _.get(query, 'ToDate', '');
      if (_.isEmpty(dateFrom) || _.isEmpty(dateTo)) return;

      const response = yield call(warrantyRequestAPI.getAll, query);
      if (response.success) {
        yield put({
          type: 'saveSearchData',
          payload: query,
        });
        yield put({
          type: 'saveList',
          payload: {
            ...response.result,
          },
        });
      }
    },

    *refreshFetch(_, { put, select }) {
      const searchData = yield select(state => state.warrantyRequest.searchData);
      yield put({
        type: 'fetch',
        payload: searchData,
      });
    },

    *get({ payload }, { call, put }) {
      const {
        warrantyType,
        id,
        from = WARRANTY_FROM.OTHER,
        isRefesh = true,
        firstNumOfDaysLateCurrent = null,
      } = payload;
      const response = yield call(warrantyRequestAPI.get, id);
      if (response.success) {
        yield put({
          type: 'save',
          payload: {
            item: response.result,
            warrantyType,
            from,
          },
        });

        yield put({
          type: 'saveVisibleModal',
          payload: {
            status: true,
          },
        });

        yield put({
          type: 'saveFirstNumOfDaysLateCurrent',
          firstNumOfDaysLateCurrent,
        });

        yield put({
          type: 'saveIsRefesh',
          payload: {
            isRefesh,
          },
        });

        yield put({
          type: 'changeWarrantyData',
          payload: response.result,
        });

        yield put({
          type: 'getRevert',
          payload,
        });

        yield put({
          type: 'callCenter/saveRecentViewItem',
          payload: {
            result: response.result.case,
          },
        });
      }
    },

    *getProcess({ payload }, { call, put }) {
      const response = yield call(warrantyRequestAPI.getProcess, payload.id);
      if (response.success) {
        yield put({
          type: 'saveProcess',
          payload: {
            process: response.result,
          },
        });
      }
    },
    *getRecords({ id }, { call, put }) {
      const { result, success } = yield call(warrantyRequestAPI.getRecords, id);
      if (success) {
        yield put({
          type: 'saveRecords',
          payload: result,
        });
      }
    },
    *getRecordsByEmployeeId({ id, employeeId }, { call, put }) {
      const { result, success } = yield call(warrantyRequestAPI.getRecords, id, employeeId);
      if (success) {
        yield put({
          type: 'saveRecordsEmployee',
          payload: result,
        });
      }
    },
    *getRecord({ id, payload }, { call }) {
      const { result, success } = yield call(warrantyRequestAPI.getRecord, id, payload);
      if (success) {
        return result;
      }
      return {};
    },
    *getSummaryRecord({ id, payload }, { call, put }) {
      const { result, success } = yield call(warrantyRequestAPI.getSummaryRecord, id, payload);
      if (success) {
        yield put({
          type: 'saveRecordsSummary',
          payload: result,
        });
      }
    },
    *searchWarrantyLineBySerial({ payload }, { call, put }) {
      const { result, success } = yield call(
        warrantyRequestAPI.searchWarrantyLineBySerial,
        payload.SerialNo,
        payload.FilterWithHiddenSerialNo,
        payload.HiddenSerialNo
      );
      if (success) {
        yield put({
          type: 'saveWarrantyLineBySerial',
          payload: result,
        });
      }
    },
    *getModelDetail({ payload }, { call }) {
      const { result, success } = yield call(warrantyRequestAPI.getModelDetail, payload);
      if (success) {
        return result;
      }
      return {};
    },
    *getInfoDeclarations({ payload }, { call }) {
      const { result, success } = yield call(warrantyRequestAPI.getInfoDeclarations, payload);
      if (success) {
        return result;
      }
      return {};
    },
    *uploadCheckInOutImage({ payload }, { call }) {
      const { id, actionId, body } = payload;
      const formData = new FormData();
      Object.entries(body).map(([key, value]) => {
        if (key === 'images') {
          return value.forEach(file => {
            if (file.originFileObj) {
              formData.append('files', file.originFileObj);
            }
          });
        }
        return formData.append(key, value);
      });

      const { success, result } = yield call(warrantyRequestAPI.uploadCheckInOutImage, {
        id,
        actionId,
        body: formData,
      });
      return result;
    },

    *uploadReplacementImage({ payload }, { call }) {
      const { id, actionId, repId, body } = payload;
      const formData = new FormData();
      Object.entries(body).map(([key, value]) => {
        if (key === 'images') {
          return value.forEach(file => {
            if (file.originFileObj) {
              formData.append('files', file.originFileObj);
            }
          });
        }
        return formData.append(key, value);
      });

      const { success } = yield call(warrantyRequestAPI.uploadReplacementImage, {
        id,
        actionId,
        repId,
        body: formData,
      });
      return success;
    },
    *getPricingTerms({ payload }, { call }) {
      const { result, success } = yield call(warrantyRequestAPI.getPricingTerms, payload);
      if (success) {
        return result;
      }
      return {};
    },

    *unContact({ id }, { call }) {
      const { success } = yield call(warrantyRequestAPI.unContact, id);
      return success;
    },
    *unContactHistory({ id }, { call }) {
      const { result } = yield call(warrantyRequestAPI.unContactHistory, id);
      return result;
    },

    *getWorkOrder({ payload }, { call }) {
      const response = yield call(warrantyRequestAPI.getWorkOrder, payload.id, payload.step);
      return _.get(response, 'result');
    },

    *start({ payload }, { call, put }) {
      const response = yield call(warrantyRequestAPI.start, payload);
      if (response.success) {
        yield put({
          type: 'changeCurrentStep',
          payload: {
            item: response.result,
          },
        });
      }
    },

    *close({ payload }, { call, put }) {
      const { success, result } = yield call(warrantyRequestAPI.close, payload);
      if (success) {
        yield put({
          type: 'changeCurrentStep',
          payload: {
            item: result,
          },
        });
      }
      return Promise.resolve(success);
    },

    *create({ payload }, { call, put }) {
      const response = yield call(warrantyRequestAPI.create, payload);
      if (response.success) {
        yield put({
          type: 'changeCurrentStep',
          payload: {
            item: response.result,
          },
        });

        yield notification.success({
          message: 'Thêm ca bảo hành',
          description: 'Đã thêm ca bảo hành thành công',
        });
      }
    },

    *update({ payload }, { call, put }) {
      const response = yield call(warrantyRequestAPI.update, payload);
      if (response.success) {
        yield put({
          type: 'changeCurrentStep',
          payload: {
            item: response.result,
          },
        });

        yield notification.success({
          message: 'Cập nhật ca bảo hành',
          description: 'Đã cập nhật ca bảo hành thành công',
        });
      }
    },

    *changeVisibleModal({ payload }, { put }) {
      const { id } = payload;
      yield put({
        type: 'saveVisibleModal',
        payload: {
          ...payload,
          id: id || 0,
        },
      });

      /**
       * Reset states for warrantyRequestProduct
       */
      yield put({
        type: 'warrantyRequestProduct/reset',
      });
      /**
       * Reset states for warrantyRequestProcess
       */
      yield put({
        type: 'saveRecordsSummary',
        payload: [],
      });
      yield put({
        type: 'saveRecords',
        payload: [],
      });
      yield put({
        type: 'saveRecordsEmployee',
        payload: [],
      });
      // yield put({
      //   type: 'saveFirstNumOfDaysLateCurrent',
      //   firstNumOfDaysLateCurrent:null,
      // });
    },

    *changeCurrentStep({ payload }, { put }) {
      const { item } = payload;

      yield put({
        type: 'saveCurrentStep',
        payload: {
          item,
        },
      });

      yield put({
        type: 'getRevert',
        payload: { id: item.id },
      });

      yield put({
        type: 'changeWarrantyData',
        payload: item,
      });
    },

    *getHistoriesCase({ payload }, { call, put }) {
      const response = yield call(warrantyRequestAPI.getHistoriesCase, payload);
      if (response.success) {
        yield put({
          type: 'saveHistoriesCase',
          payload: {
            data: response.result.items,
          },
        });
      }
    },

    *deleteImage({ payload }, { call, put }) {
      const response = yield call(warrantyRequestAPI.deleteImage, payload);
      return _.get(response, 'success');
    },

    *deleteImageLine({ payload }, { call, put }) {
      const response = yield call(warrantyRequestAPI.deleteImageLine, payload);
      return _.get(response, 'success');
    },

    *processCheckout({ payload }, { call, put }) {
      const { id, body } = payload;
      const formData = new FormData();
      const images = body.images;
      Object.entries(body).map(([key, value]) => {
        if (key === 'images') {
          return value.forEach(file => {
            if (file.originFileObj) {
              formData.append('files', file.originFileObj);
            }
          });
        }
        if (key === 'replacements') {
          return formData.append(
            'replacements',
            JSON.stringify(
              _.map(value, r => ({
                ...r,
                images: _.map(r.images, item => item.name),
              }))
            )
          );
        }
        if (_.isString(value)) {
          return formData.append(key, value);
        }
        return formData.append(key, JSON.stringify(value));
      });

      const {
        result: { actionId, sessionImageId },
      } = yield call(warrantyRequestAPI.processCheckout, {
        id,
        body: formData,
      });
      if (actionId > 0) {
        if (body.customerSignature) {
          const signFormData = new FormData();
          body.customerSignature.forEach(file => {
            if (file.originFileObj) {
              signFormData.append('files', file.originFileObj);
            }
          });

          yield put({
            type: 'uploadCustomeSign',
            payload: {
              id,
              actionId,
              body: signFormData,
            },
          });
          return true;
        }

        // if (!_.isNil(body.images)) {
        //   yield put({
        //     type: 'uploadCheckInOutImage',
        //     payload: {
        //       id,
        //       actionId,
        //       body: {
        //         images:body.images,
        //         isProcessImage: true
        //       },
        //     },
        //   });
        // }

        yield body.replacements.map(re => {
          return put({
            type: 'uploadCheckInOutImage',
            payload: {
              id,
              actionId,
              body: {
                productId: re.productId,
                warrantyRequestLineNumber: _.toString(re.warrantyRequestLineNumber),
                warrantyReqLineAccessoriesId: _.toString(re.warrantyReqLineAccessoriesId),
                images: re.images,
              },
            },
          });
        });

        yield put({
          type: 'getRecords',
          id,
        });
        yield put({
          type: 'getSummaryRecord',
          id,
        });
        return true;
      }
      return false;
    },
    *processAddReplacementCheckout({ payload }, { call, put }) {
      const { id, actionId, body } = payload;
      const images = body.images;
      const sessionMediaId = body.warrantyReqLineMediaSessionId;
      console.log('payload-images', images);
      const formData = new FormData();
      Object.entries(body).map(([key, value]) => {
        if (key === 'replacements') {
          return formData.append(
            'replacements',
            JSON.stringify(
              _.map(value, r => ({
                ...r,
                images: _.map(r.images, item => item.name),
              }))
            )
          );
        }
        if (_.isString(value)) {
          return formData.append(key, value);
        }
        return formData.append(key, JSON.stringify(value));
      });

      const response = yield call(warrantyRequestAPI.processCheckoutAddReplacement, {
        id,
        actionId,
        body: formData,
      });
      if (actionId > 0 && response.success) {
        const imagesData = body.replacements.filter(x => _.size(x.images) > 0);
        console.log('imagesData', imagesData, _.size(imagesData));
        if (_.size(imagesData) > 0) {
          yield body.replacements.map(re => {
            return put({
              type: 'uploadCheckInOutImage',
              payload: {
                id,
                actionId,
                body: {
                  productId: re.productId,
                  warrantyRequestLineNumber: _.toString(re.warrantyRequestLineNumber),
                  warrantyReqLineAccessoriesId: _.toString(re.warrantyReqLineAccessoriesId),
                  images: re.images,
                },
              },
            });
          });
        }

        yield put({
          type: 'uploadCheckInOutImage',
          payload: {
            id,
            actionId,
            body: {
              isProcessImage: true,
              sessionImageId: sessionMediaId,
              warrantyRequestLineNumber: _.head(body.replacements).warrantyRequestLineNumber,
              images: images,
            },
          },
        });

        // yield put({
        //   type: 'getRecords',
        //   id,
        // });
        // yield put({
        //   type: 'getSummaryRecord',
        //   id,
        // });
        return true;
      }
      return false;
    },
    *uploadCustomeSign({ payload }, { call }) {
      const response = yield call(warrantyRequestAPI.uploadSignatureImage, payload);
      return response.success;
    },

    *checkin({ payload }, { call }) {
      const { id, body } = payload;
      const formData = new FormData();
      Object.entries(body).map(([key, value]) => {
        if (key === 'images') {
          return value.forEach(file => {
            if (file.originFileObj) {
              formData.append('files', file.originFileObj);
            }
          });
        }
        return formData.append(key, value);
      });

      const { result } = yield call(warrantyRequestAPI.checkIn, {
        id,
        body: formData,
      });
      return result;
    },
    *addProduct({ payload }, { call, put }) {
      const { id, body } = payload;
      const formData = new FormData();
      Object.entries(body).map(([key, value]) => {
        if (key === 'images') {
          return value.forEach(file => {
            if (file.originFileObj) {
              formData.append('files', file.originFileObj);
            }
          });
        }
        if (key === 'replacements') {
          return formData.append(
            'replacements',
            JSON.stringify(
              _.map(value, r => ({
                ...r,
                images: _.map(r.images, item => item.name),
              }))
            )
          );
        }
        if (_.isObject(value)) {
          return formData.append(key, JSON.stringify(value));
        }
        return formData.append(key, value);
      });

      const { result: { actionId = 0, lineNumber = 0 } = {}, success = true } = yield call(
        warrantyRequestAPI.addProduct,
        {
          id,
          body: formData,
        }
      );
      if (success) {
        yield body.replacements.map(re => {
          if (!re.isTakenBack && !re.isCharged) {
            return put({
              type: 'uploadCheckInOutImage',
              payload: {
                id,
                actionId,
                body: {
                  productId: re.productId,
                  warrantyRequestLineNumber: lineNumber,
                  images: re.images,
                },
              },
            });
          }
          return null;
        });
      }

      return success;
    },

    *getRevert({ payload }, { call, put }) {
      const response = yield call(warrantyRequestAPI.getRevert, payload);
      if (response.success) {
        yield put({
          type: 'saveRevert',
          payload: {
            data: response.result,
          },
        });
      }
    },

    *putRevert({ payload }, { call, put }) {
      const { success, result } = yield call(warrantyRequestAPI.putRevert, payload);
      if (success) {
        yield put({
          type: 'changeCurrentStep',
          payload: { item: result },
        });
      }
    },

    *changeWarrantyData({ payload }, { put }) {
      const { lines, lineAccessories, accessories, assignees, steps, currentStepCode } = payload;
      const accessoriesData = handelAccessories(lines, lineAccessories);

      yield put({
        type: 'saveLines',
        payload: lines,
      });

      yield put({
        type: 'warrantyRequestSchedule/createAccessories',
        payload: {
          data: accessories,
          assignees,
        },
      });

      yield put({
        type: 'saveLineAccessories',
        payload: accessoriesData,
      });

      const currentStepIndex = _.findIndex(steps, s => s.code === currentStepCode);
      if (currentStepIndex >= 3 && _.isEmpty(_.find(steps, { code: 'CRM_CS_BH_04' }))) {
        yield put({
          type: 'saveCurrent',
          payload: currentStepIndex + 1,
        });
      } else {
        yield put({
          type: 'saveCurrent',
          payload: currentStepIndex || 0,
        });
      }
    },

    *putClose({ payload }, { call, put }) {
      let response = null;
      switch (payload.type) {
        case 'closed':
          response = yield call(warrantyRequestAPI.warrantyReqClosed, payload);
          break;
        case 'closed-reject':
          response = yield call(warrantyRequestAPI.warrantyReqCloseReject, payload);
          break;
        case 'closed-cancel':
          response = yield call(warrantyRequestAPI.warrantyReqCloseCancel, payload);
          break;
        default:
          break;
      }

      const { success, result } = response;
      if (success) {
        yield put({
          type: 'save',
          payload: {
            item: result,
            visibleModal: true,
          },
        });
      }
      return success;
    },

    *getAssigneesPlan({ payload }, { call, put }) {
      const { id } = payload;
      delete payload.id;
      const query = {
        id,
        query: {
          ...handleParams(payload),
        },
      };
      const response = yield call(warrantyRequestAPI.getAssigneesPlan, query);
      if (response.success) {
        yield put({
          type: 'saveAssigneesPlan',
          payload: response.result,
        });
      }
    },

    *hasAccessoriesOrder({ payload }, { call }) {
      const { code } = payload;
      const response = yield call(warrantyRequestAPI.hasAccessoriesOrder, { code });
      return _.get(response, 'result');
    },

    *removeProduct({ payload }, { call }) {
      const { success } = yield call(warrantyRequestAPI.removeProduct, payload);
      return success;
    },
    *searchSerial({ payload }, { call }) {
      const { result } = yield call(warrantyRequestAPI.searchWarrantyActivation, payload);
      return result;
    },
    *updateBillRemind({ payload }, { call }) {
      const { result } = yield call(warrantyRequestAPI.updateBillRemind, payload);
      return result;
    },
    *updateNumberOfDelay({ payload }, { call, put }) {
      const { result, success } = yield call(warrantyRequestAPI.updateNumberOfDelay, payload);
      if (success) {
        yield put({
          type: 'saveDelayInfo',
          payload: {},
        });
      }
      return result;
    },
    *getInfoRenew({ payload }, { call, put }) {
      const { result, success } = yield call(warrantyRequestAPI.getInfoRenew, payload);
      if (success) {
        yield put({
          type: 'saveDelayInfo',
          payload: result,
        });
      }
      return success;
    },
    *revertProductWR({ payload }, { call, put }) {
      const { id } = payload;
      const { result, success } = yield call(warrantyRequestAPI.revertProduct, payload);
      if (success) {
        yield put({
          type: 'getRecords',
          id,
        });
        yield put({
          type: 'getSummaryRecord',
          id,
        });
      }
      return success;
    },
    *updateModelWrongInfo({ payload }, { call }) {
      const { result, success } = yield call(warrantyRequestAPI.updateModelWrongInfo, payload);
      return success;
    },
    *warrantyRequestAction({ payload }, { call }) {
      const { success } = yield call(warrantyRequestAPI.warrantyRequestAction, payload);
      return success;
    },
    *getWarrantyRequestHistories({ payload }, { call, put }) {
      const { result } = yield call(warrantyRequestAPI.getWarrantyRequestHistories, payload);
      if (result) {
        yield put({
          type: 'saveWarrantyRequestHistories',
          payload: {
            data: result,
          },
        });
      }
    },
  },

  reducers: {
    saveWarrantyRequestHistories(state, { payload }) {
      const { data } = payload;
      return {
        ...state,
        warrantyRequestHistories: data,
      };
    },
    saveVisibleModal(state, { payload }) {
      return {
        ...state,
        visibleModal: payload.status,
        modalType: payload.modalType,
        id: payload.id,
      };
    },
    saveIsRefesh(state, { payload }) {
      return {
        ...state,
        isRefesh: payload.isRefesh,
      };
    },
    saveFirstNumOfDaysLateCurrent(state, payload) {
      return {
        ...state,
        firstNumOfDaysLateCurrent: payload.firstNumOfDaysLateCurrent,
      };
    },
    saveList(state, { payload }) {
      return {
        ...state,
        list: payload,
      };
    },
    save(state, { payload }) {
      const { item } = payload;
      return {
        ...state,
        item,
        from: _.has(payload, 'from') ? payload.from : state.from,
        warrantyType: _.has(payload, 'warrantyType') ? payload.warrantyType : state.warrantyType,
      };
    },
    saveItem(state, { payload }) {
      const { id, visibleModal, data } = payload;
      const item = {
        case: data,
      };
      return {
        ...state,
        item,
        visibleModal,
        id: id || 0,
      };
    },
    saveProcess(state, { payload }) {
      const { process } = payload;
      return {
        ...state,
        process,
      };
    },
    saveWorkOrder(state, { payload }) {
      const { workorder } = payload;
      return {
        ...state,
        workorder,
      };
    },
    saveCurrentStep(state, { payload }) {
      const { item } = payload;

      return {
        ...state,
        item,
      };
    },
    saveAccessories(state, { payload }) {
      const { item } = state;
      item.accessories = payload.map(el => {
        if (!_.has(el, 'key')) {
          el.key = Math.random();
        }
        if (!_.has(el, 'isDeleted')) {
          el.isDeleted = false;
        }
        return el;
      });
      return {
        ...state,
        item: { ...item },
      };
    },
    saveLineAccessories(state, { payload }) {
      const { item } = state;
      item.lineAccessories = payload.map(el => {
        if (!_.has(el, 'key')) {
          el.key = Math.random();
        }
        if (!_.has(el, 'isDeleted')) {
          el.isDeleted = false;
        }
        return el;
      });
      return {
        ...state,
        item: { ...item },
      };
    },
    saveLines(state, { payload }) {
      const { item } = state;
      item.lines = payload.map(el => {
        el.rowKey = el.lineNumber || Math.random();
        return el;
      });
      return {
        ...state,
        item: { ...item },
      };
    },
    saveOrders(state, { payload }) {
      const { item } = state;
      item.orderLines = payload;
      return {
        ...state,
        item: { ...item },
      };
    },
    saveHistoriesCase(state, { payload }) {
      const { data } = payload;
      return {
        ...state,
        historiesCase: data,
      };
    },
    saveSearchData(state, { payload }) {
      return {
        ...state,
        searchData: payload,
      };
    },
    saveRevert(state, { payload }) {
      const { data } = payload;
      return {
        ...state,
        revertArr: data,
      };
    },
    saveCurrent(state, { payload }) {
      return {
        ...state,
        current: payload,
      };
    },
    saveAssigneesPlan(state, { payload }) {
      return {
        ...state,
        assigneesPlan: payload,
      };
    },
    saveRecords(state, { payload }) {
      return {
        ...state,
        records: payload,
      };
    },
    saveRecordsEmployee(state, { payload }) {
      return {
        ...state,
        recordsEmployee: payload,
      };
    },
    saveRecordsSummary(state, { payload }) {
      return {
        ...state,
        recordsSummary: payload,
      };
    },
    saveWarrantyLineBySerial(state, { payload }) {
      return {
        ...state,
        warrantyLineBySerial: payload,
      };
    },
    saveDelayInfo(state, { payload }) {
      return {
        ...state,
        delayInfo: payload,
      };
    },
  },
};

import { notification } from "antd";
import * as callCenterAPI from "../services/callCenter";

const delay = timeout => new Promise(resolve => setTimeout(resolve, timeout));

const initState = {
  callCenterState: {},
  statusCreate: false,
  statusConfirm: false,
  visibleModalCallCenter: false,
  newCustomerCase: false
};

export default {
  namespace: 'customerCase',

  state: {
    ...initState
  },

  effects: {
    *changeVisibleModal({ payload }, { put, select }) {
      if (payload.status) {
        const callCenter = yield select(state => state.callCenter);
        yield put({
          type: 'initCreate',
          payload: callCenter
        });

        yield put({
          type: 'callCenter/resetState',
          payload: {
            visibleModalCallCenter: false
          }
        });
      } else {
        yield put({
          type: 'updateCallCenterState'
        });
      }

      yield put({
        type: 'callCenter/changeVisibleCallCenterModal',
        payload: {
          status: true,
          modalType: 'create'
        }
      });

    },

    *updateCallCenterState(_, { select, put }) {
      const callCenterState = yield select(state => state.customerCase.callCenterState);
      yield put({
        type: 'callCenter/updateState',
        payload: callCenterState
      });

      yield put({
        type: 'resetState'
      });
    },

    *initCreate({ payload }, { put }) {
      yield put({
        type: 'create',
        payload: {
          id: payload.item.id,
          callCenter: payload,
          body: {}
        }
      });

      yield put({
        type: 'saveCallCenterState',
        payload
      });

      yield put({
        type: 'saveNewCase',
        payload: {
          status: true
        }
      });
    },

    *create({ payload }, { call, put }) {
      const response = yield call(callCenterAPI.createCustomerCase, payload);
      if (response.success) {
        yield put({
          type: 'callCenter/get',
          payload: {
            id: response.result
          },
        });

        yield call(delay, 100);

        yield put({
          type: 'callCenter/saveObjectSelected',
          payload: {
            data: payload.callCenter.item.customer
          }
        });
      }
    },

    *close({ payload }, { call, put }) {
      const response = yield call(callCenterAPI.closeCustomerCase, payload);
      if (response.success) {
        yield put({
          type: 'updateCallCenterState'
        });
      }
    },

    *confirmInfo(_, { put }) {
      yield put({
        type: 'saveStatusCreate',
        payload: {
          status: true
        },
      });
    },

    *confirmCustomerCase({ payload }, { call, put }) {
      const response = yield call(callCenterAPI.confirmCustomerCase, payload);
      if (response.success) {
        yield put({
          type: 'saveStatusConfirm',
          payload: {
            status: true
          },
        });

        yield notification.success({
          message: 'Ca bảo hành',
          description: 'Chuyển đổi thông tin tiếp nhận thành công',
        });

        yield put({
          type: 'updateStatusCase',
          payload: response.result
        });
      }
    },

    *confirmedCustomerCase({ payload }, { call, put }) {
      const response = yield call(callCenterAPI.confirmedCustomerCase, payload);
      if (response.success) {
        yield put({
          type: 'updateCallCenterState'
        });
      }
    },

    *closeCustomerCase({ payload }, { put }) {
      if (payload.isConfirmed) {
        yield put({
          type: 'confirmedCustomerCase',
          payload
        });
      } else {
        yield put({
          type: 'close',
          payload
        });
      }
    },

    *updateStatusCase({ payload }, { put, select }) {
      const item = yield select(state => state.callCenter.item);
      if (!_.isEmpty(item)) {
        item.status = payload.status;
        item.customer = payload.customer;
      }
      yield put({
        type: 'callCenter/saveItem',
        payload: {
          item
        },
      });
    },
  },

  reducers: {

    saveModal(state, { payload }) {
      return {
        ...state,
        visibleModalCustomerCase: payload.status,
        newCustomerCase: payload.status,
        statusCreate: false
      };
    },

    saveCallCenterState(state, { payload }) {
      return {
        ...state,
        callCenterState: {
          ...payload
        },
      };
    },

    saveStatusCreate(state, { payload }) {
      return {
        ...state,
        statusCreate: payload.status
      };
    },

    saveStatusConfirm(state, { payload }) {
      return {
        ...state,
        statusConfirm: payload.status
      };
    },

    resetState() {
      return {
        ...initState
      };
    },

    saveNewCase(state, { payload }) {
      return {
        ...state,
        newCustomerCase: payload.status
      };
    },

  },
};

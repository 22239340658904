import _ from 'lodash';

export const handleParamInventory = (accessoriesOrderLines, conditionTypeCode, type) => {
  const body = [];
  accessoriesOrderLines.map(el => {
    body.push({
      sku: el.sku,
      conditionTypeCode:
        type === 'checkSAInventory' && conditionTypeCode === 'SONG_PHI'
          ? 'SONG'
          : conditionTypeCode,
    });
  });

  return body;
};

export const updateInventoryAccLines = (accessoriesOrderLines, inventories, forceUpdatePrice) => {
  return accessoriesOrderLines.map(el => {
    const result = inventories.filter(e => e.SKU === el.sku)[0];
    el.availableQty = _.get(result, 'availableQty', 0);
    if (forceUpdatePrice) {
      el.price = _.get(result, 'price', 0);
    }
    return el;
  });
};

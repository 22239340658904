import { apiGet, apiPost, apiPut, apiDelete } from '@/utils/request';
import _ from 'lodash';

export function getAll(query) {
  return apiGet(`${BASE_API_URL}/services/app/WarrantyRequest`, query);
}

export function get(id) {
  return apiGet(`${BASE_API_URL}/services/app/WarrantyRequest/GetDetail`, { id });
}

export function create(body) {
  return apiPost(`${BASE_API_URL}/services/app/WarrantyRequest/Create`, { body });
}

export function update(body) {
  return apiPut(`${BASE_API_URL}/services/app/WarrantyRequest/Update`, { body });
}

export function getAccessories(query) {
  return apiGet(`${BASE_API_URL}/services/app/Product/GetForSelect`, query);
}

export function start({ id }) {
  return apiPut(`${BASE_API_URL}/services/app/WarrantyRequest/${id}/start`);
}

export function close({ id, note }) {
  return apiPut(`${BASE_API_URL}/services/app/WarrantyRequest/${id}/close`, {
    body: { note },
  });
}

export function analyzeStart({ id, body }) {
  return apiPut(`${BASE_API_URL}/services/app/WarrantyRequest/${id}/analyze/start`, { body });
}

export function analyzeFinish({ id, body }) {
  return apiPut(`${BASE_API_URL}/services/app/WarrantyRequest/${id}/analyze/finish`, {
    body,
  });
}
export function analyzeSkip({ id, body }) {
  return apiPut(`${BASE_API_URL}/services/app/WarrantyRequest/${id}/analyze/skip`, {
    body,
  });
}

export function planStart({ id, body }) {
  return apiPut(`${BASE_API_URL}/services/app/WarrantyRequest/${id}/plan/start`, { body });
}

export function planFinish({ id, body }) {
  return apiPut(`${BASE_API_URL}/services/app/WarrantyRequest/${id}/plan/finish`, { body });
}

export function orderStart({ id, body }) {
  return apiPut(`${BASE_API_URL}/services/app/WarrantyRequest/${id}/order/start`, { body });
}

export function orderFinish({ id, body }) {
  return apiPut(`${BASE_API_URL}/services/app/WarrantyRequest/${id}/order/finish`, { body });
}

export function getProcess(id) {
  return apiGet(`${BASE_API_URL}/services/app/WarrantyRequest/${id}/process`);
}
export function getRecords(id, employeeId) {
  return apiGet(
    `${BASE_API_URL}/services/app/WarrantyRequest/${id}/process/records${
      !_.isNil(employeeId) ? `?employeeId=${employeeId}` : ''
    }`
  );
}
export function getRecord(id, query) {
  return apiGet(`${BASE_API_URL}/services/app/WarrantyRequest/${id}/process/record`, query);
}
export function getSummaryRecord(id, query) {
  return apiGet(
    `${BASE_API_URL}/services/app/WarrantyRequest/${id}/process/record-complete`,
    query
  );
}
export function unContact(id) {
  return apiPost(`${BASE_API_URL}/services/app/WarrantyRequest/${id}/uncontact`);
}
export function unContactHistory(id) {
  return apiGet(`${BASE_API_URL}/services/app/WarrantyRequest/${id}/uncontacts`);
}
export function getWorkOrder(id, step) {
  return apiGet(`${BASE_API_URL}/services/app/WarrantyRequest/${id}/work-order/${step}`);
}
export function updateLine({ id, lineNumber, body }) {
  return apiPut(`${BASE_API_URL}/services/app/WarrantyRequest/${id}/line/${lineNumber}`, {
    body,
  });
}

export function getHistoriesCase({ warrantyReqId }) {
  return apiGet(`${BASE_API_URL}/services/app/WarrantyRequest/${warrantyReqId}/histories`);
}

export function getRevert({ id }) {
  return apiGet(`${BASE_API_URL}/services/app/WarrantyRequest/${id}/revert`);
}

export function putRevert({ id, body }) {
  return apiPut(`${BASE_API_URL}/services/app/WarrantyRequest/${id}/revert`, {
    body,
  });
}

export function getComments({ warReqId }) {
  return apiGet(`${BASE_API_URL}/services/app/WarrantyRequest/${warReqId}/comments`);
}

export function postComments({ warReqId, body }) {
  return apiPost(`${BASE_API_URL}/services/app/WarrantyRequest/${warReqId}/comments`, { body });
}

export function updateComments({ warReqId, id, body }) {
  return apiPut(`${BASE_API_URL}/services/app/WarrantyRequest/${warReqId}/comments/${id}`, {
    body,
  });
}

export function warrantyReqClosed({ id }) {
  return apiPut(`${BASE_API_URL}/services/app/WarrantyRequest/${id}/closed`);
}

export function warrantyReqCloseReject({ id }) {
  return apiPut(`${BASE_API_URL}/services/app/WarrantyRequest/${id}/close-reject`);
}

export function warrantyReqCloseCancel({ id }) {
  return apiPut(`${BASE_API_URL}/services/app/WarrantyRequest/${id}/close-cancel`);
}

export function getInventoryAssignees({ id, query }) {
  return apiGet(`${BASE_API_URL}/services/app/WarrantyRequest/${id}/inventory/assignees`, query);
}
export function getInventoryEmployeee({ id, query }) {
  return apiGet(`${BASE_API_URL}/services/app/WarrantyRequest/${id}/inventory/employee`, query);
}

export function getAssigneesPlan({ id, query }) {
  return apiGet(`${BASE_API_URL}/services/app/WarrantyRequest/${id}/plan/suggest`, query);
}
export function hasAccessoriesOrder(query) {
  return apiGet(`${BASE_API_URL}/services/app/WarrantyRequest/has-order`, query);
}
export function uploadImage({ id, lineNumber, body }) {
  return apiPut(`${BASE_API_URL}/services/app/WarrantyRequest/${id}/line/${lineNumber}/images`, {
    body,
  });
}
export function getProductImages({ id, lineNumber }) {
  return apiGet(`${BASE_API_URL}/services/app/WarrantyRequest/${id}/line/${lineNumber}/images`);
}
export function getModelDetail({ id, lineNumber }) {
  return apiGet(`${BASE_API_URL}/services/app/WarrantyRequest/${id}/line/${lineNumber}/view`);
}
export function getInfoDeclarations(query) {
  return apiGet(`${BASE_API_URL}/services/app/WarrantyRequest/GetInfoDeclarations`, query);
}
export function getPricingTerms(query) {
  return apiGet(`${BASE_API_URL}/services/app/WarrantyRequest/GetPricingTerms`, query);
}

export function getAllCheckoutImages({ id }) {
  return apiGet(`${BASE_API_URL}/services/app/WarrantyRequest/${id}/checkout/images`);
}
export function deleteProductImage({ id, lineNumber, imageLineNumber, body }) {
  return apiPut(
    `${BASE_API_URL}/services/app/WarrantyRequest/${id}/line/${lineNumber}/images/imageLines`,
    { body }
  );
}

export function deleteImage({ id, actionId, body }) {
  return apiPut(
    `${BASE_API_URL}/services/app/WarrantyRequest/${id}/process/${actionId}/delete-image`,
    {
      body,
    }
  );
}

export function deleteImageLine({ id, lineNumber, body }) {
  return apiPut(
    `${BASE_API_URL}/services/app/WarrantyRequest/${id}/process/${lineNumber}/delete-image-line`,
    {
      body,
    }
  );
}

export function processCheckout({ id, body }) {
  return apiPut(`${BASE_API_URL}/services/app/WarrantyRequest/${id}/process/checkout`, {
    body,
  });
}
export function processCheckoutAddReplacement({ id, actionId, body }) {
  console.log('body', body);
  return apiPut(`${BASE_API_URL}/services/app/WarrantyRequest/${id}/replacements/${actionId}`, {
    body,
  });
}
export function uploadCheckInOutImage({ id, actionId, body }) {
  return apiPut(
    `${BASE_API_URL}/services/app/WarrantyRequest/${id}/process/${actionId}/upload-image`,
    {
      body,
    }
  );
}
export function uploadReplacementImage({ id, actionId, repId, body }) {
  return apiPut(
    `${BASE_API_URL}/services/app/WarrantyRequest/${id}/process/${actionId}/replacement/${repId}/upload-image`,
    {
      body,
    }
  );
}
export function uploadSignatureImage({ id, actionId, body }) {
  return apiPut(
    `${BASE_API_URL}/services/app/WarrantyRequest/${id}/process/${actionId}/upload-signature`,
    {
      body,
    }
  );
}
export function getEmployeeRecord({ id, employeeId }) {
  return apiGet(
    `${BASE_API_URL}/services/app/WarrantyRequest/${id}/process/employee/${employeeId}`
  );
}
export function addProduct({ id, body }) {
  return apiPost(`${BASE_API_URL}/services/app/WarrantyRequest/${id}/AddProductToWarranty`, {
    body,
  });
  // const mock = { actionId: 0, lineNumber: 0 };
  // return new Promise(resolve => {
  //   setTimeout(function() {
  //     resolve({ success: true, result: mock });
  //   }, 100);
  // });
}

export function checkIn({ id, body }) {
  return apiPut(`${BASE_API_URL}/services/app/WarrantyRequest/${id}/process/checkin`, {
    body,
  });
}

export function getReturnable({ id, query }) {
  return apiGet(`${BASE_API_URL}/services/app/User/${id}/inventory/returnable`, query);
}
export function removeProduct({ id, body }) {
  return apiPut(`${BASE_API_URL}/services/app/WarrantyRequest/${id}/RemoveProduct`, {
    body,
  });
}
export function searchWarrantyActivation(query) {
  return apiGet(`${BASE_API_URL}/services/app/WarrantyActivation/Search`, query);
}
export function updateBillRemind(payload) {
  return apiPut(`${BASE_API_URL}/services/app/WarrantyRequest/${payload.id}/BillRemind`, {
    body: payload,
  });
}
export function searchWarrantyLineBySerial(query, filterWithHiddenSerialNo, hiddenSerialNo) {
  return apiGet(
    `${BASE_API_URL}/services/app/WarrantyRequest/Line/GetBySerial/${query}?filterWithHiddenSerialNo=${filterWithHiddenSerialNo ?? false}${filterWithHiddenSerialNo && !_.isEmpty(hiddenSerialNo) ? `&hiddenSerialNo=${hiddenSerialNo}` : ''}`
  );
}
export function updateNumberOfDelay({ id, numberofDelay, note }) {
  return apiPut(`${BASE_API_URL}/services/app/WarrantyRequest/${id}/renew/${numberofDelay}`, {
    body: { note },
  });
}
export function getInfoRenew({ id }) {
  return apiGet(`${BASE_API_URL}/services/app/WarrantyRequest/${id}/renew`);
}
export function revertProduct({ id }) {
  return apiPut(`${BASE_API_URL}/services/app/WarrantyRequest/${id}/revertProduct`);
}
export function updateModelWrongInfo({ id, lineNumber, userId }) {
  return apiPut(
    `${BASE_API_URL}/services/app/WarrantyRequest/${id}/line/${lineNumber}/wrongInfo?userId=${userId}`
  );
}
export function getStationInfo({ id, stationId }) {
  return apiGet(
    `${BASE_API_URL}/services/app/WarrantyRequest/${id}/plan/getInfoStation/${stationId}`
  );
}
export function warrantyRequestAction(payload) {
  return apiPut(
    `${BASE_API_URL}/services/app/WarrantyRequest/warrantyRequestAction/${payload.warrantyRequestActionId}`,
    {
      body: { ...payload },
    }
  );
}
export function getWarrantyRequestHistories(payload) {
  return apiGet(
    `${BASE_API_URL}/services/app/WarrantyRequest/warrantyRequestAction/${payload.id}/histories`
  );
}

export function updateAddressLocation(payload) {
  console.log('payload', payload);
  return apiPut(`${BASE_API_URL}/services/app/Address/location`, {
    body: { ...payload },
  });
}

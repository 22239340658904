import _ from 'lodash';
import { notification } from 'antd';
import * as converSessionAPI from '@/services/converSession';
import * as businessAPI from '@/services/business';

export default {
  namespace: 'converSession',

  state: {
    searchData: {},
    visibleModal: false,
    modalType: '',
    list: [],
    item: {},
    statusList: [],
    inventoryLines: [],
    conditionTypes: [],
  },

  effects: {
    *fetch({ payload }, { call, put }) {
      const query = Object.assign({});
      _.forEach(payload, (val, key) => {
        if (val) {
          _.set(query, key, val);
        }
      });

      const response = yield call(converSessionAPI.getAll, query);
      if (response.success) {
        yield put({
          type: 'saveList',
          payload: {
            ...response.result,
          },
        });
      }
    },

    *refreshFetch(_, { put, select }) {
      const searchData = yield select(state => state.returnOrder.searchData);

      yield put({
        type: 'fetch',
        payload: searchData,
      });
    },

    *get({ payload }, { call, put }) {
      const response = yield call(converSessionAPI.get, payload.id);
      if (response.success) {
        yield put({
          type: 'save',
          payload: {
            ...response,
            visibleModal: true,
          },
        });
      }
    },

    *create({ payload }, { call, put }) {
      const response = yield call(converSessionAPI.create, payload);
      if (response.success) {
        yield put({
          type: 'save',
          payload: {
            ...response,
            visibleModal: false,
          },
        });

        yield notification.success({
          message: 'Tạo phiếu chuyển đổi',
          description: 'Đã tạo chuyển đổi linh kiện thành công',
        });

        yield put({
          type: 'refreshFetch',
        });
        return response.success;
      }
      else {
        return false;
      }
    },

    *update({ payload }, { call, put }) {
      const response = yield call(converSessionAPI.update, payload);
      if (response.success) {
        yield put({
          type: 'save',
          payload: {
            ...response,
            visibleModal: false,
          },
        });

        yield notification.success({
          message: 'Cập nhật phiếu chuyển đổi',
          description: 'Đã cập nhật chuyển đổi linh kiện thành công',
        });

        yield put({
          type: 'refreshFetch',
        });
        return response.success;
      }
      else {
        return false;
      }
    },


    *reload({ payload }, { put }) {
      yield put({
        type: 'changeVisibleModal',
        payload: {
          status: payload.status,
          modelType: payload.modelType,
        },
      });

      yield put({
        type: 'refreshFetch',
      });
    },

    *changeVisibleModal({ payload }, { put }) {
      yield put({
        type: 'saveVisibleModal',
        payload: {
          status: payload.status,
          modalType: payload.modalType,
          inventoryLines: [],
        },
      });
    },


    *getReturnable({ payload }, { call, put }) {
      const response = yield call(converSessionAPI.getReturnable, payload);
      if (response.success) {
        yield put({
          type: 'saveInventoryLines',
          payload: response.result.items,
        });
      }
    },

    *getReturnableSerials({ payload }, { call, put }) {
      const response = yield call(converSessionAPI.getReturnableSerials, payload);
      if (response.success) {
        return response.result.items;
      }

      return [];
    },

    *getConditionType({ payload }, { call, put }) {
      const response = yield call(converSessionAPI.getConditionType, payload);
      if (response.success) {
        yield put({
          type: 'saveConditionTypes',
          payload: response.result.items,
        });
      }
    },
  },

  reducers: {
    saveSearchData(state, { payload }) {
      return {
        ...state,
        searchData: {
          ...payload,
        },
      };
    },
    saveVisibleModal(state, { payload }) {
      return {
        ...state,
        visibleModal: payload.status,
        modalType: payload.modalType,
      };
    },

    saveList(state, { payload }) {
      return {
        ...state,
        list: payload,
      };
    },

    save(state, { payload }) {
      const warrantyArea = payload.result.coverages;
      return {
        ...state,
        item: payload.result,
        visibleModal: payload.visibleModal,
        warrantyArea,
      };
    },

    saveStatusList(state, { payload }) {
      return {
        ...state,
        statusList: payload,
      };
    },

    saveInventoryLines(state, { payload }) {
      return {
        ...state,
        inventoryLines: payload,
      };
    },

    saveConditionTypes(state, { payload }) {
      return {
        ...state,
        conditionTypes: payload,
      };
    },
  },
};

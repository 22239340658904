import * as callCenterAPI from '@/services/callCenter';
import { resolve } from 'url';

export default {
  namespace: 'warrantyCare',

  state: {
    visibleModal: false,
  },

  effects: {
    *callTelephony({ payload }, { call, put }) {
      const response = yield call(callCenterAPI.callTelephony, payload);
      const { success } = response;
      return Promise.resolve(success);
    },
    *callTelephonyRecall({ payload }, { call, put }) {
      const response = yield call(callCenterAPI.callTelephonyRecall, payload);
      const { success } = response;
      return Promise.resolve(success);
    },

    *callDialing({ payload }, { call, put }) {
      const response = yield call(callCenterAPI.callDialingCare, payload);
      if (response.success) {
        const params = {
          secret: 'dcf572bef7c80962034bddc569354359',
          callstatus: 'DialAnswer',
          calluuid: payload.calluuid,
          childcalluuid: '1512439826.4231',
          callernumber: payload.destinationnumber,
          destinationnumber: '0833507090',
          version: 4,
        };

        yield put({
          type: 'callDialAnswer',
          payload: params,
        });
      }
    },

    *callDialAnswer({ payload }, { call, put }) {
      yield call(callCenterAPI.callDialAnswerCare, payload);
    },
  },

  reducers: {},
};

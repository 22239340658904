
import _ from 'lodash';
import * as addressAPI from "../services/business";


export default {
  namespace: 'address',

  state: {
    visibleModal: false,
    modalType: '',
    data: null,
    provinces: null,
    districts: null,
    wards: null,
    provincesFilter: null,
  },

  effects: {
    *changeVisibleModal({ payload }, { call, put, select }) {
      yield put({
        type: 'saveVisibleModal',
        payload
      });

      if (payload.status) {

        yield put({
          type: 'getProvinces'
        });

        const address = yield select(
          state => state.address.data
        );

        if (!_.isEmpty(address)) {
          yield put({
            type: 'getDistricts',
            payload: {
              parentCode: address.provinceCode
            }
          });

          yield put({
            type: 'getWards',
            payload: {
              parentCode: address.districtCode
            }
          });
        }
      }
    },

    *setDataAddress({ payload }, { call, put }) {
      yield put({
        type: 'saveDataAddress',
        payload
      });
    },

    *getProvinces({ payload }, { select, put }) {
      const businessData = yield select(
        state => state.business.businessData
      );
      const data = _.get(businessData, 'Province');
      if (data) {
        yield put({
          type: 'saveProvinces',
          payload: {
            items: data
          }
        });
      }
    },


    *getDistricts({ payload }, { call, put }) {
      const response = yield call(addressAPI.getDistricts, payload);
      if (response.success) {
        yield put({
          type: 'saveDistricts',
          payload: {
            items: response.result.items
          }
        });
      }
    },

    *getLocationByAddress({ payload }, { call, put }) {
      const response = yield call(addressAPI.getLocationByAddress, payload);
      if (response.success) {
        return response.result;
      }
      return {};
    },

    *getWards({ payload }, { call, put }) {
      const response = yield call(addressAPI.getWards, payload);
      if (response.success) {
        yield put({
          type: 'saveWards',
          payload: {
            items: response.result.items
          }
        });
      }
    },

    *resetAddress({ payload }, { put }) {
      yield put({
        type: 'saveDataAddress',
        payload: {
          address: null
        },
      });
    }

  },

  reducers: {
    saveVisibleModal(state, { payload }) {
      return {
        ...state,
        visibleModal: payload.status
      }
    },

    saveDataAddress(state, { payload }) {
      return {
        ...state,
        data: payload.address
      }
    },

    saveProvinces(state, { payload }) {
      return {
        ...state,
        provinces: payload.items
      }
    },

    saveDistricts(state, { payload }) {
      return {
        ...state,
        districts: payload.items
      }
    },

    saveWards(state, { payload }) {
      return {
        ...state,
        wards: payload.items
      }
    },

    resetData(state, { payload }) {
      return {
        data: null,
        provinces: null,
        districts: null,
        wards: null,
        provincesFilter: null,
      }
    },

    saveProvincesFilter(state, { payload }) {
      return {
        ...state,
        provincesFilter: payload.items
      }
    },
  }
};

import _ from 'lodash';
import { handleParams } from '@/utils/utils';
import { notification } from 'antd';
import { hanldeRowspanReportReceivableMonthly } from '@/pages/Report/utils';
import * as warrantyRequestAPI from '@/services/warrantyRequest';
import * as reportAPI from '../services/report';
import ProductWarrantyTimes from '@/pages/Report/ProductWarrantyTimes';

export default {
  namespace: 'report',

  state: {
    warrantyHistory: [],
    techRevenue: [],
    customer: [],
    customerCare: [],
    accessoriesOrder: [],
    id: 0,
    currentStepCode: null,
    statisticCase: {},
    statisticCaseOverview: {},
    warrantyObject: {},
    inventory: [],
    inventoryAccessories: [],
    activityTracking: [],
    userActivityTracking: [],
    invOnhandSerials: [],
    receivablemonthly: [],
    summaryReceivableByMonth: [],
    visibleModal: false,
    modalType: '',
    item: {},
    warrantyActivation: [],
    saveReportStationRevenueSummary: [],
    saveReportStationRevenueDetail: [],
    saveReportStationRewardPunishment: [],
    saveReportStationSummaryRewardPunishment: [],
    saveReportTechRevenueSummary: [],
    dataReportSaleOrder: [],
    dataReportPerformServiceSummary: [],
    revenueofsale: [],
    returnOrder: [],
    debt_accessories_management: [],
    debt_accessories_management_detail: [],
    dataReportInventoryDv: [],
    dataReportNoTakenBack: {},
    waitingInRecords: [],
    waitingOutRecords: []
  },

  effects: {
    *getWarrantyRequestHistory({ payload }, { call, put }) {
      const query = handleParams(payload);
      const response = yield call(reportAPI.getWarrantyRequestHistory, query);
      if (response.success) {
        yield put({
          type: 'saveWarrantyRequestHistory',
          payload: {
            ...response.result,
          },
        });
      }
    },
    *getAccessoriesOrderProducts({ payload }, { call, put }) {
      const query = handleParams(payload);
      const response = yield call(reportAPI.getAccessoriesOrderProducts, query);
      if (response.success) {
        yield put({
          type: 'saveAccessoriesOrderProducts',
          payload: {
            ...response.result,
          },
        });
      }
    },

    *getTechRevenue({ payload }, { call, put }) {
      const query = handleParams(payload);
      const response = yield call(reportAPI.getTechRevenue, query);
      if (response.success) {
        yield put({
          type: 'saveTechRevenue',
          payload: {
            ...response.result,
          },
        });
      }
    },
    *getReturnOrder({ payload }, { call, put }) {
      const query = handleParams(payload);
      const response = yield call(reportAPI.getReturnOrder, query);
      if (response.success) {
        yield put({
          type: 'saveReturnOrder',
          payload: {
            ...response.result,
          },
        });
      }
    },
    *getDebtAccessoriesManagement({ payload }, { call, put }) {
      const query = handleParams(payload);
      const response = yield call(reportAPI.getDebtAccessoriesManagement, query);
      if (response.success) {
        yield put({
          type: 'saveDebtAccessoriesManagement',
          payload: {
            ...response.result,
          },
        });
      }
    },
    *getDebtAccessoriesManagementDetail({ payload }, { call, put }) {
      const query = handleParams(payload);
      const response = yield call(reportAPI.getDebtAccessoriesManagementDetail, query);
      if (response.success) {
        yield put({
          type: 'saveDebtAccessoriesManagementDetail',
          payload: {
            ...response.result,
          },
        });
      }
    },
    *getCustomer({ payload }, { call, put }) {
      const query = handleParams(payload);
      const response = yield call(reportAPI.getCustomer, query);
      if (response.success) {
        yield put({
          type: 'saveCustomer',
          payload: {
            ...response.result,
          },
        });
      }
    },

    *getCustomerCare({ payload }, { call, put }) {
      const query = handleParams(payload);
      const response = yield call(reportAPI.getCustomerCare, query);
      if (response.success) {
        yield put({
          type: 'saveCustomerCare',
          payload: {
            ...response.result,
          },
        });
      }
    },

    *getAccessoriesOrder({ payload }, { call, put }) {
      const query = handleParams(payload);
      const response = yield call(reportAPI.getAccessoriesOrder, query);
      if (response.success) {
        yield put({
          type: 'saveAccessoriesOrder',
          payload: {
            ...response.result,
          },
        });
      }
    },

    *searchWarRqBusinessStep({ payload }, { call, put }) {
      const query = handleParams(payload);
      const response = yield call(reportAPI.warrantyRequestBusiness, query);
      if (response.success) {
        yield put({
          type: 'saveStatisticCase',
          payload: {
            ...response.result,
          },
        });
      }
    },

    *getStatisticCaseOverview({ payload }, { call, put }) {
      const query = handleParams(payload);
      const response = yield call(reportAPI.warrantyRequestBusinessOverview, query);
      if (response.success) {
        yield put({
          type: 'saveStatisticCaseOverview',
          payload: {
            ...response.result,
          },
        });
      }
    },

    *getWarrantyRequestObject({ payload }, { call, put }) {
      const query = handleParams(payload);
      const response = yield call(reportAPI.warrantyRequestObject, query);
      if (response.success) {
        const { result } = response;
        yield put({
          type: 'saveWarrantyObject',
          payload: {
            ...result,
          },
        });
      }
    },

    *getInventory({ payload }, { call, put }) {
      const query = handleParams(payload);
      const response = yield call(reportAPI.getInventory, query);
      if (response.success) {
        yield put({
          type: 'saveInventory',
          payload: {
            ...response.result,
          },
        });
      }
    },

    *getSerialHistory({ payload }, { call, put }) {
      const query = handleParams(payload);
      const response = yield call(reportAPI.getSerialHistory, query);
      if (response.success) {
        yield put({
          type: 'saveSerialHistory',
          payload: {
            ...response.result,
          },
        });
      }
    },
    *getProductWarrantyTimes({ payload }, { call, put }) {
      const query = handleParams(payload);
      const response = yield call(reportAPI.getProductWarrantyTimes, query);
      if (response.success) {
        yield put({
          type: 'saveProductWarrantyTimes',
          payload: {
            ...response.result,
          },
        });
      }
    },

    *getWarrantyActivation({ payload }, { call, put }) {
      const query = handleParams(payload);
      const response = yield call(reportAPI.getWarrantyActivation, query);
      if (response.success) {
        yield put({
          type: 'saveWarrantyActivation',
          payload: {
            ...response.result,
          },
        });
      }
    },

    *getActivityVisit({ payload }, { call, put }) {
      const query = handleParams(payload);
      const response = yield call(reportAPI.getActivityVisit, query);
      if (response.success) {
        yield put({
          type: 'saveActivityVisit',
          payload: {
            ...response.result,
          },
        });
      }
    },

    *getWorkingDiary({ payload }, { call, put }) {
      const query = handleParams(payload);
      const response = yield call(reportAPI.getWorkingDiary, query);
      if (response.success) {
        yield put({
          type: 'saveWorkingDiary',
          payload: {
            ...response.result,
          },
        });
      }
    },

    *getInvWaitingOut({ payload }, { call, put }) {
      const query = handleParams(payload);
      const response = yield call(reportAPI.getInvWaitingOut, query);
      if (response.success) {
        yield put({
          type: 'saveInvWaitingOut',
          payload: {
            ...response.result,
          },
        });
      }
    },

    *getInventoryAccessories({ payload }, { call, put }) {
      const query = handleParams(payload);
      const response = yield call(reportAPI.getInventoryAccessories, query);
      if (response.success) {
        yield put({
          type: 'saveInventoryAccessories',
          payload: {
            ...response.result,
          },
        });
      }
    },

    *getActivityTracking({ payload }, { call, put }) {
      const query = handleParams(payload);
      const response = yield call(reportAPI.getActivityTracking, query);
      if (response.success) {
        yield put({
          type: 'saveActivityTracking',
          payload: {
            ...response.result,
          },
        });
      }
    },

    *getActivityImages({ payload }, { call, put }) {
      const query = handleParams(payload);
      const response = yield call(reportAPI.getActivityImages, query);
      if (response.success) {
        return response.result;
      }
      return [];

    },
    *getAONoReturnImages({ payload }, { call, put }) {
      const query = handleParams(payload);
      const response = yield call(reportAPI.getAONoReturnImages, query);
      if (response.success) {
        return response.result;
      }
      return [];

    },

    *getUserActivities({ payload }, { call, put }) {
      const query = handleParams(payload);
      const response = yield call(reportAPI.getUserActivities, query);
      if (response.success) {
        yield put({
          type: 'saveUserActivityTracking',
          payload: {
            ...response.result,
          },
        });
      }
    },

    *getTimeSheetsHistories({ payload }, { call, put }) {
      const query = handleParams(payload);
      const response = yield call(reportAPI.getTimeSheetsHistories, query);
      if (response.success) {
        yield put({
          type: 'saveTimeSheetsHistories',
          payload: {
            ...response.result,
          },
        });
      }
    },
    *getAccessoriesNoReturn({ payload }, { call, put }) {
      const query = handleParams(payload);
      const response = yield call(reportAPI.getAccessoriesNoReturn, query);
      if (response.success) {
        yield put({
          type: 'saveAccessoriesNoReturn',
          payload: {
            ...response.result,
          },
        });
      }
    },
    *getReportRevenueOfSaleAccessories({ payload }, { call, put }) {
      const query = handleParams(payload);
      const response = yield call(reportAPI.getRevenueOfSaleAccessories, query);
      if (response.success) {
        yield put({
          type: 'saveRevenueOfSale',
          payload: {
            ...response.result,
          },
        });
      }
    },

    *getInventoryInOut({ payload }, { call, put }) {
      const query = handleParams(payload);
      const response = yield call(reportAPI.getInventoryInOut, query);
      if (response.success) {
        yield put({
          type: 'saveInventoryInOut',
          payload: response.result,
        });
      }
    },
    *getBookkingAccessories({ payload }, { call, put }) {
      const query = handleParams(payload);
      const response = yield call(reportAPI.getBookingAccessories, query);
      if (response.success) {
        yield put({
          type: 'saveBookingAccessories',
          payload: response.result,
        });
      }
    },

    *getLogs({ payload }, { call, put }) {
      const response = yield call(reportAPI.getLogs, payload);
      if (response.success) {
        yield put({
          type: 'saveLogs',
          payload: {
            ...response.result,
          },
        });
      }
    },

    *getInvOnhandSerials({ payload }, { call, put }) {
      const query = handleParams(payload);
      const response = yield call(reportAPI.getInventoryOnhandSerials, query);
      if (response.success) {
        yield put({
          type: 'saveInvOnhandSerials',
          payload: response.result.items,
        });
      }
    },
    *getReceivableMonthly({ payload }, { call, put }) {
      const query = handleParams(payload);
      const response = yield call(reportAPI.getReceivableMonthly, query);
      if (response.success) {
        const { result } = response;
        const newData = yield hanldeRowspanReportReceivableMonthly(result.items);
        result.items = newData;
        yield put({
          type: 'saveReceivableMonthly',
          payload: {
            ...result,
          },
        });
      }
    },
    *getSummaryReceivableByMonth({ payload }, { call, put }) {
      const query = handleParams(payload);
      const response = yield call(reportAPI.getSummaryReceivableByMonth, query);
      if (response.success) {
        const { result } = response;
        yield put({
          type: 'saveSummaryReceivableByMonth',
          payload: {
            ...result,
          },
        });
      }
    },
    *changeVisibleModal({ payload }, { call, put }) {
      yield put({
        type: 'saveVisibleModal',
        payload: {
          status: payload.status,
          modalType: payload.modalType,
          item: {},
        },
      });
    },
    *getDetailWarrantyActivation({ payload }, { call, put }) {
      const response = yield call(reportAPI.getDetailWarrantyActivation, payload.id, payload.code);
      if (response.success) {
        yield put({
          type: 'save',
          payload: {
            ...response,
            status: true,
          },
        });
      }
    },
    *updateWarrantyActivation({ payload }, { call, put }) {
      const response = yield call(reportAPI.updateWarrantyActivationInfo, payload);
      if (response.success) {
        const serial = response.result.serialNo;
        yield notification.success({
          message: 'Cập nhật Bảo hành',
          description: 'Đã cập nhật thông tin Bảo hành thành công',
        });
        yield put({
          type: 'changeVisibleModal',
          payload: {
            status: false,
          },
        });
        yield put({
          type: 'getWarrantyActivation',
          payload: { Keyword: serial },
        });
      }
    },

    *cancleWarrantyActivation({ payload }, { call, put }) {
      const response = yield call(reportAPI.cancleWarrantyActivationInfo, payload);
      if (response.success) {
        yield notification.success({
          message: 'Hủy Bảo hành',
          description: 'Đã hủy thông tin Bảo hành thành công',
        });
        yield put({
          type: 'changeVisibleModal',
          payload: {
            status: false,
          },
        });
      }
    },

    *getReportStationRevenueSummary({ payload }, { call, put }) {
      const query = handleParams(payload);
      const response = yield call(reportAPI.getReportStationRevenueSummary, query);
      if (response.success) {
        yield put({
          type: 'saveReportStationRevenueSummary',
          payload: {
            ...response.result,
          },
        });
      }
    },

    *getReportStationRevenueDetail({ payload }, { call, put }) {
      const query = handleParams(payload);
      const response = yield call(reportAPI.getReportStationRevenueDetail, query);
      if (response.success) {
        yield put({
          type: 'saveReportStationRevenueDetail',
          payload: {
            ...response.result,
          },
        });
      }
    },
    *getReportStationRewardPunishment({ payload }, { call, put }) {
      const query = handleParams(payload);
      const response = yield call(reportAPI.getStationRewardPunishment, query);
      if (response.success) {
        yield put({
          type: 'saveReportStationRewardPunishment',
          payload: {
            ...response.result,
          },
        });
      }
    },
    *getReportStationSummaryRewardPunishment({ payload }, { call, put }) {
      const query = handleParams(payload);
      const response = yield call(reportAPI.getStationSummaryRewardPunishment, query);
      if (response.success) {
        yield put({
          type: 'saveReportStationSummaryRewardPunishment',
          payload: {
            ...response.result,
          },
        });
      }
    },

    *getReportTechRevenueSummary({ payload }, { call, put }) {
      const query = handleParams(payload);
      const response = yield call(reportAPI.getReportTechRevenueSummary, query);
      if (response.success) {
        yield put({
          type: 'saveReportTechRevenueSummary',
          payload: {
            ...response.result,
          },
        });
      }
    },

    *getReportSaleOrder({ payload }, { call, put }) {
      const query = handleParams(payload);
      const response = yield call(reportAPI.getReportSaleOrder, query);
      if (response.success) {
        yield put({
          type: 'saveReportSaleOrder',
          payload: {
            ...response.result,
          },
        });
      }
    },
    *getReportPerformServiceSummary({ payload }, { call, put }) {
      const query = handleParams(payload);
      const response = yield call(reportAPI.getReportPerformServiceSummary, query);
      if (response.success) {
        yield put({
          type: 'saveReportPerformServiceSummary',
          payload: {
            ...response.result,
          },
        });
      }
    },
    *getProductForSelect({ payload }, { call }) {
      return yield call(warrantyRequestAPI.getAccessories, payload);
    },

    *getReportInventoryDv({ payload }, { call, put }) {
      const query = handleParams(payload);
      const response = yield call(reportAPI.getReportInventoryDv, query);
      if (response.success) {
        yield put({
          type: 'saveReportInventoryDv',
          payload: {
            ...response.result,
          },
        });
      }
    },

    *getWaitingInRecords({ payload }, { call, put }) {
      const query = handleParams(payload);
      const response = yield call(reportAPI.getWaitingInRecords, query);
      if (response.success) {
        yield put({
          type: 'saveWaitingInRecords',
          payload: {
            ...response.result,
          },
        });
      }
    },

    *getWaitingOutRecords({ payload }, { call, put }) {
      const query = handleParams(payload);
      const response = yield call(reportAPI.getWaitingOutRecords, query);
      if (response.success) {
        yield put({
          type: 'saveWaitingOutRecords',
          payload: {
            ...response.result,
          },
        });
      }
    },

    *getReportNoTakenBack({ payload }, { call, put }) {
      const query = handleParams(payload);
      const response = yield call(reportAPI.getAccessoriesNoTakenBack, query);
      if (response.success) {
        yield put({
          type: 'saveReportNoTakenBack',
          payload: {
            ...response.result,
          },
        });
      }
    },

    *getNoTakenBackDetail({ payload }, { call }) {
      const response = yield call(reportAPI.getAccessoriesNoTakenBackDetail, payload.id);
      if (response.success) {
        return response.result;
      }
      return []
    },

    *saveStatus({ payload }, { call, }) {
      console.log("save", payload)
      const response = yield call(reportAPI.saveStatusAoTakenBack, payload);
      return response.success
    },


    *exportInventoryDv({ payload }, { call }) {
      return yield call(reportAPI.exportInventoryDv, payload);
    },


  },
  reducers: {
    saveVisibleModal(state, { payload }) {
      return {
        ...state,
        visibleModal: payload.status,
        modalType: payload.modalType,
        ...payload,
      };
    },
    save(state, { payload }) {
      const { result, visibleModal } = payload;
      return {
        ...state,
        item: result,
        visibleModal,
      };
    },
    saveWarrantyRequestHistory(state, { payload }) {
      return {
        ...state,
        warrantyHistory: payload,
      };
    },
    saveAccessoriesOrderProducts(state, { payload }) {
      return {
        ...state,
        accessoriesOrderProducts: payload,
      };
    },
    saveTechRevenue(state, { payload }) {
      return {
        ...state,
        techRevenue: payload,
      };
    },
    saveReturnOrder(state, { payload }) {
      return {
        ...state,
        returnOrder: payload,
      };
    },
    saveDebtAccessoriesManagement(state, { payload }) {
      return {
        ...state,
        debt_accessories_management: payload,
      };
    },
    saveDebtAccessoriesManagementDetail(state, { payload }) {
      return {
        ...state,
        debt_accessories_management_detail: payload,
      };
    },
    saveCustomer(state, { payload }) {
      return {
        ...state,
        customer: payload,
      };
    },
    saveCustomerCare(state, { payload }) {
      return {
        ...state,
        customerCare: payload,
      };
    },
    saveAccessoriesOrder(state, { payload }) {
      return {
        ...state,
        accessoriesOrder: payload,
      };
    },
    saveStatisticCase(state, { payload }) {
      return {
        ...state,
        statisticCase: payload,
      };
    },
    saveStatisticCaseOverview(state, { payload }) {
      return {
        ...state,
        statisticCaseOverview: payload,
      };
    },
    saveWarrantyObject(state, { payload }) {
      return {
        ...state,
        warrantyObject: payload,
      };
    },
    saveInventory(state, { payload }) {
      return {
        ...state,
        inventory: payload,
      };
    },
    saveSerialHistory(state, { payload }) {
      return {
        ...state,
        serialHistory: payload,
      };
    },
    saveSerialHistory(state, { payload }) {
      return {
        ...state,
        serialHistory: payload,
      };
    },
    saveProductWarrantyTimes(state, { payload }) {
      return {
        ...state,
        productWarrantyTimes: payload,
      };
    },
    saveWarrantyActivation(state, { payload }) {
      return {
        ...state,
        warrantyActivation: payload,
      };
    },
    saveActivityVisit(state, { payload }) {
      return {
        ...state,
        activityVisit: payload,
      };
    },
    saveWorkingDiary(state, { payload }) {
      return {
        ...state,
        workingDiary: payload,
      };
    },

    saveTimeSheetsHistories(state, { payload }) {
      return {
        ...state,
        timesheetsHistories: payload,
      };
    },
    saveAccessoriesNoReturn(state, { payload }) {
      return {
        ...state,
        accessoriesNoReturn: payload,
      };
    },
    saveRevenueOfSale(state, { payload }) {
      return {
        ...state,
        revenueofsale: payload,
      };
    },
    saveInventoryInOut(state, { payload }) {
      return {
        ...state,
        inventoryInOut: payload,
      };
    },
    saveBookingAccessories(state, { payload }) {
      return {
        ...state,
        bookingAccessories: payload,
      };
    },
    saveLogs(state, { payload }) {
      return {
        ...state,
        logs: payload,
      };
    },
    saveInvWaitingOut(state, { payload }) {
      return {
        ...state,
        invWaitingOut: payload,
      };
    },
    saveInventoryAccessories(state, { payload }) {
      return {
        ...state,
        inventoryAccessories: payload,
      };
    },
    saveActivityTracking(state, { payload }) {
      return {
        ...state,
        activityTracking: payload,
      };
    },
    saveUserActivityTracking(state, { payload }) {
      return {
        ...state,
        userActivityTracking: payload,
      };
    },
    saveInvOnhandSerials(state, { payload }) {
      return {
        ...state,
        invOnhandSerials: payload,
      };
    },
    saveReceivableMonthly(state, { payload }) {
      return {
        ...state,
        receivablemonthly: payload,
      };
    },
    saveSummaryReceivableByMonth(state, { payload }) {
      return {
        ...state,
        summaryReceivableByMonth: payload,
      };
    },
    saveReportStationRevenueSummary(state, { payload }) {
      return {
        ...state,
        saveReportStationRevenueSummary: payload,
      };
    },
    saveReportStationRevenueDetail(state, { payload }) {
      return {
        ...state,
        saveReportStationRevenueDetail: payload,
      };
    },
    saveReportStationRewardPunishment(state, { payload }) {
      return {
        ...state,
        saveReportStationRewardPunishment: payload,
      };
    },
    saveReportStationSummaryRewardPunishment(state, { payload }) {
      return {
        ...state,
        saveReportStationSummaryRewardPunishment: payload,
      };
    },

    saveReportTechRevenueSummary(state, { payload }) {
      return {
        ...state,
        saveReportTechRevenueSummary: payload,
      };
    },
    saveReportSaleOrder(state, { payload }) {
      return {
        ...state,
        dataReportSaleOrder: payload,
      };
    },
    saveReportPerformServiceSummary(state, { payload }) {
      return {
        ...state,
        dataReportPerformServiceSummary: payload,
      };
    },
    saveReportInventoryDv(state, { payload }) {
      return {
        ...state,
        dataReportInventoryDv: payload,
      };
    },
    saveReportNoTakenBack(state, { payload }) {
      return {
        ...state,
        dataReportNoTakenBack: payload,
      };
    },
    saveWaitingInRecords(state, { payload }) {
      return {
        ...state,
        waitingInRecords: payload,
      };
    },
    saveWaitingOutRecords(state, { payload }) {
      return {
        ...state,
        waitingOutRecords: payload,
      };
    },
  },
};

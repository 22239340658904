import React, { useState, useEffect } from 'react';
import { Card, Modal, Table, Typography } from 'antd';
import _ from 'lodash';

const { Text } = Typography;

const SerialModal = props => {
  const { serialRecord, visible, handleVisibleModal } = props;
  const columns = [
    {
      title: 'Serial',
      dataIndex: 'serial',
      key: 'serial',
      className: 'list-header',
    },
  ];

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  useEffect(() => {
    if (visible) {
      setSelectedRowKeys(_.get(serialRecord, 'serials', []));
    }
  }, [visible]);

  const onSelectChange = newSelectedRowKeys => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleSubmit = () => {
    serialRecord.serials = selectedRowKeys;
    if (handleVisibleModal) {
      handleVisibleModal(false, serialRecord, true);
    }
  };

  const { curSerials, productName, sku, product } = serialRecord || {};
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <Modal
      destroyOnClose
      title="Danh sách serial"
      visible={visible}
      width={1000}
      onOk={handleSubmit}
      onCancel={() => handleVisibleModal(false)}
      maskClosable={false}
      okText="Đóng"
      cancelButtonProps={{ style: { display: 'none' } }}
      zIndex={1520}
    >
      <Card bordered={false}>
        <div>
          <Text strong style={{ fontSize: '15px' }}>
            Sản phẩm
          </Text>
          <div style={{ marginTop: '5px' }}>
            <Text>
              {product
                ? `${_.get(product, 'sku')} - ${_.get(product, 'name')}`
                : `${sku} - ${productName}`}
            </Text>
          </div>
        </div>

        <div style={{ marginTop: '15px' }}>
          <div style={{ marginTop: '10px' }}>
            <Table
              rowKey="serial"
              dataSource={curSerials || []}
              columns={columns}
              size="small"
              bordered
              pagination={false}
            />
          </div>
        </div>
      </Card>
    </Modal>
  );
};

export default SerialModal;

import { apiGet, apiPost, apiPut } from '@/utils/request';

export function getAll(query) {
  return apiGet(`${BASE_API_URL}/services/app/Transfer/GetAll`, query);
}

export function get(id) {
  return apiGet(`${BASE_API_URL}/services/app/Transfer/GetDetail`, { id: id });
}

export function create(body) {
  return apiPost(`${BASE_API_URL}/services/app/Transfer/Create`, { body });
}

export function update(body) {
  return apiPut(`${BASE_API_URL}/services/app/Transfer/Update`, { body });
}

export function finish({ id, lines }) {
  return apiPut(`${BASE_API_URL}/services/app/Transfer/${id}/finish`, { body: { lines } });
}

export function start({ id }) {
  return apiPut(`${BASE_API_URL}/services/app/Transfer/${id}/start`);
}

export function getForSelect(query) {
  return apiGet(`${BASE_API_URL}/services/app/Transfer/GetForSelect`, query);
}

export function getTypeAccessoriesOrderRef(ref) {
  return apiGet(`${BASE_API_URL}/services/app/Ref/TypeAccessoriesOrder/${ref}`);
}
